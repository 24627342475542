import { fullChains } from "@/constants/wallets";
import useInterface from "@/stores/interface";
import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useAccount } from "wagmi";

const useActiveChains = () => {
  const { metadata: interfaceConfig } = useInterface();
  const account = useAccount();
  // 连接 mpc 钱包  account?.chainId 有时会 undefined，遂定义 activeChainId
  const [activeChainId, setActiveChainId] = useState(interfaceConfig?.multiChain?.chainList?.[0].chainId);
  useEffect(() => {
    if (account?.chainId) {
      setActiveChainId(account?.chainId);
    } else {
      // 兼容
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        provider.getNetwork().then((chain) => {
          setActiveChainId(chain.chainId);
        });
      // eslint-disable-next-line no-empty
      } catch (error) { }
    }
  }, [account?.chainId]);

  const chains = useMemo(() => {
    return interfaceConfig?.multiChain?.chainList?.filter((j) => fullChains?.find((i) => +i?.id === +j?.chainId));
  }, [interfaceConfig?.multiChain?.chainList]);

  const unsupported = useMemo(() => activeChainId && !chains?.find((i) => +i.chainId === +activeChainId), [activeChainId, chains]);

  const currentActiveChain = useMemo(() => chains?.find((i) => +i?.chainId === +activeChainId) || chains?.[0], [activeChainId, chains]);

  const currentActiveChainTokens = useMemo(
    () =>
      currentActiveChain?.tokenList
        ?.map((i, index) => {
          return {
            ...i,
            isDefaultToken: index === 0 ? true : false,
          };
        })
        ?.filter((t) => !t.pullOff),
    [currentActiveChain?.tokenList],
  );

  return { chains, unsupported, currentActiveChain, currentActiveChainTokens };
};

export default useActiveChains;
