import React, { useState } from "react";
import PropTypes from "prop-types";
import { Select as UISelect, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { twMerge } from "tailwind-merge";
import ui from "@/constants/ui";
/**
 * 简写 ui/select 组件
 * <Select value={tpProfitType} onValueChange={(newValue)=>{}} onChange={(e) => console.log(e.target.value)} />
 */
let timeout = null;
const Select = ({ hasError, className, selectContentClassName, selectItemClassName, value, onChange, triggerEvent, options, contentProps, onOpenChange, ...props }) => {
  const [open, setOpen] = useState(false);
  return (
    <UISelect
      onValueChange={(v) => {
        onChange({
          target: {
            value: v,
          },
        });
      }}
      open={open}
      onOpenChange={(v) => {
        setOpen(v);
        onOpenChange?.(v);
      }}
      value={value}
      {...props}
    >
      <SelectTrigger
        data-error={hasError ? "on" : ""}
        className={twMerge(
          "h-auto focus:ring-0 focus:ring-offset-0 [&>span]:inline-flex [&>span]:items-center group-[]/join:h-full group-[]/join:bg-transparent group-[]/join:px-0 group-[]/join:border-0 border border-solid border-[--select] bg-[--select] hover:border-border-strong focus-within:border-border-strong rounded [&.input-ghost]:border-0 [&.input-ghost]:bg-transparent data-[state=open]:border-border-strong group-[]/dialog:bg-background group-[]/dialog:border-background",
          ui?.selectTriggerClassName,
          className,
        )}
        {...(triggerEvent == "mouseenter"
          ? {
              onMouseEnter: () => {
                clearTimeout(timeout);
                open || setOpen(true);
              },
              onMouseMove: () => {
                clearTimeout(timeout);
              },
              onMouseLeave: () => {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                  open && setOpen(false);
                }, 1000);
              },
            }
          : {})}
      >
        <SelectValue />
      </SelectTrigger>
      <SelectContent
        arrow={false}
        className={twMerge("border-0 bg-bg-primary box-border rounded", ui?.selectContentClassName, selectContentClassName)}
        viewPortClassName={twMerge("p-1", ui?.selectViewPortClassName)}
        {...(triggerEvent == "mouseenter"
          ? {
              onMouseEnter: () => {
                clearTimeout(timeout);
              },
              onMouseMove: () => {
                clearTimeout(timeout);
              },
              onMouseLeave: () => {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                  open && setOpen(false);
                }, 200);
              },
            }
          : {})}
        {...contentProps}
      >
        {options?.map((t) => (
          <SelectItem
            hideIcon={ui?.hideSelectItemIndicator}
            data-state={value === t.value ? "selected" : ""}
            key={t.value}
            value={t.value}
            className={twMerge(
              "[&>span]:flex [&>span:not(.indicator)]:w-full [&>span]:items-center focus:bg-transparent focus:text-primary cursor-pointer",
              ui?.selectItemClassName,
              selectItemClassName,
            )}
          >
            {t.label}
          </SelectItem>
        ))}
      </SelectContent>
    </UISelect>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  selectContentClassName: PropTypes.string,
  selectItemClassName: PropTypes.string,
  triggerEvent: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onOpenChange: PropTypes.func,
  contentProps: PropTypes.object,
  hasError: PropTypes.bool,
};

export default Select;
