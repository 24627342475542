import Icon from "@/components/Icon";
import languages from "@/constants/languages";
import HeaderDropDown from "@/modules/layout/components/HeaderDropDown";
import useGlobal from "@/stores/global";
import language from "@/icons/language.svg";
import axios from "axios";
import useUser from "@/stores/user";
import { useEffect } from "react";
import ui from "@/constants/ui";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import { winENV } from "@/utils/env";

function LangSwitch(props) {
  const { setGlobal, lang, remoteMessages } = useGlobal();
  const { currentActiveAccount } = useUser();
  const { className, ...rest } = props;
  // 设置语言为 user.language
  useEffect(() => {
    if (currentActiveAccount?.user?.language) {
      const lang = currentActiveAccount?.user?.language;
      const correctedLanguage = languages.filter((row) => ui?.enabledLanguages?.includes(row.lang)).find((l) => l.lang == lang) ? lang : ui?.defaultLanguage || "en-US";
      setGlobal({ lang: correctedLanguage });
    }
  }, [currentActiveAccount?.user?.language]);

  // 加载远程语言包
  useEffect(() => {
    if (winENV?.MESSAGES_SERVER && !remoteMessages[lang]) {
      fetch(window.ENV.MESSAGES_SERVER.replace("{lang}", lang) + "?_=" + new Date().getTime(), { mode: "cors" })
        .then((res) => res.json())
        .then((res) => {
          setGlobal({
            remoteMessages: {
              ...remoteMessages,
              [lang]: res,
            },
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
    return () => {};
  }, [lang]);
  return (
    <HeaderDropDown title={<Icon icon={language} className={twMerge("h-6 fill-current transition-all origin-center", className)} />} {...rest}>
      {languages
        .filter((row) => ui?.enabledLanguages?.includes(row.lang))
        .sort((a, b) => ui?.enabledLanguages.indexOf(a.lang) - ui?.enabledLanguages.indexOf(b.lang))
        .map((e) => (
          <a
            key={e.lang}
            onClick={async (evt) => {
              setGlobal({ lang: e.lang });
              evt.preventDefault();
              axios
                .post(
                  "/v1/private/user/updateUser",
                  {
                    language: e.lang,
                    nickname: currentActiveAccount?.user?.nickname,
                    email: currentActiveAccount?.user?.email,
                  },
                  { interceptError: false },
                )
                // eslint-disable-next-line no-console
                .catch(console.error);
            }}
          >
            {languages.find((l) => l.lang == e.lang).name}
          </a>
        ))}
    </HeaderDropDown>
  );
}
LangSwitch.propTypes = {
  className: PropTypes.string,
};

export default LangSwitch;
