// eslint-disable-next-line react/prop-types
function Flash({ marked }) {
  return (
    <svg
      className={marked ? "fill-primary text-primary" : "fill-inverse-primary text-inverse-primary"}
      style={{ transition: "all linear .2s" }}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
    >
      <path
        d="M10.1154 5.53929C10.1012 5.47919 10.0724 5.42352 10.0315 5.37724C9.99062 5.33096 9.93893 5.29549 9.88104 5.27398L7.18057 4.26101L7.86776 0.823668C7.88332 0.743803 7.87248 0.661044 7.83688 0.587879C7.80128 0.514713 7.74285 0.455112 7.6704 0.418069C7.59796 0.381026 7.51543 0.368551 7.43527 0.382526C7.35511 0.396502 7.28168 0.436169 7.22604 0.495543L1.97604 6.12054C1.9334 6.16547 1.90255 6.22027 1.88626 6.28003C1.86996 6.33979 1.86872 6.40265 1.88265 6.46301C1.89658 6.52337 1.92524 6.57933 1.96608 6.62591C2.00691 6.67248 2.05865 6.70822 2.11667 6.72992L4.81807 7.74289L4.13276 11.1765C4.1172 11.2563 4.12804 11.3391 4.16364 11.4123C4.19924 11.4854 4.25767 11.545 4.33012 11.5821C4.40256 11.6191 4.48509 11.6316 4.56525 11.6176C4.64541 11.6036 4.71884 11.564 4.77448 11.5046L10.0245 5.87961C10.0663 5.83467 10.0965 5.78013 10.1124 5.72079C10.1282 5.66145 10.1293 5.59913 10.1154 5.53929Z"
        fill="currentColor"
      />
    </svg>
  );
}
// eslint-disable-next-line react/prop-types
function SpeedRating({ len = 3, active = 0, bordered = false, children }) {
  return (
    <div className={"inline-flex gap-1 h-[14px] leading-[19px] items-center justify-between " + (bordered ? "bg-background px-2 rounded-full" : " ")}>
      {children ? <div className=""> {children} </div> : null}
      {/* 创建 len 个组件 */}
      <div style={{ gap: "1px", display: "inline-flex" }}>
        {Array.from({ length: len }, (v, i) => (
          <Flash key={i} marked={active >= i + 1} />
        ))}
      </div>
    </div>
  );
}
export default SpeedRating;
