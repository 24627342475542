import React from "react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import useTranslation from "@/hooks/useTranslation";
import { about, media } from "../setting";
import logo from "@/icons/logo.svg";
import Icon from "@/components/Icon";
import { winENV } from "@/utils/env";

const Footer = ({ topBorder = true }) => {
  const t = useTranslation();
  return (
    <div className={twMerge("footer-wrapper bg-black", topBorder && "border-t-[2px] border-brand")}>
      <div className="home-container p-8 w-full">
        <div className="items-start justify-between grid pc:grid-cols-2 ltPc:grid-cols-1 ">
          <div>
            <a href="/" className="inline-flex items-center h-full mr-2">
              <Icon icon={logo} className="h-7" />
            </a>
            <p className="text-foreground-secondary text-sm mt-4">{t("home.header.Right1")}</p>
            <p className="text-foreground-secondary text-sm mt-1">{t("home.footer.desc")}</p>
          </div>

          <div className={twMerge("gap-[60px] ltPc:mt-6 flex justify-end")}>
            {about.map((item) => (
              <div className="" key={t(item.title)}>
                <p className="text-foreground-secondary text-sm mb-4">{t(item.title)}</p>
                {item?.children.map((i) => (
                  <a href={i.url} key={t(i.title)} className="text-sm w-full mb-2 block" target={i.target ? "_blank" : "_self"} rel="noreferrer">
                    <p className="text-sm text-foreground-tertiary hover:text-foreground-secondary">{t(i.title)}</p>
                  </a>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="mt-6 grid pc:grid-cols-2 ltPc:grid-cols-1">
          {/* <h1 className="text-Archive-Brightness-Grey-600 text-sm">{t("home.footer.RightsReserved", { v: `© ${new Date().getFullYear()} ${winENV?.SYSTEM_NAME}` })}</h1> */}
          <div>
            {/* <p className="text-Archive-Brightness-Grey-600 text-sm">{t('home.header.Right1')}</p> */}
            <h1 className="text-Archive-Brightness-Grey-600 text-sm">{t("home.footer.RightsReserved", { v: `© ${new Date().getFullYear()} ${winENV?.SYSTEM_NAME || "edgeX"}` })}</h1>
          </div>
          <div className="flex items-center justify-end ltPc:mt-6">
            {media.map((item) => (
              <a href={item.url} key={t(item.title)} className="text-foreground-tertiary text-sm ml-6 ltPc:m-auto" target="_blank" rel="noreferrer">
                <div className="border-[1px] border-foreground-disabled p-[5px] rounded-full hover:border-foreground-secondary ">{item.icon}</div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  topBorder: PropTypes.bool,
};

export default Footer;
