import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import Icon from "@/components/Icon";
import arrowRight from "@/icons/line-right.svg";

// 实现功能 1.00 -> 0.00
// todo 优化命名
const TransitionStatus = ({ className, suffix, prefix, prevValue, nextValue, transitionIcon, transitionClassName, forceTransitionStatus = !!nextValue }) => {
  return (
    <div className={twMerge("flex flex-row gap-1 items-center flex-wrap", className)}>
      {/* prevStatus */}
      <span className="flex flex-row items-center">
        {prefix}
        {prevValue}
        {suffix}
      </span>
      {forceTransitionStatus ? (
        <div className="flex flex-row gap-1 items-center">
          {transitionIcon ? transitionIcon : <Icon icon={arrowRight} className={twMerge("w-3 fill-[--short]", transitionClassName)} />}
          <span className="flex flex-row items-center">
            {prefix}
            {nextValue}
            {suffix}
          </span>
        </div>
      ) : null}
    </div>
  );
};

TransitionStatus.propTypes = {
  className: PropTypes.string,
  transitionClassName: PropTypes.string,
  suffix: PropTypes.node,
  prefix: PropTypes.node,
  prevValue: PropTypes.node,
  nextValue: PropTypes.node,
  transitionIcon: PropTypes.node,
  forceTransitionStatus: PropTypes.bool,
};

export default TransitionStatus;
