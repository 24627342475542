import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import useTranslation from "@/hooks/useTranslation";
import { useEventListener } from "ahooks";
import PropTypes from "prop-types";
import { useState } from "react";
import useGlobal from "@/stores/global";

import { CustomEvents } from "@/constants/customEvents";
import ui from "@/constants/ui";
import { twMerge } from "tailwind-merge";

const DialogWsConnectError = ({ children }) => {
  const { setGlobal } = useGlobal();
  const [dlgVisible, setDlgVisible] = useState(false);
  const multiLanguage = useTranslation();
  const t = multiLanguage;
  useEventListener(
    CustomEvents["ws-connect-error"],
    () => {
      setDlgVisible(true);
      setGlobal({ network: false });
    },
    { target: window },
  );

  return (
    <Dialog
      open={dlgVisible}
      onOpenChange={(v) => {
        setDlgVisible(v);
      }}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        className="max-w-[400px] w-[400px] text-xs"
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>{t("networkError")}</DialogTitle>
        </DialogHeader>

        <div className="leading-5">{t("wsConnectError")}</div>

        <DialogFooter>
          <button
            onClick={() => {
              window.location.reload();
            }}
            className={twMerge(ui?.dialogFooterButtonClassName, " btn-primary")}
          >
            {multiLanguage("btnConfirm")}
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

DialogWsConnectError.propTypes = {
  children: PropTypes.any,
};

export default DialogWsConnectError;
