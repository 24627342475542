/* eslint-disable */

import { CustomEvents } from "@/constants/customEvents";
import useInterface from "@/stores/interface";
import { getPrivateSignatureLocalInfo, genApiSignature } from "@/utils/onboarding";

/**
 * 使用：
 * - 公有推送
 *  1、创建实例：
 *    const WS = new WSServer('wss://x.x.x/realtime_public');
 *  2、订阅：
 *    WS.subscribe('topicName', handler);
 *      + 有对应 topicName 的消息时，自动调用 handler，并传入 Object 数据：{ type: string; data: any; timestamp: number };
 *      + 同一个 topicName, 可多次订阅
 *  3、取消订阅：
 *    WS.unsubscribe('topicName', handler);
 *      + handler 必须为 订阅时的 函数引用，该功能类似 addEventListener/removeEventListener。代码内注意 handler 的声明、引用
 *      + 当相同主题有多个订阅回调时，仍有订阅未完全取消时，不向后台发送 取消订阅消息，只断开回调
 *  4、实例销毁 (注意多页面共用时谨慎关闭)
 *    WS.close();
 *
 * 二 私有推送
 *  1、创建实例
 *    const WS = new WSServer('wss://x.x.x/realtime_public');
 *  2、发送鉴权信息，同时订阅推送信息回调
 *    WS.getAccess(genAccessInfo, handler);
        + genAccessInfo 调用时返回 鉴权数据：
           const genAccessInfo = useCallback(() => {
              const topic = {type: 'subscribe', channel: 'ws_accounts_v1'};
              let info = {}
              if (privateClass) {
                // @ts-ignore
                info = privateClass.wssign({
                  requestPath: "/ws/accounts",
                  method: "GET",
                  isoTimestamp: Date.now(),
                });
              }
              return {...info, ...topic};
            }, [privateClass]);
 *      + 当有消息时 handler 传入 Object 数据：{ type: string; data: any; timestamp: number };
 *  4、实例销毁 (注意多页面共用时谨慎关闭)
 *    WS.close();
 *
 *  *** 自动脉搏通讯，定时发送 'pong' 消息，当断开时自动不断重连、重新订阅。
 *        + ping / pong，当前服务框架不需要关注后端发送的 ping，只需要时间间隔内不断发送 pong 消息即可；
 *
 */
export default class WSServer {
  constructor(link, isPrivate, captureException) {
    this.socket = null;
    this.pureLink = link;
    this.isArtificialClosed = false; // 是否是主动关闭
    this.msgHandleMap = {};
    this.pulseTimer = null;
    this.pulseInterval = 13000;
    this.reader = new FileReader();
    this.onLine = false;
    this.neeReSubscribeMsgList = []; // [[topic, armMsg]]
    this.isPrivateWS = isPrivate;
    this.hasSendAccessInfo = false; // 是否已经发送鉴权信息，用于重新订阅判断
    this.hasAccessed = isPrivate; // 是否获得订阅许可：公有推送 - 始终为 true; 私有推送 - 鉴权成功后 为 true;
    this.genAccessInfoHandler = null;
    this.privateLoginHandler = null; // 私有订阅 鉴权时提供的回调
    this.reConnectFlag = 0;
    this.reConnectTimer = null;
    this.maxReConnectTimes = 4;
    this.onlineCheckTimer = null;
    this.onlineCheckDuration = 30000; // 连接检测时间
    this.captureException = null;
    this.userSignInfo = "";
    this.serverTimestamp = "";

    this.onlineCheckIntervalId = null;
    this.onlineCheckInterval = 200; // 连接检测时间
    this.onLineListeners = [];
    this.privateSignature = "";
    this.logout = () => {
      if (this.socket) {
        this.socket.close();
        this.socket = null;
      }
      this.isArtificialClosed = true;
      this.clearPulse();
    };
    /**
     * 订阅 topic 消息
     * 当有对应 topic 消息返回是 调用 msgHandle
     * 1）类同 DOM.addEventListener，可以为相同 topic 订阅多个回调
     * 2）订阅相同 topic 时，当前方案为先取消之前的订阅，再重新订阅，否则 > 1 的相同 topic 订阅 只发送 delta 包，数据缺少
     * @param topic
     * @param msgHandle
     */
    this.subscribe = (topic, msgHandle) => {
      if (this.pureLink) {
        if (this.msgHandleMap[topic]) {
          // 已经注册该 topic
          this.msgHandleMap[topic].handles.push(msgHandle);
          if (this.msgHandleMap[topic].hasSubscribed) {
            // 此处判断已经发送过该 topic 订阅，
            this.msgHandleMap[topic].hasSubscribed = false;
            this.putData(topic, "unsubscribe");
            this.putData(topic, "subscribe");
          }
        } else {
          this.msgHandleMap[topic] = {
            hasSubscribed: false,
            handles: [msgHandle],
          };
          this.putData(topic, "subscribe");
        }
      }
    };
    // 类同 DOM.removeEventListener
    this.unsubscribe = (topic, msgHandle, forceClear) => {
      if (!msgHandle || typeof msgHandle !== "function") {
        console.error("The unsubscribe works like removeEventListener, need second argument same with subscribe handle");
        return;
      }
      if (this.msgHandleMap[topic]) {
        const handleIdx = this.msgHandleMap[topic].handles.indexOf(msgHandle);
        if (handleIdx !== -1) {
          this.msgHandleMap[topic].handles.splice(handleIdx, 1);
        }
        if (!this.msgHandleMap[topic].handles.length || forceClear) {
          delete this.msgHandleMap[topic];
          this.putData(topic, "unsubscribe");
        }
      }
    };
    this.close = () => {
      if (this.socket) {
        const topics = Object.keys(this.msgHandleMap);
        if (topics.length) {
          topics.forEach((topic) => {
            this.putData(topic, "unsubscribe");
            delete this.msgHandleMap[topic];
          });
        }
        this.onLine = false;
        this.isArtificialClosed = true;
        this.clearPulse();
        this.socket.close();
        this.privateLoginHandler = null;
        this.genAccessInfoHandler = null;
        this.socket = null;
        this.rmObserveVisibilityHandler();
      }
    };
    this.stop = () => {
      if (this.socket) {
        this.isArtificialClosed = true;
        this.socket.close();
        this.clearPulse();
        this.socket = null;
        this.onLine = false;
        this.rmObserveVisibilityHandler();
      }
    };
    this.reConnect = () => {
      if (!this.onLine) {
        this.serverTimestamp = "";
        if (this.reConnectFlag >= this.maxReConnectTimes) {
          // 超过最大重连次数
          console.warn("stop reConnect: ", this.pureLink);
          this.clearPulse();
          this.showOfflineTips();
          this.reConnectFlag = 0;
          this.reConnect();
        } else {
          if (this.reConnectTimer) {
            clearTimeout(this.reConnectTimer);
          }
          this.reConnectTimer = setTimeout(() => {
            if (this.socket) {
              this.socket.close();
              this.socket = null;
            }
            console.info("reConnect: ", this.pureLink);
            this.setNeeReSendInfo();
            this.connect();
            this.dealWithException();
            this.reConnectFlag += 1;
            this.reConnectTimer = null;
          }, 4 * this.reConnectFlag * 1000);
        }
      }
    };
    this.observeVisibilityHandler = () => {
      document.addEventListener("visibilitychange", this.handleVisibilityChanged);
    };
    this.rmObserveVisibilityHandler = () => {
      document.removeEventListener("visibilitychange", this.handleVisibilityChanged);
    };
    this.handleVisibilityChanged = () => {
      if (!document.hidden && !this.onLine) {
        this.reConnectFlag = 0;
        this.reConnect();
      }
    };
    this.closedHandler = () => {
      this.onLine = false;
      this.hasAccessed = true;
      if (this.socket) {
        this.socket.onmessage = null;
        this.socket.close();
      }
      this.socket = null;
      if (!this.isArtificialClosed) {
        this.reConnect();
      }
    };
    this.hideOfflineTips = () => {
      const wsMask = window.document.getElementById("wsDialog");
      if (wsMask) {
        wsMask.remove();
      }
    };
    this.checkOnLine = () => {
      if (this.onlineCheckTimer) {
        clearTimeout(this.onlineCheckTimer);
      }
      // private ws 不检查
      this.isPrivateWS ||
        (this.onlineCheckTimer = setTimeout(() => {
          if (!this.onLine) {
            this.showOfflineTips();
          }
        }, this.onlineCheckDuration));
    };
    this.showOfflineTips = () => {
      window.dispatchEvent(new CustomEvent(CustomEvents["ws-connect-error"], { detail: {} }));
    };
    this.ready = (callback) => {
      if (this.onLine) return callback();
      this.onLineListeners.push(callback);
      clearInterval(this.onlineCheckIntervalId);
      this.onlineCheckIntervalId = setInterval(() => {
        if (this.onLine) {
          clearInterval(this.onlineCheckIntervalId);
          // callback();
          this.onLineListeners.forEach((l) => l());
        }
      }, this.onlineCheckInterval);
    };

    this.init = () => {
      if (window.WebSocket) {
        if (link) {
          this.connect();
          this.dealWithException();
          if (captureException) {
            this.captureException = captureException;
          }
          this.observeVisibilityHandler();
          this.checkOnLine();
        } else {
          console.error("Please input WebSocket URL");
        }
      } else {
        console.error("This browser is not support WebSocket, please use other browsers");
      }
    };

    if (!this.isPrivateWS) {
      this.init();
    }
  }
  login(genInfo, msgHandle) {
    if (!this.socket) {
      this.setNeeReSendInfo();
      this.connect();
      this.dealWithException();
    }
    this.hasAccessed = false;
    this.genAccessInfoHandler = genInfo;
    this.privateLoginHandler = msgHandle || null;
    this.putAccess();
  }
  /**
   * 私有推送 发送鉴权消息
   * 1）鉴权信息使用缓存的回调动态生成
   * 2）判断鉴权的同时是否写的订阅信息，如果有且传递了对应回调方法则构建【topic - handler】对应关系以供消息返回是触发对应 topic 回调
   */
  async putAccess() {
    this.reConnect();
    // if (this.socket && this.onLine && this.genAccessInfoHandler) {
    //   this.hasSendAccessInfo = true;
    //   const loginMsg = await this.genAccessInfoHandler();
    //   this.userSignInfo = `userId: ${loginMsg.userId} apiKey: ${loginMsg.apiKey} sign: ${loginMsg.timestamp} pc: ${Date.now()} adjust: ${useInterface.getState()?.serverClock?.timestampAdjustment}ms`;
    //   delete loginMsg.userId;
    //   const {
    //     topics = []
    //   } = loginMsg;
    //   if (topics.length && typeof this.privateLoginHandler === "function") {
    //     topics.forEach((topic) => {
    //       if (this.privateLoginHandler) {
    //         if (this.msgHandleMap[topic]) {
    //           this.msgHandleMap[topic].handles.push(this.privateLoginHandler);
    //         } else {
    //           this.msgHandleMap[topic] = {
    //             handles: [this.privateLoginHandler],
    //             hasSubscribed: false,
    //           };
    //         }
    //       }
    //     });
    //     delete loginMsg.topics;
    //     this.setNeeReSendInfo();
    //   }
    //   const msg = {
    //     op: "login",
    //     args: [JSON.stringify(loginMsg)],
    //   };
    //   this.socket.send(JSON.stringify(msg));
    // }
  }

  connect() {
    if (!this.onLine && this.pureLink) {
      if (this.genAccessInfoHandler) {
        this.hasSendAccessInfo = false;
        this.hasAccessed = false;
      }
      const link = this.pureLink;
      const curTimeStamp = Date.now();
      const url = `${link}${link.includes("?") ? "&" : "?"}timestamp=${curTimeStamp}`;
      this.isArtificialClosed = false;
      // todo 私有接口优化成事件触发，需要获取到accountId后再链接
      if (this.isPrivateWS) {
        const { currentActiveApiKey, timestamp: curTimeStamp, accountId } = getPrivateSignatureLocalInfo();
        if (!accountId || !currentActiveApiKey?.secret) return;
        const url = `${link}${link.includes("?") ? "&" : "?"}accountId=${accountId}&timestamp=${curTimeStamp}`;
        // this.socket = new WebSocket(privateUrl);
        if (currentActiveApiKey?.secret) {
          const requestBody = `accountId=${accountId}&timestamp=${curTimeStamp}`;
          const signParams = {
            timestamp: curTimeStamp,
            httpMethod: "GET",
            requestUri: "/api/v1/private/ws",
            requestBody: requestBody,
            secret: currentActiveApiKey?.secret,
          };
          const signature = genApiSignature(signParams);
          const headerKey = useInterface.getState()?.metadata?.global?.appName;
          const wssSignParam = {
            ["X-" + headerKey + "-Api-Key"]: currentActiveApiKey?.apiKey,
            ["X-" + headerKey + "-Passphrase"]: currentActiveApiKey?.passphrase,
            ["X-" + headerKey + "-Signature"]: signature,
            ["X-" + headerKey + "-Timestamp"]: curTimeStamp,
          };

          // 对wssSignSignature取json并做一次 base64 编码，并去除=
          const wssSignSignature = btoa(JSON.stringify(wssSignParam)).replaceAll("=", "");
          this.privateSignature = wssSignSignature;
          // console.log("base signature", signParams);
          // console.log("wssSignParam", wssSignParam);
          // console.log("wssSignSignature", wssSignSignature);
          this.socket = new WebSocket(url, wssSignSignature);
        }
      } else {
        this.socket = new WebSocket(url);
      }
      if (this.socket) {
        this.dealWithMsg();
        this.makePulse();
      }
    }
  }
  /**
   * 封装【待重新发送到服务端信息】列表
   */
  setNeeReSendInfo() {
    this.neeReSubscribeMsgList.length = 0;
    let topicList = Object.keys(this.msgHandleMap);
    if (topicList.length) {
      topicList.forEach((topic) => {
        this.msgHandleMap[topic].hasSubscribed = false;
        const msg = {
          type: "subscribe",
          args: [topic],
        };
        this.neeReSubscribeMsgList.push([topic, JSON.stringify(msg)]);
      });
    }
  }
  /**
   * 消息分类处理
   */
  dealWithMsg() {
    if (this.socket) {
      this.socket.onmessage = (events) => {
        const resObj = JSON.parse(events.data || "{}");
        if (resObj.type === "ping" && resObj.time) {
          this.serverTimestamp = resObj.time;
        } else {
          let results = events.data;
          // 当前不使用 Blob 格式，使用时需深度优化
          if (results instanceof Blob) {
            this.reader.readAsText(results, "UTF-8");
            this.reader.onload = () => {
              try {
                results = JSON.parse(this.reader.result);
                this.triggerHandle(results);
              } catch (e) {}
            };
          } else {
            try {
              this.triggerHandle(resObj);
            } catch (e) {}
          }
        }
      };
    }
  }
  /**
   * 服务端返回消息，触发对应 topic 缓存的【回调列表】
   * @param results
   */
  triggerHandle(results) {
    const { channel, type, content, ts } = results;
    const msgHandles = this.msgHandleMap[channel] && this.msgHandleMap[channel].handles;
    if (msgHandles && msgHandles.length && type != "subscribed") {
      msgHandles.forEach((handle) => {
        handle({
          type,
          timestamp: ts,
          ...content,
        });
      });
    }
    document.dispatchEvent(new CustomEvent(CustomEvents["ws-message"], { detail: results }));
  }
  /**
   * 向服务端发送消息
   * 1、当前仅需支持订阅 - subscribe 和 取消订阅 - unsubscribe
   * 2、当发送订阅时，如果和服务端并未建立连接，则缓存该订阅消息
   * @param topic
   * @param op
   */
  putData(topic, type) {
    const msg = { type, channel: topic };
    if (this.onLine && this.socket) {
      type === "subscribe" ? this.sendToService(topic, JSON.stringify(msg)) : this.socket.send(JSON.stringify(msg));
      if (type === "subscribe") {
        this.neeReSubscribeMsgList.push([topic, JSON.stringify(msg)]);
      }
    }
  }
  sendToService(topic, armMsg) {
    if (this.msgHandleMap[topic]) {
      if (!this.msgHandleMap[topic].hasSubscribed && this.socket) {
        this.socket.send(armMsg);
      }
      this.msgHandleMap[topic].hasSubscribed = true;
    }
  }
  /**
   * 定时发送脉搏消息
   * 1、无需关注服务端返回，直接定时发送消息即可。（服务端会判断脉搏发送的时间戳是否合法、并判断是否要断连）
   * 2、连接被动断开时 this.onLine 和 this.socket 都为布尔 false，此时清除定时器并重连
   */
  makePulse() {
    this.clearPulse();
    this.pulseTimer = setInterval(async () => {
      if (this.onLine && this.socket) {
        let localTimestamp = useInterface.getState()?.serverClock?.getAdjustedIsoTimestamp;
        // 矫正时间 和 ping 下发时间 相差 100s，则使用 ping 时间戳
        if (this.serverTimestamp && Math.abs(localTimestamp - Number(this.serverTimestamp)) > 100000) {
          localTimestamp = this.serverTimestamp;
        }
        const info = {
          type: "pong",
          time: "" + localTimestamp,
        };
        this.socket.send(JSON.stringify(info));
      } else {
        this.clearPulse();
      }
    }, this.pulseInterval);
  }
  clearPulse() {
    if (this.pulseTimer) {
      clearInterval(this.pulseTimer);
      this.pulseTimer = null;
    }
  }
  /**
   * 异常处理
   * 1、连接建立时，
   *    1）公有推送启动脉搏消息
   *    2）私有推送发送鉴权消息，并启动脉搏消息
   *    3）发送待订阅列表（主动订阅时，存在调用时推送并没有建立连接，这时会缓存入待订阅列表，此处发送该列表消息）
   * 2、连接出错、或被动中断重置全局连接状态相关的变量
   * 3、页面卸载，中断关闭连接
   */
  dealWithException() {
    if (this.socket) {
      this.socket.onopen = () => {
        this.reConnectFlag = 0;
        this.onLine = true;
        this.hideOfflineTips();
        setTimeout(async () => {
          if (this.isPrivateWS) {
            // 私有推送
            if (!this.hasSendAccessInfo) {
              await this.putAccess();
            }
          } else {
            // 公有推送
            if (this.neeReSubscribeMsgList.length) {
              this.neeReSubscribeMsgList.forEach((msg) => {
                this.sendToService(msg[0], msg[1]);
              });
              this.neeReSubscribeMsgList.length = 0;
            }
          }
        }, 300);
      };
      this.socket.onerror = () => {
        console.warn("ws error... ", this.pureLink);
        this.closedHandler();
      };
      this.socket.onclose = (ev) => {
        console.warn("ws close ... ", ev.code, ev.reason || "null", this.pureLink);
        this.closedHandler();
      };
      window.addEventListener("beforeunload", () => {
        this.clearPulse();
        this.rmObserveVisibilityHandler();
        if (this.socket) {
          this.socket.close();
        }
      });
    }
  }
}
