import React from "react";
import DialogWsConnectError from "@/modules/layout/components/DialogWsConnectError";
import DisclaimerAndSignDialog from "@/modules/layout/components/DisclaimerAndSignDialog";
import UserUpdater from "@/modules/layout/components/UserUpdater";
import WalletConnectDialog from "@/modules/walletConnect/components/WalletConnectDialog";
import WsStatusToaster from "./components/WsStatusToaster";
import ReconnectWalletOnSwitchAccount from "@/modules/layout/components/ReconnectWalletOnSwitchAccount";
// import ReconnectWalletOnMount from "@/modules/layout/components/ReconnectWalletOnMount";

function GlobalLayoutModule() {
  return (
    <>
      <UserUpdater />
      <WsStatusToaster />
      <WalletConnectDialog />
      <DisclaimerAndSignDialog />
      <DialogWsConnectError />
      {/* <ReconnectWalletOnMount /> */}
      <ReconnectWalletOnSwitchAccount />
    </>
  );
}

export default GlobalLayoutModule;
