// 动态加载组件
import { lazy, Suspense } from "react";
import Spinner from "@/components/Spinner";
function LazyLoad(path) {
  const Element = lazy(() => path);
  return (
    // 返回组件，Suspense:懒加载组件
    <Suspense fallback={<Spinner className="min-h-[120px]" />}>
      <Element />
    </Suspense>
  );
}
export default LazyLoad;
