import Icon from "@/components/Icon";
import { useToast } from "@/components/ui/use-toast";
import ui from "@/constants/ui";
// import useActiveChains from "@/hooks/useActiveChains";
import useTranslation from "@/hooks/useTranslation";
import accountIcon from "@/icons/account.svg";
import arrowDown from "@/icons/arrow-down.svg";
import caret from "@/icons/caret.svg";
import copyIcon from "@/icons/copy.svg";
import depositWalletIcon from "@/icons/deposit-wallet.svg";
import privy from "@/icons/privy.svg";
import referralIcon from "@/icons/referral.svg";
import exportWalletIcon from "@/icons/export-wallet.svg";
// import unsupportedIcon from "@/icons/unsupported.svg";
import walletIcon from "@/icons/wallet.svg";

import HeaderDropDown from "@/modules/layout/components/HeaderDropDown";
import useGlobal from "@/stores/global";
import { shortStr, toThousandString } from "@/utils/funcs";
import CopyToClipboard from "react-copy-to-clipboard";

import { CustomEvents } from "@/constants/customEvents";
import Logout from "@/modules/layout/components/Logout";
import useUserPrivateInfo from "@/modules/trade/hooks/useUserPrivateInfo";
import useSymbol from "@/stores/symbol";
import useUser from "@/stores/user";
import { usePrivy } from "@privy-io/react-auth";
import { Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAccount /*, useSwitchChain*/ } from "wagmi";
import { logEvent } from "@/utils/analytics";
const Settings = lazy(() => import("./Settings"));

const WalletConnect = () => {
  const { setGlobal } = useGlobal();
  const { toast: Toast } = useToast();
  const multiLanguage = useTranslation();
  const t = multiLanguage;
  const account = useAccount();
  const { currentActiveAccount } = useUser();
  const { user, ready, authenticated, exportWallet } = usePrivy();
  // Check that your user is authenticated
  const isAuthenticated = ready && authenticated;
  // Check that your user has an embedded wallet
  const hasEmbeddedWallet = !!user?.linkedAccounts?.find((account) => account.type === "wallet" && account.walletClientType === "privy");

  // console.log("WalletConnect", ready, authenticated, user?.wallet?.address);
  const address = currentActiveAccount?.ethAddress || user?.wallet?.address;

  // const { switchChainAsync, isPending } = useSwitchChain();
  // const { chains } = useActiveChains();
  // const { unsupported } = useActiveChains();

  const { isAlreadySigned } = useUser();
  const alreadySigned = isAlreadySigned();
  const { getCurrentSymbol } = useSymbol();
  const { quoteCoinPrecision, quoteCoin, quoteCoinIcon } = getCurrentSymbol();

  // const handleSwitchChainId = async () => {
  //   try {
  //     await switchChainAsync({ chainId: +chains?.[0]?.chainId });
  //   } catch (e) {
  //     Toast.show(t(e?.details), { type: "error" });
  //   }
  // };

  // const switchChainIfNeeded = () => {
  //   if (address && unsupported) {
  //     handleSwitchChainId();
  //   }
  // };

  const { balance, totalAssets } = useUserPrivateInfo();

  return address ? (
    <HeaderDropDown
      id="wallet-connect-dropdown"
      // onClick={switchChainIfNeeded}
      className="dropdown-end ml-2"
      contentClassName="hover:[&>a]:bg-base-200 [&>a+a]:mt-2 sm:min-w-[240px]"
      title={
        <>
          {
            /*isPending ||*/ account.status == "reconnecting" || !ready ? (
              <span className="loading loading-spin size-5"></span>
            ) : /* unsupported ? (
            <Icon icon={unsupportedIcon} className="size-5" />
          ) :*/ currentActiveAccount?.wallet == "mpc" ? (
              <Icon icon={privy} className="size-5" />
            ) : currentActiveAccount?.walletIcon ? (
              <img className="size-5" src={currentActiveAccount?.walletIcon} />
            ) : account?.connector?.icon ? (
              <img className="size-5" src={account?.connector?.icon} />
            ) : (
              <Icon icon={walletIcon} className="size-5" />
            )
          }
          <span className="font-normal text-sm">{shortStr(address, 11)}</span>
          {address /*&& !unsupported*/ && <Icon icon={caret} className="caret h-2.5 fill-foreground transition-all origin-center" />}
        </>
      }
    >
      {address /*&& !unsupported*/ && !alreadySigned && (
        <div className={twMerge("flex flex-col gap-3 justify-center text-xs my-2 text-center", ui?.recoverClassName)}>
          <div className="">{t("welcomeBack")}</div>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              setGlobal({ disclaimerAndSignVisible: true });
            }}
          >
            {t("recoverKeys")}
          </button>
        </div>
      )}
      {address /*&& !unsupported*/ && (
        <div className={twMerge(`flex items-center justify-between gap-2 mb-2 !min-h-[58px] px-1.5`)}>
          {currentActiveAccount?.wallet == "mpc" ? (
            <Icon icon={privy} className="size-6" />
          ) : currentActiveAccount?.walletIcon ? (
            <img className="size-6" src={currentActiveAccount?.walletIcon} />
          ) : (
            <img className={twMerge("size-6", account?.connector?.icon ? "" : "invisible")} src={account?.connector?.icon} />
          )}

          <span className="">{shortStr(address, 11)}</span>
          <CopyToClipboard
            onCopy={() => {
              Toast.show(t("copySuccess"));
            }}
            text={address}
          >
            <Icon icon={copyIcon} className="cursor-pointer icon size-5 shrink-0" />
          </CopyToClipboard>
        </div>
      )}

      {address && (
        <>
          <Link to="/account" className={twMerge(`flex flex-col !items-start !min-h-auto py-2 gap-2 mb-2 !text-foreground`)}>
            <div className="text-sm leading-4 text-[--font-gray]">{t("totalAssets")}</div>
            <div className="flex justify-between w-full">
              <span className="text-lg leading-5 font-medium">
                {toThousandString(totalAssets, quoteCoinPrecision)} {quoteCoin}
              </span>
              <Icon icon={arrowDown} className="icon size-5 -rotate-90" />
            </div>
          </Link>
          <a
            className=" flex flex-col !items-start !min-h-auto py-2 gap-2 !text-foreground bg-[#111413]"
            onClick={() => {
              window.dispatchEvent(new CustomEvent(CustomEvents["open-deposit-dialog"], { detail: {} }));
            }}
          >
            <span className="text-sm leading-4 text-[--font-gray]">{t("balanceWithType", { coin: quoteCoin })}</span>
            <div className="w-full h-6 flex items-center justify-between gap-2">
              <img className="size-5" src={quoteCoinIcon} />
              <span className="text-lg font-medium">{toThousandString(balance, quoteCoinPrecision)}</span>
              <span className="ml-auto">
                <Icon icon={depositWalletIcon} className="ml-auto icon size-5 cursor-pointer" />
              </span>
            </div>
          </a>
          {address /*&& !unsupported*/ && alreadySigned && (
            <Suspense>
              <Settings></Settings>
            </Suspense>
          )}
          <Link to="/account" className={twMerge(``)}>
            <Icon icon={accountIcon} className=" size-5" />
            <span className="font-normal text-sm">{t("AccountManagement")}</span>
          </Link>

          <Link to="/referral" className={twMerge(``)}>
            <Icon icon={referralIcon} className=" size-5" />
            <span className="font-normal text-sm">{t("menu.referral")}</span>
          </Link>

          {/* 1.0 版本不展示 */}
          {/* <Link to="/keyManagement" className={twMerge(``)}>
            <Icon icon={apiIcon} className=" size-5" />
            <span>{t("api")}</span>
          </Link> */}

          {/* <Link to="/bot-key" className={twMerge(``)}>
            <Icon icon={keyIcon} className="text-primary size-5" />
            <span>{t("botKey")}</span>
          </Link> */}
          {!isAuthenticated || !hasEmbeddedWallet ? null : (
            <a onClick={exportWallet}>
              <Icon icon={exportWalletIcon} className="size-5" />
              <span>{t("menu.exportEmailWallet")}</span>
            </a>
          )}
          <Logout />
        </>
      )}
    </HeaderDropDown>
  ) : (
    <button
      className={twMerge("btn btn-primary btn-sm px-3 ml-3", ui?.connectWalletBtnClassName)}
      onClick={() => {
        setGlobal({ connectModalVisible: true });
        logEvent("button", "click", "Web_Click_Navigation_ConnectWallet_btn");
      }}
    >
      <div className="text-sm">{t("connectWallet")}</div>
    </button>
  );
};
export default WalletConnect;
