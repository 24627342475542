import React from "react";
import "@/App.css";
import "@/style/global.css";
import App from "@/App";
import "@/config/sentryConfig";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
