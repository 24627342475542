import { CustomEvents } from "@/constants/customEvents";
import { fullChains } from "@/constants/wallets";
import useActiveChains from "@/hooks/useActiveChains";
import useUser from "@/stores/user";
import { createWalletClient, custom, verifyMessage } from "viem";
import { useConnectorClient } from "wagmi";

const addEventListenerOnce = (target, type, listener, options) => {
  const newListener = (...args) => {
    target.removeEventListener(type, newListener, options);
    listener(...args);
  };
  target.addEventListener(type, newListener, options);
};

// 兼容 mpc 钱包和非 mpc 钱包的 signMessage 签名
export default () => {
  const { currentActiveAccount } = useUser();
  const { data: connectorClient } = useConnectorClient();
  const { currentActiveChain } = useActiveChains();

  return {
    signMessage: async (message) => {
      if (currentActiveAccount?.wallet == "mpc") {
        window.dispatchEvent(new CustomEvent(CustomEvents["mpc-sign-message"], { detail: { message } }));
        const signature = await new Promise((resolve) => {
          addEventListenerOnce(window, CustomEvents["mpc-sign-message-result"], (e) => {
            const { signature } = e.detail;
            resolve(signature);
          });
        });
        return signature;
      } else {
        const chain = fullChains.find((i) => +i.id === +currentActiveChain?.chainId);
        if (!chain) {
          throw new Error("Invalid chain");
        }
        // if (!tempAccount) {
        //   throw new Error("Invalid account");
        // }

        const walletClient = createWalletClient({
          account: currentActiveAccount?.ethAddress,
          chain,

          transport: custom({
            async request({ method, params }) {
              const request = connectorClient?.transport?.request || window?.ethereum?.request;
              const response = await request({ method, params });
              return response;
            },
          }),
        });

        const signature = await walletClient.signMessage({
          account: currentActiveAccount?.ethAddress,
          message,
        });

        const valid = await verifyMessage({
          address: currentActiveAccount?.ethAddress,
          message,
          signature,
        });

        if (!valid) {
          throw new Error("Invalid Signer");
        }
        return signature;
      }
    },
  };
};
