import { keyPairFromData, stripHexPrefix } from "@/components/trade/helpers";
import { fullChains } from "@/constants/wallets";
import useUser from "@/stores/user";
import axios from "axios";
import { Buffer } from "buffer";
import { sha256 } from "js-sha256";
import { createWalletClient, custom, keccak256 } from "viem";

export function hexToByteArray(hex) {
  if (hex.startsWith("0x")) {
    hex = hex.substring(2);
  }

  let byteLen = hex.length / 2;
  let ret = new Uint8Array(byteLen);

  for (let i = 0; i < byteLen; i++) {
    let m = i * 2;
    let n = m + 2;
    let intVal = parseInt(hex.substring(m, n), 16);
    ret[i] = intVal;
  }

  return ret;
}

export function byteArrayToHex(byteArray) {
  return Array.from(byteArray, function (byte) {
    // `& 0xFF`确保我们只取字节的最后两位
    // `toString(16)`将数字转换为十六进制表示
    // `padStart(2, '0')`确保输出为两位数
    return (byte & 0xff).toString(16).padStart(2, "0");
  }).join("");
}

export function uuidFormat(apiKey) {
  return [apiKey.substring(0, 8), apiKey.substring(8, 12), apiKey.substring(12, 16), apiKey.substring(16, 20), apiKey.substring(20, 32)].join("-");
}

export function urlBase64Encode(byteArray) {
  // 使用Buffer.from来创建一个Buffer实例，然后调用toString时传入'base64'来获取Base64编码的字符串
  let base64 = Buffer.from(byteArray).toString("base64");

  // 对字符串进行URL安全的替换操作
  return base64
    .replace(/=+$/, "") // 删除末尾的'='字符
    .replace(/\+/g, "-") // 将'+'替换为'-'
    .replace(/\//g, "_"); // 将'/'替换为'_'
}

export function subArray(byteArray, startIndex, length) {
  return byteArray.slice(startIndex, startIndex + length);
}

export const getPrivateSignatureLocalInfo = () => {
  const currentActiveWagmiConfig = window.localStorage.getItem("wagmi.store") ? JSON.parse(window.localStorage.getItem("wagmi.store")) : null;
  const userState = useUser.getState();
  const account = (userState?.currentActiveAccount?.ethAddress || currentActiveWagmiConfig?.state?.connections?.value?.[0]?.[1]?.accounts?.[0])?.toLowerCase();

  const currentActiveApiKey = userState.keys?.[account]?.[userState.currentClientAccountId]?.apiKey;
  const accountId = userState?.currentActiveAccount?.accountId;

  // console.log("getPrivateSignatureLocalInfo", account, currentActiveApiKey, accountId);

  const timestamp = `${+new Date()}`;
  return {
    accountId,
    currentActiveApiKey,
    timestamp,
  };
};

export function toQueryString(obj, prefix) {
  let str = [],
    p;
  for (p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      let k = prefix ? `${prefix}[${p}]` : p;
      let v = obj[p];

      str.push(
        v !== null && Array.isArray(v)
          ? `${encodeURIComponent(k)}=${v.join("&")}`
          : v !== null && typeof v === "object"
          ? // 递归调用对于嵌套对象, prefix置空匹配后端规则l2Signature=r=xx&s=xx&v=xx
            `${encodeURIComponent(k)}=${toQueryString(v, "")}`
          : `${encodeURIComponent(k)}=${v}`,
      );
    }
  }
  return str.join("&");
}

export function sortObjectByKeys(obj) {
  if (!obj) return null;
  // 获取对象的所有键并按ASCII码顺序进行排序
  let sortedKeys = Object.keys(obj).sort();

  // 创建一个新的对象，并按照排序后的键的顺序添加键值对
  let sortedObj = {};
  for (let key of sortedKeys) {
    if (!Array.isArray(obj[key]) && typeof obj[key] === "object") {
      sortedObj[key] = sortObjectByKeys(obj[key]);
    } else {
      sortedObj[key] = obj[key];
    }
  }

  return sortedObj;
}

export const checkPrefix = (str, prefix) => {
  if (!str) return str;
  if (str?.startsWith(prefix)) {
    return str;
  }
  return `${prefix}${str}`;
};

export function generateHmacSha256(key, message) {
  // 将密钥和消息转换为 Uint8Array
  const encoder = new TextEncoder();

  // 对字符串进行编码
  var encode = encodeURI(key);
  // 对编码的字符串转化base64
  var keyBytes = btoa(encode);

  const messageBytes = encoder.encode(message);

  const hmac = sha256.hmac.create(keyBytes);
  hmac.update(messageBytes);
  const signature = hmac.hex();
  return signature;
}

export const genApiSignature = ({ timestamp, httpMethod, requestUri, requestBody, secret }) => {
  // Create the message from the concatenation of timestamp, httpMethod, requestUri, and requestBody
  const message = timestamp + httpMethod + requestUri + requestBody;
  const signature = generateHmacSha256(secret, message);
  return signature;
};

export const checkUserExist = async ({ address }) => {
  return axios.get("/v1/public/user/checkUserExist", {
    params: {
      ethAddress: address,
    },
  });
};

export const genL2Key = async ({ account, chainId, appEnv, appOnlySignOn, appName, clientAccountId = "main", connectorClient }) => {
  const chain = fullChains.find((i) => +i.id === +chainId);

  if (!chain) {
    throw new Error("Invalid chain");
  }
  if (!account) {
    throw new Error("Invalid account");
  }

  const walletClient = createWalletClient({
    account,
    chain,
    transport: custom({
      async request({ method, params }) {
        const request = connectorClient?.transport?.request || window?.ethereum?.request;
        const response = await request({ method, params });
        return response;
      },
    }),
  });

  // 调用钱包签名生成 signature
  const signature = await walletClient.signMessage({
    account,
    message: `name: ${appName}\nenvId: ${appEnv}\naction: L2 Key\nonlySignOn: ${appOnlySignOn}\nclientAccountId: ${clientAccountId}`,
  });

  // 去 “0x”-> 转 buffer 十六进制 -> keccak256
  // 使用 asEcKeyPair -> starkEc.keyFromPrivate 函数从处理后的私钥创建一个椭圆曲线密钥对对象
  // 将上面椭圆密钥对象（ecKeyPair）传递给 asSimpleKeyPair ->
  // 通过 ecPrivateKey = ecKeyPair.getPrivate() 获取私钥
  // 通过 ecPublicKey = ecKeyPair.getPublic() 获取公钥
  // 通过 ecPublicKey.getX() 获取 X 坐标在转换十六进制生成 publicKey
  // 通过 ecPublicKey.getY() 获取 Y 坐标在转换十六进制生成 publicKeyYCoordinate
  // 通过 ecPrivateKey 转换十六进制 生成 privateKey
  const keyPair = keyPairFromData(Buffer.from(stripHexPrefix(signature), "hex"));

  return {
    keyPair,
    signature,
  };
};

// sign_typedDataV4
export const genApiKey = async ({ account, chainId, appOnlySignOn, appName, connectorClient, apiName }) => {
  const chain = fullChains.find((i) => +i.id === +chainId);
  if (!chain) {
    throw new Error("Invalid chain");
  }
  if (!account) {
    throw new Error("Invalid account");
  }
  const walletClient = createWalletClient({
    account,
    chain,
    transport: custom({
      async request({ method, params }) {
        const request = connectorClient?.transport?.request || window?.ethereum?.request;
        const response = await request({ method, params });
        return response;
      },
    }),
  });

  const signature = await walletClient.signMessage({
    account,
    message: `action: ${appName} Onboard\nonlySignOn: ${appOnlySignOn}${apiName ? "\nparam: " + apiName : ""}`,
  });

  // signature string
  const tempSignature = signature.replace("0x", "");
  // 64 bit signature Uint8Array
  const tempBuffer = hexToByteArray(tempSignature);
  // buffer slice 0~31 is then encrypted with keccak256
  const r_buffer_hash = keccak256(subArray(tempBuffer, 0, 32));
  // buffer slice 32~64 is then encrypted with keccak256
  const s_buffer_hash = keccak256(subArray(tempBuffer, 32, 32));
  // apikey "be923ec2-aea9-c91a-2c28-33ddcefaf7c1"
  const apiKey = uuidFormat(byteArrayToHex(subArray(hexToByteArray(s_buffer_hash), 0, 16)));
  // used to generate sha3 keccak256 signatures
  const secret = urlBase64Encode(subArray(hexToByteArray(r_buffer_hash), 0, 32));
  // password used for encryption and decryption
  const passphrase = urlBase64Encode(subArray(hexToByteArray(s_buffer_hash), 16, 16));

  const keyPair = {
    apiKey,
    passphrase,
    secret,
  };

  return {
    keyPair,
    signature,
  };
};

export const registerAccount = async ({ l2Key, l2KeyYCoordinate, clientAccountId = "main" }) => {
  return axios.post("/v1/private/account/registerAccount", {
    l2Key: checkPrefix(l2Key, "0x"),
    l2KeyYCoordinate: checkPrefix(l2KeyYCoordinate, "0x"),
    clientAccountId: clientAccountId,
  });
};

export const onboardSite = async ({ address, appOnlySignOn, signature, l2Key, l2KeyYCoordinate, clientAccountId = "main" }) => {
  return axios.post("/v1/public/user/onboardSite", {
    ethAddress: address,
    onlySignOn: appOnlySignOn,
    // param: param,
    signature: signature,
    l2Key: checkPrefix(l2Key, "0x"),
    l2KeyYCoordinate: checkPrefix(l2KeyYCoordinate, "0x"),
    clientAccountId: clientAccountId,
  });
};
