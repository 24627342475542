// import logo from "@/assets/img/logo.png";
import Icon from "@/components/Icon";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import ui from "@/constants/ui";
import useTranslation from "@/hooks/useTranslation";
import qrcode from "@/icons/qrcode.svg";
import useUser from "@/stores/user";
import { useDebounceFn, useInterval, useRequest } from "ahooks";
import axios from "axios";
import CryptoJS from "crypto-js";
import dayjs from "dayjs";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

const AppQrCode = () => {
  const [dlgVisible, setDlgVisible] = useState(false);
  const [view, setView] = useState(false);
  const { isAlreadySigned, keys, account: accountList, currentClientAccountId } = useUser();
  const clientAccountId = currentClientAccountId;
  const accountInfo = accountList?.find((item) => item.clientAccountId === clientAccountId);

  // 初始字符串无实际意义，长度接近真实字符串，生成的初始二维码更接近真实
  const [encrypted, setEncrypted] = useState(
    "1da3059dS6ec0S4c90S8e26S3ac5ccc58991U2FsdGVkX1/QvGK/J6kSQg8nQ8zrzSwvnmlhs0ChiaMCgmP/jkTuImtVqSS31ss1EIkmSVYgUkJPsSJF34bUT0s3Jq1dcl1QTaL42cI0szfndfKauLnIgXDpI50K6Th8gHwFd3cT4r6q9B0JI9iM4NacuhpD19RprQ4iFSP0PARBfMtI5VAp51w26EHD2V5S2nh0Ua8DR2Ms/i5aMofE5BEZ+JmC8MYk6VlQqsEuF5+LF998mbX21YRWIJAj+3q3hB2PXdzEyX9AnY52sGa4Uu3Q8A6ICGRY38aSbVN2/PZRJim0EU402GPlBLhhDsgzBwkiyWtCXJk0L5GtEDejrNcR85B0d38IfPXn21n+QIokQdn/phBEDj9/gjCn5fDv0zYIfDgGb1ra6lif6JHY0rvPNCBISCdpgARm/Dvf0ijuR1EA0mHH4j3M9g+TobkSsIYxnHSphcYPncyMeTDt2TwYQ3hKoQXYIIeoI0lLiGcgaADzYQJnPvhUax0KMIfAhS3kuPjEvLTKLjmV6fvEdz1yfWl9+4YB2KvpS91nSVy9cQ3tbccUpekccXm1d1pKu0RWUVL+CA+RpI1l2ZNIKUkSqhMjyCjFdDGTI/Pyjtl/PkrNZWfuImVaoUG0m4n2dzB8Ci3N9gtx2F5QnMbgjCk1BMzt/WpM4TggwWmZI0WcUiejpk0xKggkwYCyAUwBzp/VaqnI5NHxt051siH5eA==",
  );

  const { runAsync } = useRequest(
    () =>
      axios
        .post("/v1/private/user/createAppScanSecret", {
          expireTime: dayjs().add(5, "m").valueOf(), // token 5 分钟过期
        })
        .then(refreshEncrypted),
    { manual: true },
  );

  useInterval(runAsync, view ? 1000 * 60 * 5 : undefined); // 5 分钟刷新一次，view = false 时不刷新

  const { run: debouncedRunAsync } = useDebounceFn(runAsync, { leading: true, wait: 10000 });

  const refreshEncrypted = (data) => {
    const str2Encrypt = JSON.stringify({
      ethAddress: accountInfo?.ethAddress,
      clientAccountId,
      id: accountInfo?.id,
      ...keys?.[accountInfo?.ethAddress?.toLowerCase()]?.[accountInfo?.clientAccountId],
    });
    const iv = CryptoJS.enc.Hex.parse("101112131415161718191a1b1c1d1e1f");
    const key = CryptoJS.enc.Utf8.parse(data?.data?.data?.secret);
    //加密
    const encrypted = CryptoJS.AES.encrypt(str2Encrypt, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const enBase64 = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    // token 长度固定 36 位，可以直接取子串得到
    // app 端拿到 token 通过请求 /v1/public/user/getAppScanSecret 获取 secret
    setEncrypted(data?.data?.data?.token + enBase64);
  };
  const alreadySigned = isAlreadySigned();

  const t = useTranslation();
  return alreadySigned ? (
    <Dialog
      open={dlgVisible}
      onOpenChange={(v) => {
        setDlgVisible(v);
        setView(false);
      }}
    >
      <DialogTrigger asChild>
        <div className={ui?.headerIconLinkClassName}>
          <Icon icon={qrcode} className="size-6" />
        </div>
      </DialogTrigger>
      <DialogContent
        className="sm:max-w-[456px]"
        onOpenAutoFocus={(e) => {
          setView(false);
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>{t("appLogin")}</DialogTitle>
        </DialogHeader>
        <div className="pt-6 px-4 text-center flex flex-col items-center">
          <div className={twMerge("size-[350px] inline-flex justify-center items-center rounded-lg relative", view ? "bg-white" : "")}>
            <QRCodeSVG
              value={encrypted}
              className="mx-auto cursor-pointer"
              size={330}
              level="L" // qrcode level: ["L", "M", "Q", "H"]
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              includeMargin={false}
              onClick={() => setView(false)}
              // imageSettings={{
              //   src: logo,
              //   height: 56,
              //   width: 56,
              //   excavate: true,
              // }}
            />
            <div
              className={twMerge("absolute inset-[10px] z-[4] bg-black/95 text-sm transition-all ", view ? "opacity-0" : "opacity-100")}
              onClick={async () => {
                setView(!view);
                if (!view) {
                  debouncedRunAsync();
                }
              }}
            >
              <div className="w-full h-full gap-2 text-brand cursor-pointer flex items-center justify-center">{t("clickToView")}</div>
            </div>
          </div>
          <div className="w-full mt-6 text-sm text-foreground font-medium">{t("appScanTitle")}</div>
          <div className="w-full mt-2 text-sm text-foreground-tertiary font-medium">{t("appScanDesc")}</div>
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
};

export default AppQrCode;
