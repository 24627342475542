import { fullChains } from "@/constants/wallets";
import useInterface from "@/stores/interface";
import { PrivyProvider } from "@privy-io/react-auth";
import { WagmiProvider, createConfig } from "@privy-io/wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { createClient, http } from "viem";
import { winENV } from "@/utils/env";

function MyPrivyProvider({ children }) {
  const queryClient = new QueryClient();
  const { metadata } = useInterface();

  const config = useMemo(() => {
    const chains = metadata?.multiChain?.chainList
      ?.filter((j) => fullChains?.find((i) => +i?.id === +j?.chainId))
      ?.map((k) => {
        return {
          ...k,
          chainId: +k?.chainId,
          id: +k?.chainId,
        };
      });

    if (!chains?.length) {
      return undefined;
    }
    if (window.dangerouslyExportedPrivyConfig) {
      return window.dangerouslyExportedPrivyConfig;
    } else {
      window.dangerouslyExportedPrivyConfig = createConfig({
        chains: chains,
        connectors: [
          // walletConnect({
          //   projectId: "fd316edf6637c7a3aae560559fb8422d",
          // }),
        ],
        client({ chain }) {
          return createClient({
            chain,
            transport: http(winENV?.[`CHAIN_RPC_URL_${chain.id}`] || undefined),
          });
        },
      });
    }
    return window.dangerouslyExportedPrivyConfig;
  }, [metadata?.multiChain?.chainList?.length]);

  return (
    <PrivyProvider
      appId={metadata?.global?.appEnv == "mainnet" ? "clxcopqh4044jjoh4i0y2ux1h" : "clzcbdk9j00234yxzetp2c3en"}
      config={{
        embeddedWallets: {
          createOnLogin: "all-users", // defaults to 'off'
          noPromptOnSignature: false,
        },
        loginMethods: ["email"],
        appearance: {
          showWalletLoginFirst: false,
          accentColor: "#00ce5f",
          theme: "#202020",
          logo: "https://static.edgex.exchange/icons/logo/logo.svg",
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <WagmiProvider config={config} reconnectOnMount={false}>
          {children}
        </WagmiProvider>
      </QueryClientProvider>
    </PrivyProvider>
  );
}

MyPrivyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MyPrivyProvider;
