import Icon from "@/components/Icon";
import React from "react";
import useTranslation from "@/hooks/useTranslation";
import useGlobal from "@/stores/global";
// import useUser from "@/stores/user";
// import DevnetTradingCompetition from "./DevnetTradingCompetition";
// import { useLocation } from "react-router-dom";
// import starkware from "@/icons/starkware.svg";
// import { winENV } from "@/utils/env";

function Footer() {
  const t = useTranslation();
  const { network } = useGlobal();
  // const location = useLocation();
  // const { account: accountList } = useUser();
  // const isTradePage = accountList.length <= 1 && location.pathname.includes("/trade");

  return (
    <div className="module footer w-full h-10 flex items-center justify-between px-4 text-white/50 text-xs">
      <div className="flex items-center">
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="3" y="11.2273" width="1" height="2.33333" rx="0.5" fill="#00CE5F" />
          <rect x="6" y="8.8938" width="1" height="4.66667" rx="0.5" fill="#00CE5F" />
          <rect x="9" y="6.56055" width="1" height="7" rx="0.5" fill="#00CE5F" />
          <rect x="12" y="3.80859" width="1" height="9.75195" rx="0.5" fill="#00CE5F" />
        </svg>
        {network ? t("Stable.connection") : t("menu.disconnect")}
        {/* {isTradePage && <DevnetTradingCompetition />} */}
      </div>
      <div className="flex items-center">
        <span>
          {window?.ENV?.SYSTEM_NAME} © {new Date().getFullYear()}
        </span>
        <a href="https://starkware.co/" target="_blank" className="inline-flex items-center gap-1" rel="noreferrer">
          Powered by StarkWare
          {/* <Icon icon={starkware} className="h-4" /> */}
        </a>
      </div>
    </div>
  );
}

export default Footer;
