import { TYPE_orderType } from "@/constants/trade";
import useTranslations from "@/utils/getTranslations";

export default ({ toast, order, contract }) => {
  const multiLanguage = useTranslations();
  const i = order;
  toast.show({
    // type: order?.status === "CANCELED" ? "error" : "success",
    type: "success",
    title: multiLanguage("orderToastTitle", { status: multiLanguage(order?.status) }),
    txt: multiLanguage("orderToastTxt", {
      size: order?.size,
      contractName: contract.symbol,
      price: [TYPE_orderType.MARKET, TYPE_orderType.STOP_MARKET, TYPE_orderType.TAKE_PROFIT_MARKET].includes(i.type) ? multiLanguage("marketPrice") : order?.price,
      status: multiLanguage(order?.status),
    }),
  });
};
