import { useClickAway } from "ahooks";
import { useRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import ui from "@/constants/ui";

// 顶部链接，支持下拉菜单
function HeaderDropDown({ title, children, className = "", contentClassName = "", titleClassName = "", onClick = () => {}, ...rest }) {
  const refDd = useRef(null);
  useClickAway(() => {
    refDd.current?.parentNode.removeAttribute("open");
  }, refDd);
  return (
    <details
      className={twMerge(
        "header-dropdown dropdown [&>summary>.caret]:open:rotate-180 [&>summary>.caret]:open:fill-current [&>summary]:open:text-primary [&_.caret]:open:fill-primary  [&_.caret]:open:text-primary [&>ul:empty]:open:hidden [&>summary]:open:bg-bg-primary h-full box-border text-base",
        ui?.dropdownClassName,
        className,
      )}
      {...rest}
    >
      <summary
        ref={refDd}
        tabIndex="0"
        role="button"
        key={title}
        onClick={onClick}
        className={twMerge(
          "header-dropdown-title gap-0.5 m-0 btn h-full box-border text-foreground font-medium hover:text-primary hover:bg-bg-primary [&>.caret]:hover:fill-primary rounded-none text-base border-none px-2.5",
          ui?.dropdownTitleClassName,
          titleClassName,
        )}
      >
        {title}
      </summary>
      <ul
        tabIndex="0"
        className={twMerge(
          "header-dropdown-content p-1.5 menu dropdown-content bg-bg-primary z-[10] min-w-full box-border [&>a]:rounded-[3px] [&>a]:px-2 [&>a]:min-h-9 [&>a]:flex [&>a]:items-center [&>a]:gap-2 [&>a]:text-foreground [&>a]:whitespace-nowrap [&>a]:cursor-pointer hover:[&>a]:text-primary hover:[&>a]:fill-primary hover:[&>a]:bg-[#272A29] rounded-b-[6px] text-base [&>a+a]:mt-0.5",
          ui?.dropdownContentClassName,
          contentClassName,
        )}
      >
        {children}
      </ul>
    </details>
  );
}

HeaderDropDown.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  onClick: PropTypes.func,
};


export default HeaderDropDown;
