/**
 * a checkbox to hide the next time
 */
import useSettings from "@/modules/trade/stores/settings";
import getTranslations from "@/utils/getTranslations";
import PropTypes from "prop-types";

const HideNextTime = ({ storeKey }) => {
  const { xStore, setSettings } = useSettings();
  const t = getTranslations();
  return (
    <>
      {/* <span className="h-0 w-full block border-b border-solid border-b-[--highlight] mt-4"></span> */}
      <label className="mt-8 flex items-center gap-2 cursor-pointer">
        <input
          type="checkbox"
          className="checkbox checkbox-primary checkbox-xs cb-hide-next-time"
          checked={!xStore?.[storeKey]}
          onChange={(e) => {
            setSettings((settings) => {
              settings.xStore[storeKey] = !e.target.checked;
            });
          }}
        ></input>
        <span
          dangerouslySetInnerHTML={{
            __html: t("hideNextTime", {
              settings: ` <span class='cursor-pointer text-primary' onclick='arguments[0].stopPropagation();arguments[0].preventDefault();window.dispatchEvent(new CustomEvent("open-settings-sheet", { detail: {} })); ' > ${t(
                "menu.setting",
              )} </span> `,
            }),
          }}
        ></span>
      </label>
    </>
  );
};

HideNextTime.propTypes = {
  storeKey: PropTypes.string,
  labelText: PropTypes.string,
};

export default HideNextTime;
