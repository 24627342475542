import { CustomEvents } from "@/constants/customEvents";
import { disclaimerAndSignStepEnum } from "@/constants/wallets";
import useGlobal from "@/stores/global";
import useUser from "@/stores/user";
import { captureException } from "@sentry/react";
import { watchAccount } from "@wagmi/core";
import { useEventListener } from "ahooks";
import { useEffect } from "react";

const ReconnectWalletOnSwitchAccount = () => {
  const { resetUser, setUser, currentActiveAccount } = useUser();
  const { setGlobal } = useGlobal();

  useEffect(() => {
    const unwatch = watchAccount(window?.dangerouslyExportedConfig, {
      onChange(account, prevAccount) {
        // console.log("account onChange", account, prevAccount);
        if (account?.address != prevAccount?.address && prevAccount?.address && account?.address) {
          // console.log("account reconnect", account, prevAccount);
          window.dispatchEvent(new CustomEvent(CustomEvents["account-change"], { detail: { address: account?.address, wallet: account?.connector?.id } }));
        }
      },
    });
    return () => {
      unwatch?.();
    };
  }, []);

  useEventListener(
    CustomEvents["account-change"],
    (e) => {
      try {
        resetUser();

        setUser({
          currentActiveAccount: {
            ...currentActiveAccount,
            ethAddress: e.detail?.address,
            wallet: e.detail?.wallet,
          },
        });
        setGlobal({ disclaimerAndSignVisible: true, disclaimerAndSignStep: disclaimerAndSignStepEnum.unknown });
      } catch (error) {
        captureException(error);
        // eslint-disable-next-line no-console
        console.error("reconnect error", error);
      }
    },
    { target: window },
  );

  return <></>;
};

export default ReconnectWalletOnSwitchAccount;
