import { fullChains } from "@/constants/wallets";
import useUser from "@/stores/user";
import { getBalance, multicall } from "@wagmi/core";
import { useMemo } from "react";
import { createClient, erc20Abi, getAddress, http } from "viem";
import { createConfig } from "wagmi";
import useActiveChains from "./useActiveChains";

const genConfig = (chains = []) => {
  if (!chains.length) return;
  const config = createConfig({
    chains: chains,
    client({ chain }) {
      return createClient({
        chain,
        transport: http(window?.ENV?.[`CHAIN_RPC_URL_${chain.id}`] || undefined),
      });
    },
  });

  return config;
};

const useMulticallBalance = (selectChain) => {
  const { currentActiveChain, chains } = useActiveChains();
  const chainId = +selectChain || +currentActiveChain?.chainId;
  // const rpcUrl = (selectChain ? chains?.find(i=>+i.chainId === selectChain) : currentActiveChain)?.rpcUrl
  const contractAddress = (selectChain ? chains?.find((i) => +i.chainId === +selectChain) : currentActiveChain)?.contractAddress;
  const currentActiveChainTokens = (selectChain ? chains?.find((i) => +i.chainId === +selectChain) : currentActiveChain)?.tokenList
    ?.map((i, index) => {
      return {
        ...i,
        isDefaultToken: index === 0 ? true : false,
      };
    })
    ?.filter((t) => !t.pullOff);

  const { currentActiveAccount } = useUser();

  const validErcTokens = useMemo(() => currentActiveChainTokens?.filter((i) => i?.tokenAddress), [currentActiveChainTokens]);

  const genMulticallBalanceConfig = () => {
    return validErcTokens.map((i) => {
      return {
        address: i.tokenAddress,
        abi: erc20Abi,
        functionName: "balanceOf",
        args: [currentActiveAccount?.ethAddress],
      };
    });
  };

  const genMulticallAllowanceConfig = () => {
    return validErcTokens.map((i) => {
      return {
        address: i.tokenAddress,
        abi: erc20Abi,
        functionName: "allowance",
        args: [currentActiveAccount?.ethAddress, contractAddress],
      };
    });
  };

  const multicallRun = async () => {
    if (!currentActiveAccount?.ethAddress) {
      // throw new Error("Invalid Account Address");
      return {};
    }

    const balanceConfig = genMulticallBalanceConfig();
    const allowanceConfig = genMulticallAllowanceConfig();

    const balanceConfigLength = balanceConfig?.length;

    const calls = [...balanceConfig, ...allowanceConfig];

    const config = genConfig(fullChains.filter((i) => +i.id === +chainId));

    const result = await Promise.allSettled([
      getBalance(config, {
        address: currentActiveAccount?.ethAddress,
        chainId,
      }),
      multicall(config, {
        contracts: calls,
        chainId,
      }),
    ]);

    const baseTokenBalance = result?.[0]?.value;
    const ercTokenBalance = result?.[1]?.value;

    const balanceList = ercTokenBalance?.slice(0, balanceConfigLength)?.map((i, index) => {
      const decimal = validErcTokens?.find((j) => j?.tokenAddress?.toLowerCase() === balanceConfig?.[index]?.address?.toLowerCase())?.decimals;
      return {
        address: balanceConfig?.[index]?.address,
        value: i?.result,
        formatted: (BigInt(i?.result || 0) / BigInt(10) ** BigInt(decimal)).toString(),
        decimals: +decimal,
      };
    });
    const allowanceList = ercTokenBalance?.slice(balanceConfigLength)?.map((i, index) => {
      const decimal = validErcTokens?.find((j) => j?.tokenAddress?.toLowerCase() === balanceConfig?.[index]?.address?.toLowerCase())?.decimals;
      return {
        address: allowanceConfig?.[index]?.address,
        value: i?.result,
        formatted: (BigInt(i?.result || 0) / BigInt(10) ** BigInt(decimal)).toString(),
        decimals: +decimal,
      };
    });

    const combinedData = balanceList?.reduce((prev, next) => {
      const allowance = allowanceList?.find((i) => i?.address === next?.address);
      return {
        ...prev,
        [getAddress(next?.address)]: {
          balance: next,
          allowance,
        },
      };
    }, {});

    return {
      balanceList,
      allowanceList,
      baseTokenBalance,
      combinedData,
    };

    // todo 聚合地址输出
  };

  return { multicallRun, validErcTokens };
};

export default useMulticallBalance;
