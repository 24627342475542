// Inspired by react-hot-toast library
import * as React from "react"
import success from "@/icons/success.svg";
import warning from "@/icons/warning.svg";
import error from "@/icons/error.svg";
import Icon from "@/components/Icon";
import { cn } from "@/utils/cn";
import ui from "@/constants/ui";
import uiConfig from "@/constants/ui";
import { useInterval } from "ahooks";

const TOAST_LIMIT = uiConfig?.toastLimit || 1
const TOAST_REMOVE_DELAY = uiConfig?.toastRemoveDelay || 200

const actionTypes = {
  ADD_TOAST: "ADD_TOAST",
  UPDATE_TOAST: "UPDATE_TOAST",
  DISMISS_TOAST: "DISMISS_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST"
}

let count = 0

function genId() {
  count = (count + 1) % Number.MAX_SAFE_INTEGER
  return count.toString();
}

const toastTimeouts = new Map()

const addToRemoveQueue = (toastId) => {
  if (toastTimeouts.has(toastId)) {
    return
  }
  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId)
    dispatch({
      type: "REMOVE_TOAST",
      toastId: toastId,
    })
  }, TOAST_REMOVE_DELAY)

  toastTimeouts.set(toastId, timeout)
}
let memoryState = { toasts: [] }

export const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TOAST":
      action.toast.time = new Date().getTime();
      return {
        ...state,
        toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT),
      };

    case "UPDATE_TOAST":
      return {
        ...state,
        toasts: state.toasts.map((t) =>
          t.id === action.toast.id ? { ...t, ...action.toast } : t),
      };

    case "DISMISS_TOAST": {
      const { toastId } = action

      // ! Side effects ! - This could be extracted into a dismissToast() action,
      // but I'll keep it here for simplicity
      if (toastId) {
        addToRemoveQueue(toastId)
      } else {
        state.toasts.forEach((toast) => {
          addToRemoveQueue(toast.id)
        })
      }

      return {
        ...state,
        toasts: state.toasts.map((t) =>
          t.id === toastId || toastId === undefined
            ? {
                ...t,
                open: false,
              }
            : t),
      };
    }
    case "REMOVE_TOAST":
      if (action.toastId === undefined) {
        return {
          ...state,
          toasts: [],
        }
      }
      return {
        ...state,
        toasts: state.toasts.filter((t) => t.id !== action.toastId),
      };
  }
}

const listeners = []



function dispatch(action) {
  memoryState = reducer(memoryState, action)
  listeners.forEach((listener) => {
    listener(memoryState)
  })
}

function toast({
  ...props
}) {
  const id = genId()

  const update = (props) =>
    dispatch({
      type: "UPDATE_TOAST",
      toast: { ...props, id },
    })
  const dismiss = () => dispatch({ type: "DISMISS_TOAST", toastId: id })

  dispatch({
    type: "ADD_TOAST",
    toast: {
      duration:5000,
      ...props,
      id,
      open: true,
      onOpenChange: (open) => {
        if (!open) dismiss()
      }
    },
  })

  return {
    id: id,
    dismiss,
    update,
  }
}

// 兼容原系统的调用 Toast.show({type:"info/success/error",title:"标题",txt:"提示内容"})
// Toast.show("轻提示",{type:"success/error/warning"}) ,默认 success
toast.show = (props, opts = {}) =>
  toast(
    typeof props == "string"
      ? {
          variant: "simple",
          description: (
            <>
              <Icon
                icon={{ success, warning, error }[opts?.type || "success"]}
                className={"w-4 " + { success: "text-success", warning: "text-warning", error: "text-error" }[opts?.type || "success"]}
              />{" "}
              {props}{" "}
            </>
          ),
          descriptionClassName: "inline-flex gap-2 ",
        }
      : {
          ...props,
          title: (
            <>
              {["success", "info", "error"].includes(props?.type) && (
                <Icon
                  icon={{ success, info: warning, error }[props?.type || "success"]}
                  className={cn("w-4 " + { success: "text-success", info: "text-warning", error: "text-error" }[props?.type || "success"], ui?.toastTitleIconClassName)}
                />
              )}
              {props?.title}
            </>
          ),
          description: props?.txt || props?.description,
          descriptionClassName:["success", "info", "error"].includes(props?.type)?"pl-6":"",
          type:"foreground"
        },
  );

function useToast() {
  const [state, setState] = React.useState(memoryState)


  useInterval(()=>{
    state.toasts.forEach((toast) => {
      if (new Date().getTime() - toast.time > toast.duration) {
        addToRemoveQueue(toast.id)
      }
    })
  },state.toasts.length>0?1000:undefined);

  React.useEffect(() => {
    listeners.push(setState)
    return () => {
      const index = listeners.indexOf(setState)
      if (index > -1) {
        listeners.splice(index, 1)
      }
    };
  }, [state])

  return {
    ...state,
    toast,
    dismiss: (toastId) => dispatch({ type: "DISMISS_TOAST", toastId }),
  };
}

export { useToast, toast }
