import ui from "@/constants/ui";
import { deepCopy } from "@/utils/funcs";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const viewHeaderFields = ui?.viewHeaderFields || [];

const initValues = {
  items: [
    { id: "indexPrice", checked: true },
    { id: "oraclePrice", checked: true },
    { id: "dailyChange", checked: true },
    { id: "dailyHigh", checked: true },
    { id: "dailyLow", checked: true },
    { id: "dailyTurnover", checked: true },
    { id: "dailyVolume", checked: true },
  ].sort((a, b) => viewHeaderFields.indexOf(a.id) - viewHeaderFields.indexOf(b.id)),
  quickTrading: true, // 下单浮窗
  showPositionLine: true, // 持仓线
  showOrderLine: true, // 活动委托线
  showConditionalLine: true, // 条件委托线
  showTpOSlLine: true, // 显示止盈止损
  // 布局相关
  layout: [], // grid layout item
  rowHeight: 44, // grid layout row height

  chartActiveTab: "kline", // ["kline", "depth", "tabFunding", "details", "riskRate"]
  historyActiveTab: "orderBook", // ["orderBook", "recentTrades"]
  positionActiveTab: "positions", // ["positions", "closePnL", "active", "conditional", "filled", "tradeHistory"]
  showAllPositions: {}, // [positionTab]: true/false

  activeOrderBookType: "all", // ['all', 'buy', 'sell']
  deepValue: "0.5", // ['0.5', '1', '2', '5', '10']
  showItemLen: 9, // orderbook item length
  amountType: "size", // ['size','total']

  theme: "normal", // normal/sl-reverse

  activePnlTab: "closePnL", // closePnL openPnL
  activeCurTab: "active", // active conditional

  mode: "open-close", // open-close long-short

  txSignature: false, // sign before create order
  uplPriceType: "lastPrice",

  xStore: {
    "order.confirm": true, // 下单确认
    "kline.createTpOSl": true,
    "kline.closePosition": true,
    "kline.moveTpOSl": true,
    "kline.moveActive": true,
    "kline.moveConditional": true,
    "kline.cancelOrder": true,
  }, // 替换原 xStore，自由读写 key/value
};

const useSettings = create(
  persist(
    immer((set) => ({
      ...deepCopy(initValues),
      setSettings: set,
      resetSettings: () => set(() => deepCopy(initValues)),
    })),
    {
      name: "trade.settings",
      storage: createJSONStorage(() => localStorage),
      version: 3,
      migrate: (persistedState, version) => {
        // 1->2 修改了 items 的排序
        if (version === 0) {
          persistedState.items = persistedState.items.sort((a, b) => viewHeaderFields.indexOf(a.id) - viewHeaderFields.indexOf(b.id));
        }
        // 2->3 修改了 uplPriceType
        persistedState.uplPriceType = "lastPrice";
        return persistedState;
      },
    },
  ),
);

export default useSettings;
