// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/**
 * 全局初始化样式
 */


html,
body,
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: "IBMPlexSans", -apple-system, "system-ui", Roboto, Arial, sans-serif;
}


html::-webkit-scrollbar,
body::-webkit-scrollbar,
#root::-webkit-scrollbar {
  display: none;
}


html,
body,
#root {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

#root {
  font-size: 14px; /* default font size */
}


/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* hide input[type=number] spin button */
/* Chrome, Safari, Edge, Opera */
input[type="number"].hide-button::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"].hide-button {
  -moz-appearance: textfield;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;;EAEE;;;AAGF;;;EAGE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,iFAAiF;AAEnF;;;AADE;;;EAAA,aAAmB;AAAA;;;AAAnB;;;EAAA,wBAAmB;EAAnB,gBAAmB;EAAnB,qBAAmB;EAAnB,YAAmB;AAAA;;AAGrB;EACE,eAAe,EAAE,sBAAsB;AACzC;;;AAGA,gDAAgD;AAChD;EACE,aAAa;AACf;;AAEA,4CAA4C;AAC5C;EACE,wBAAwB;EACxB,gBAAgB;EAChB,qBAAqB;EACrB,YAAY;AACd;;AAEA,wCAAwC;AACxC,gCAAgC;AAChC;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B","sourcesContent":["\n/**\n * 全局初始化样式\n */\n\n\nhtml,\nbody,\n#root {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  font-family: \"IBMPlexSans\", -apple-system, \"system-ui\", Roboto, Arial, sans-serif;\n  @apply no-scrollbar;\n}\n\n#root {\n  font-size: 14px; /* default font size */\n}\n\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.no-scrollbar::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.no-scrollbar {\n  -ms-overflow-style: none;\n  /* IE and Edge */\n  scrollbar-width: none;\n  /* Firefox */\n}\n\n/* hide input[type=number] spin button */\n/* Chrome, Safari, Edge, Opera */\ninput[type=\"number\"].hide-button::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type=\"number\"].hide-button {\n  -moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
