/**
 * Dialog.confirm() 平替之前 Toast.showConfirmDialog
 * Dialog.confirm({ title, description})
 * Dialog.confirmWithXStore({ title, description, storeKey })
 *
 * TODO: 全屏元素
 */

import { createPortal } from "react-dom";
import { createRoot } from "react-dom/client";
import { X } from "lucide-react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import useSettings from "@/modules/trade/stores/settings";
import HideNextTime from "@/modules/trade/components/HideNextTime";
import useTranslation from "@/utils/getTranslations";
import ui from "@/constants/ui";
import { twMerge } from "tailwind-merge";
const dialog = {
  confirm: ({ title, description, contentClassName, confirmText, cancel, buttonClassName, confirmClassName, onClose }) =>
    new Promise((resolve) => {
      const t = useTranslation();
      const $container = document.createElement("div");
      $container.className = "x-alert-dialog-container";
      document.body.appendChild($container);
      const root = createRoot($container);
      const close = (result) => {
        onClose?.(result);
        root?.unmount();
        document.body.removeChild($container);
        $container?.parentNode?.removeChild($container);
      };
      root.render(
        createPortal(
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <button className="hidden x-alert-dialog-trigger"></button>
            </AlertDialogTrigger>
            <AlertDialogContent
              className={contentClassName}
              onOpenAutoFocus={(e) => {
                e.preventDefault();
              }}
            >
              <AlertDialogHeader>
                <AlertDialogTitle>{title}</AlertDialogTitle>
                <AlertDialogDescription>
                  <div className={ui?.alertDialogDescriptionClassName}>{description}</div>
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                {cancel !== false && (
                  <AlertDialogCancel
                    className={twMerge(ui?.dialogFooterButtonClassName, buttonClassName)}
                    onClick={() => {
                      close(false);
                      resolve(false);
                    }}
                  >
                    {t("btnCancel")}
                  </AlertDialogCancel>
                )}
                <AlertDialogAction
                  className={twMerge(ui?.dialogFooterButtonClassName, buttonClassName, confirmClassName)}
                  onClick={() => {
                    close(true);
                    resolve(true);
                  }}
                >
                  {confirmText || t("btnConfirm")}
                </AlertDialogAction>
              </AlertDialogFooter>
              <button
                className={twMerge("", ui?.dialogCloseClassName)}
                onClick={() => {
                  close(false);
                  resolve(false);
                }}
              >
                <X className="h-4 w-4" />
              </button>
            </AlertDialogContent>
          </AlertDialog>,
          $container,
        ),
      );
      setTimeout(() => {
        document.querySelector(".x-alert-dialog-trigger")?.click();
      }, 50);
    }),
  confirmWithXStore: ({ title, description, cancelText, okText, storeKey, ...rest }) =>
    new Promise((resolve) => {
      if (!useSettings.getState()?.xStore?.[storeKey]) {
        return resolve(true);
      }
      return dialog
        .confirm({
          title,
          description: (
            <>
              {description}
              <HideNextTime storeKey={storeKey} />
            </>
          ),
          cancelText,
          okText,
          onClose: (res) => {
            if (res && document.querySelector(".cb-hide-next-time").checked) {
              useSettings.getState()?.setSettings((state) => {
                state.xStore[storeKey] = false;
              });
            }
          },
          ...rest,
        })
        .then(resolve);
    }),
};

export default dialog;
