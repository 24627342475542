/**
 * deploy.envs 环境变量（本地备份)
 * @param
 */
import { currentENV } from "./env";

const mainnet = {
  EXCHANGEPRO_FLAG_ENV: "mainnet",
  EXCHANGEPRO_FLAG_WS_HOST: "wss://quote.edgex.exchange",
  EXCHANGEPRO_FLAG_OPT_API_BASEURL: "https://be-portal.edgex.exchange",
  EXCHANGEPRO_FLAG_EVENT_API_BASEURL: "https://award.edgex.exchange/api",
  EXCHANGEPRO_FLAG_CHAIN_RPC_URL_1: "https://rpc.edgex.exchange/RMZZpeTnB6hjfcm8xNNyo6cKa9Zn4qgB/ethereum",
  EXCHANGEPRO_FLAG_CHAIN_RPC_URL_56: "https://rpc.edgex.exchange/RMZZpeTnB6hjfcm8xNNyo6cKa9Zn4qgB/bsc",
  EXCHANGEPRO_FLAG_CHAIN_RPC_URL_42161: "https://rpc.edgex.exchange/RMZZpeTnB6hjfcm8xNNyo6cKa9Zn4qgB/arbitrum",
  EXCHANGEPRO_FLAG_TESTNET_LINK: "https://testnet.edgex.exchange/trade/BTCUSDT",
  EXCHANGEPRO_FLAG_MAINNET_LINK: "https://pro.edgex.exchange/trade/BTCUSDT",
  EXCHANGEPRO_FLAG_S3_URL: "https://s3.edgex.exchange/",
};

const testnet = {
  EXCHANGEPRO_FLAG_ENV: "testnet",
  EXCHANGEPRO_FLAG_WS_HOST: "wss://quote-testnet.edgex.codekit.work",
  EXCHANGEPRO_FLAG_OPT_API_BASEURL: "https://cloudgw-testnet.edgex.exchange",
  EXCHANGEPRO_FLAG_EVENT_API_BASEURL: "https://testapi.pompom.social/api",
  EXCHANGEPRO_FLAG_CHAIN_RPC_URL_11155111: "https://rpc.edgex.exchange/RMZZpeTnB6hjfcm8xNNyo6cKa9Zn4qgB/eth-sepolia",
  EXCHANGEPRO_FLAG_CHAIN_RPC_URL_97: "https://rpc.edgex.exchange/RMZZpeTnB6hjfcm8xNNyo6cKa9Zn4qgB/bsc-testnet",
  EXCHANGEPRO_FLAG_CHAIN_RPC_URL_421614: "https://rpc.edgex.exchange/RMZZpeTnB6hjfcm8xNNyo6cKa9Zn4qgB/arbitrum-sepolia",
  EXCHANGEPRO_FLAG_TESTNET_LINK: "https://testnet.edgex.exchange/trade/BTCUSDT",
  EXCHANGEPRO_FLAG_MAINNET_LINK: "https://pro.edgex.exchange/trade/BTCUSDT",
  EXCHANGEPRO_FLAG_S3_URL: "https://s3.edgex.exchange/",
};

const dev = {
  EXCHANGEPRO_FLAG_ENV: "dev",
  EXCHANGEPRO_FLAG_WS_HOST: "wss://quote-dev.edgex.exchange",
  EXCHANGEPRO_FLAG_OPT_API_BASEURL: "https://cloudgw-dev.edgex.exchange",
  EXCHANGEPRO_FLAG_EVENT_API_BASEURL: "https://testapi.pompom.social/api",
  EXCHANGEPRO_FLAG_CHAIN_RPC_URL_11155111: "https://rpc.edgex.exchange/RMZZpeTnB6hjfcm8xNNyo6cKa9Zn4qgB/eth-sepolia",
  EXCHANGEPRO_FLAG_CHAIN_RPC_URL_97: "https://rpc.edgex.exchange/RMZZpeTnB6hjfcm8xNNyo6cKa9Zn4qgB/bsc-testnet",
  EXCHANGEPRO_FLAG_CHAIN_RPC_URL_421614: "https://rpc.edgex.exchange/RMZZpeTnB6hjfcm8xNNyo6cKa9Zn4qgB/arbitrum-sepolia",
  EXCHANGEPRO_FLAG_TESTNET_LINK: "https://testnet.edgex.exchange/trade/BTCUSDT",
  EXCHANGEPRO_FLAG_MAINNET_LINK: "https://pro.edgex.exchange/trade/BTCUSDT",
  EXCHANGEPRO_FLAG_S3_URL: "https://s3.edgex.exchange/",
};

/**
 * 环境变量汇总
 * @param
 */
export const allEnvs = {
  mainnet,
  testnet,
  dev,
};

/**
 * 当前环境的环境变量
 * @param
 */
export const getENVItem = (env = currentENV) => {
  return allEnvs[env];
};
