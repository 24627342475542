import { useRequest } from "ahooks";
import React from "react";
import request from "axios";
import useInterface from "@/stores/interface";
import Clock from "@/components/trade/lib/clock";
import PropTypes from "prop-types";
import Spinner from "@/components/Spinner";
/**
 * 请求 metadata
 */
function Bootstrap({ children }) {
  const { setInterface } = useInterface();

  const { loading: loadingMeta, error: metaError } = useRequest(() =>
    request("/v1/public/meta/getMetaData").then((res) => {
      const timeRes = parseInt(res.data.responseTime, 10);
      const reqTimestamp = Date.now();
      const serverTimeGap = timeRes - reqTimestamp;
      const serverClock = new Clock(serverTimeGap, reqTimestamp, timeRes);
      setInterface({ metadata: res.data.data, serverClock });
    }),
  );
  return loadingMeta ? <Spinner className="flex-1" /> : metaError ? <div className="flex-1 flex items-center justify-center text-lg">:(</div> : children;
}

Bootstrap.propTypes = {
  children: PropTypes.any,
};

export default Bootstrap;
