import { Suspense, lazy } from "react";
import useInterface from "@/stores/interface";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { CustomEvents } from "@/constants/customEvents";
// import useTranslation from "@/hooks/useTranslation";
// import AppDownload from "@/modules/layout/components/AppDownload";
import Icon from "@/components/Icon";
import ui from "@/constants/ui";
import useTranslation from "@/hooks/useTranslation";
import { GetHeadConfig } from "@/constants/header.setting";
import HeaderDropDown from "@/modules/layout/components/HeaderDropDown";
import HeaderNavLink from "@/modules/layout/components/HeaderNavLink";

import LangSwitch from "@/modules/layout/components/LangSwitch";
import NotificationCenter from "@/modules/layout/components/NotificationCenter";
import AppQrCode from "@/modules/layout/components/AppQrCode";
import ChainDropDown from "./ChainDropDown";
import MyPrivyProvider from "@/modules/layout/components/MyPrivyProvider";
import WalletConnect from "@/modules/layout/components/WalletConnect";
import MpcHandler from "@/modules/layout/components/MpcHandler";
import MpcConnectUpdater from "@/modules/layout/components/MpcConnectUpdater";
import { hoemRoutes } from "@/modules/home/index";
import logo from "@/icons/logo.svg";
import caret from "@/icons/caret.svg";
import DialogDeposit from "@/modules/trade/components/deposit";
import DialogWithdraw from "@/modules/trade/components/withdraw";
const Settings = lazy(() => import("@/modules/trade/components/Settings"));
import { logEvent } from "@/utils/analytics";
import useUser from "@/stores/user";
import { usePrivy } from "@privy-io/react-auth";

function Header() {
  const { currentActiveAccount } = useUser();
  const { user } = usePrivy();
  const { metadata } = useInterface();
  const t = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { APP, appMenu, userConfig, homePageMenu } = GetHeadConfig(metadata);
  const address = currentActiveAccount?.ethAddress || user?.wallet?.address;
  const isHome =
    location.pathname === "/" ||
    hoemRoutes.find((v) => {
      return location.pathname.includes(v.path);
    });

  const isTrade = ["trade", "dashboard"].find((v) => location.pathname.includes(v));
  const menu = isHome ? homePageMenu : appMenu.sort((a, b) => b.weight - a.weight).filter((v) => v.auth);
  const { switchLang, notification, appQrCode, settings, chains, account } = userConfig;

  const linkTrade = () => {
    navigate("/trade");
    logEvent("link", "click", "Web_Click_Home_Navigation_Trade");
  };

  const logTrackerEvent = (event, i) => {
    event.stopPropagation();
    event.preventDefault();
    if (i.link) i.target ? window.open(i.link, "_blank") : i.link.trim() === "/" ? navigate("/") : navigate(i.link);
    if (i.logEvent) logEvent("link", "click", i.logEvent);
  };

  return (
    <div className="h-14 flex px-4 items-center text-sm border-b-[1px] border-bg-primary bg-background sticky top-[0] z-10 drop-shadow-md">
      <div className="flex items-center h-full">
        <>
          {APP.link ? (
            <a href={APP.link} key={APP.key + APP.link} className="inline-flex items-center h-full mr-2">
              {APP.icon}
            </a>
          ) : (
            <div key={APP.key} className="inline-flex items-center h-full mr-2 ">
              {APP.icon}
            </div>
          )}
        </>

        {menu?.map((item, index) =>
          item.children.length ? (
            <div key={item.title + index}>
              <HeaderDropDown
                titleClassName={twMerge(
                  "px-3 text-sm",
                  item.link &&
                    item.children.find((v) => {
                      return v.link.includes(location.pathname);
                    }) &&
                    "text-primary fill-primary active-nav-link bg-[linear-gradient(to_bottom,transparent_0px,transparent_96%,oklch(var(--p))_96%,oklch(var(--p)))]",
                )}
                contentClassName="[&>a]:px-1.5"
                title={
                  <div key={item.title} className="flex items-center gap-0.5" onClick={() => item.logEvent && logEvent("button", "click", item.logEvent)}>
                    <span className="whitespace-nowrap text-sm capitalize font-normal">{t(item.title)}</span>
                    <Icon icon={caret} className="caret h-2.5 fill-foreground transition-all origin-center" />
                  </div>
                }
              >
                {item.children.length &&
                  item.children.map((i) => {
                    return (
                      i.auth &&
                      (i.target ? (
                        <a
                          key={i.label}
                          href={i.link}
                          onClick={(e) => {
                            logTrackerEvent(e, i);
                          }}
                          className="inline-flex items-center h-full mr-2 whitespace-nowrap text-sm text-left w-full"
                        >
                          {t(i.label)}
                        </a>
                      ) : (
                        <HeaderNavLink
                          key={i.label}
                          to={i.link}
                          onClick={(e) => {
                            logTrackerEvent(e, i);
                          }}
                        >
                          <span className="whitespace-nowrap text-sm text-left w-full">{t(i.label)}</span>
                        </HeaderNavLink>
                      ))
                    );
                  })}
              </HeaderDropDown>
            </div>
          ) : !item.target ? (
            <HeaderNavLink
              key={item.title}
              onClick={(e) => {
                logTrackerEvent(e, item);
              }}
              to={item.link}
            >
              <span className="whitespace-nowrap text-sm">{t(item.title)}</span>
            </HeaderNavLink>
          ) : item.link ? (
            <div
              className={({ isActive, isPending }) =>
                twMerge(
                  "w-auto h-full flex items-center justify-center px-3 nav-link text-sm",
                  isPending
                    ? "loading loading-xs inline-flex "
                    : isActive
                    ? "text-primary fill-primary active-nav-link bg-[linear-gradient(to_bottom,transparent_0px,transparent_96%,oklch(var(--p))_96%,oklch(var(--p)))]"
                    : "",
                )
              }
              onClick={(e) => {
                logTrackerEvent(e, item);
              }}
            >
              <a key={item.title} href={item.link} className="whitespace-nowrap text-sm">
                {t(item.title)}
              </a>
            </div>
          ) : (
            <div
              className={({ isActive, isPending }) =>
                twMerge(
                  "w-auto h-full flex items-center justify-center px-3 nav-link text-sm",
                  isPending
                    ? "loading loading-xs inline-flex "
                    : isActive
                    ? "text-primary fill-primary active-nav-link bg-[linear-gradient(to_bottom,transparent_0px,transparent_96%,oklch(var(--p))_96%,oklch(var(--p)))]"
                    : "",
                )
              }
              onClick={(e) => {
                logTrackerEvent(e, item);
              }}
            >
              <span key={item.title} className="px-2 py-[2px] whitespace-nowrap text-sm rounded-[3px] bg-[#00873e4d]" rel="noreferrer">
                {t(item.title)}
              </span>
            </div>
          ),
        )}
      </div>

      {/* 充值 */}
      <div className="ml-auto h-full flex items-center">
        {!isHome && address && (
          <div
            onClick={() => {
              window.dispatchEvent(new CustomEvent(CustomEvents["open-deposit-dialog"], { detail: {} }));
              logEvent("link", "click", "Web_Click_Deposit_btn");
            }}
            className="linear-gradient-button py-[2px] px-3 mx-[10px] text-sm font-medium cursor-pointer whitespace-nowrap "
          >
            {t("Deposit")}
          </div>
        )}
        {/* 语种切换 */}
        {switchLang && <LangSwitch />}
        {/* 站内信 */}
        {notification && !isHome && <NotificationCenter />}
        {/* 二维码登陆 */}
        {appQrCode && !isHome && <AppQrCode />}
        {/* 用户设置 */}
        {settings && isTrade && (
          <Suspense>
            <Settings className="size-8 mr-4" />
          </Suspense>
        )}
        {/* 切换链 */}
        {chains && !isHome && <ChainDropDown className="dropdown-end" iconClassName={"size-5"} titleClassName="[&>span]:hidden" />}
        {account && !isHome && (
          <MyPrivyProvider>
            <WalletConnect />
            <MpcHandler />
            <MpcConnectUpdater />
          </MyPrivyProvider>
        )}
        {isHome && (
          <span onClick={linkTrade} className="whitespace-nowrap bg-border-strong rounded-[3px] px-8 py-[5px] mx-[10px] text-sm capitalize font-normal cursor-pointer">
            {t("menu.trade")}
          </span>
        )}

        {/* {download && <AppDownload />} */}
      </div>
      <DialogDeposit />
      <DialogWithdraw />
    </div>
  );
}

export default Header;
