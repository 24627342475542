import { captureException } from "@sentry/react";
import { useState } from "react";
import { usePublicClient, useWriteContract } from "wagmi";

const useSubmitTx = () => {
  const { writeContractAsync } = useWriteContract();
  const [loading, setLoading] = useState(false);
  const publicClient = usePublicClient();

  // {
  //     abi: erc20Abi,
  //     address: tokenInfo.tokenAddress,
  //     functionName: 'approve',
  //     args: [
  //         currentActiveChain?.contractAddress,
  //         maxUint256
  //     ],
  // }
  const writeContract = async (config, waitForTransactionReceipt = true) => {
    try {
      setLoading(true);
      const res = await writeContractAsync(config);
      if (waitForTransactionReceipt) {
        const transaction = await publicClient.waitForTransactionReceipt({ hash: res });
        setLoading(false);
        if (transaction?.status === "success") {
          // transaction?.transactionHash
          return transaction;
        } else {
          throw new Error({ details: "Transaction Failed" });
        }
      } else {
        setLoading(false);
        return;
      }
    } catch (e) {
      setLoading(false);
      captureException(e);
      throw e;
    }
  };

  return { writeContract, loading };
};

export default useSubmitTx;
