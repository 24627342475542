import BigNumber from "bignumber.js";
import { toPrecisionStringWithType } from "@/utils/funcs";

/*
{
  "userId": "462363730915623169",
  "accountId": "462363730995315299",
  "coinId": "1000",
  "contractId": "10000001",
  "termCount": 1,
  "cumOpenSize": "-0.0010",
  "cumOpenValue": "-72.008800",
  "cumOpenFee": "-72.008800",
  "cumCloseSize": "0.0010",
  "cumCloseValue": "72.008000",
  "cumCloseFee": "-0.014401",
  "cumFundingFee": "0",
  "cumLiquidateFee": "0",
  "createdTime": "1710236201617",
  "updatedTime": "1710237477209"
}

*/
/**
 * position term item 计算
 * @param {object} item positionTerm item
 * @param {Array<Object>} symbolsList like [{symbol:"BTCUSDT"}]
 * @returns {object} decorated item
 */

export const calculatePositionTerm = (item, symbolsList) => {
  const symbolInfo = symbolsList.find((s) => s.contractId == item.contractId);
  // 入场价格：beforeOpenValue / cumOpenSize , 按照 contract.tickSize 取整。注意取整规则，卖出平多：向上取整；买入平空：向下取整
  const entryPrice = toPrecisionStringWithType(BigNumber(item.cumOpenValue).dividedBy(item.cumOpenSize).abs(), symbolInfo.pricePrecision, "floor");
  // 出场价格：(PositionTerm.cumCloseValue + PositionTerm.cumLiquidateFee) / PositionTerm.cumCloseSize, 按照 contract.tickSize 取整。注意取整规则，卖出平多：向下取整；买入平空：向上取整
  const exitPrice = toPrecisionStringWithType(BigNumber(item.cumCloseValue).plus(item.cumLiquidateFee).dividedBy(item.cumCloseSize).abs(), symbolInfo.pricePrecision, "floor");

  // 平仓价值：abs(fillCloseValue)
  const orderCloseValue = BigNumber(Math.abs(item.cumCloseValue) || 0);
  // 开仓价值：beforeOpenValue x abs(cumCloseSize) / cumOpenSize
  const orderOpenValue = BigNumber(item.cumOpenValue).multipliedBy(item.cumCloseSize).abs().dividedBy(item.cumOpenSize).abs();
  // 手续费：cumOpenFee x abs(cumCloseSize) / abs(cumOpenSize) + cumCloseFee
  const orderTradingFee = BigNumber(item.cumOpenFee).multipliedBy(item.cumCloseSize).abs().dividedBy(item.cumOpenSize).plus(item.cumCloseFee);
  // 资金费：cumFundingFee
  const orderFundingFee = BigNumber(item.cumFundingFee);

  /*
        Long Position
        Realized P&L = Close Value - Open Value - Trading Fees + Funding Fees

        Short Position
        Realized P&L = Open Value - Close Value - Trading Fees + Funding Fees
         */
  const totalPnl =
    Number(item.cumCloseSize) <= 0
      ? orderCloseValue.minus(orderOpenValue).minus(orderTradingFee).plus(orderFundingFee)
      : orderOpenValue.minus(orderCloseValue).minus(orderTradingFee).plus(orderFundingFee);

  return {
    ...symbolInfo,
    ...item,
    side: Number(item.cumCloseSize) > 0 ? "BUY" : "SELL",
    entryPrice,
    exitPrice,
    orderCloseValue,
    orderOpenValue,
    orderTradingFee,
    orderFundingFee,
    totalPnl,
    fillOpenFee: item.cumOpenFee,
    fillCloseFee: item.cumCloseFee,
  };
};
