// onboarding 整体流程
// 1. 检查本地存储l2Key、apiKey
// 2. 若存在 则通过任意私有接口检查 通过拦截器判断后续逻辑
//    若不存在 先检查用户是否存在，进行注册/登录
import useInterface from "@/stores/interface";
import useUser from "@/stores/user";
import { genApiKey, genL2Key } from "@/utils/onboarding";
import { captureException } from "@sentry/react";
import { useEffect, useRef } from "react";
import { getAddress } from "viem";
import { useAccount, useChainId, useWalletClient } from "wagmi";

const useOnboarding = () => {
  const { metadata } = useInterface();
  const account = useAccount();
  const chainId = useChainId();
  const { keys, currentActiveAccount } = useUser();
  const address = currentActiveAccount?.ethAddress || account?.address;
  const { data: connectorClient } = useWalletClient();

  // const [l2KeyResult, setL2KeyResult] = useState();
  // const [apiKeyResult, setApiKeyResult] = useState();

  const l2KeyResult = useRef();
  const apiKeyResult = useRef();

  const appName = metadata?.global?.appName;
  const appEnv = metadata?.global?.appEnv;
  const appOnlySignOn = metadata?.global?.appOnlySignOn;

  useEffect(() => {
    if (address) {
      l2KeyResult.current = undefined;
      apiKeyResult.current = undefined;
    }
  }, [address]);

  // 1. 检查用户存在 2. 注册 3. 登录
  const onboarding = async ({ onBeforeGenL2Key, onAfterGenL2Key, onBeforeGenApiKey, onAfterGenApiKey, clientAccountId = "main" }) => {
    if (!address) {
      throw new Error("Invalid address");
    }
    if (!chainId) {
      throw new Error("Invalid chain");
    }
    if (!appName || !appEnv || !appOnlySignOn) {
      throw new Error("Invalid sign params");
    }

    // console.log("onboarding", address, chainId, appName, appEnv, appOnlySignOn, getAddress(address), connectorClient);

    const appEnvInfo = {
      appEnv,
      appOnlySignOn,
      appName,
    };
    try {
      if (!l2KeyResult?.current?.keySignature) {
        // await new Promise((resolve) => setTimeout(resolve, 2000));
        onBeforeGenL2Key?.();
        const { keyPair: l2KeyPair, signature: l2KeySignature } = await genL2Key({
          account: getAddress(address),
          chainId,
          appEnv,
          appOnlySignOn,
          appName,
          clientAccountId,
          connectorClient,
        });

        const res = {
          keyPair: l2KeyPair,
          keySignature: l2KeySignature,
        };
        l2KeyResult.current = res;
        onAfterGenL2Key?.(res);
      }

      if (!apiKeyResult?.current?.keySignature) {
        onBeforeGenApiKey?.();
        const { keyPair: apiKeyPair, signature: apiKeySignature } = await genApiKey({
          account: getAddress(address),
          chainId,
          appOnlySignOn,
          appName,
          connectorClient,
        });

        const res = {
          keyPair: apiKeyPair,
          keySignature: apiKeySignature,
        };
        apiKeyResult.current = res;
        onAfterGenApiKey?.(res);
      }

      //  {
      //   [address]: {
      //     'main': {apiKey, l2Key},
      //     'account1': {apiKey, l2Key},
      //     'account2': {apiKey, l2Key},
      //   }
      //  }
      const userKeys = {
        [address?.toLowerCase()]: {
          ...keys?.[address?.toLowerCase()],
          [clientAccountId]: {
            l2Key: l2KeyResult?.current?.keyPair,
            apiKey: apiKeyResult?.current?.keyPair,
          },
        },
      };

      const currentActiveUserKey = {
        [address?.toLowerCase()]: {
          l2Key: l2KeyResult?.current?.keyPair,
          apiKey: apiKeyResult?.current?.keyPair,
        },
      };

      return {
        appEnvInfo,
        userKeys,
        currentActiveUserKey,
        l2KeySignature: l2KeyResult?.current?.keySignature,
        apiKeySignature: apiKeyResult?.current?.keySignature,
      };
    } catch (e) {
      captureException(e);
      throw new Error(e);
    }
  };

  return {
    onboarding,
  };
};

export default useOnboarding;
