import useInterface from "@/stores/interface";
import useUser from "@/stores/user";
import { checkPrefix, genApiSignature, getPrivateSignatureLocalInfo, sortObjectByKeys, toQueryString } from "@/utils/onboarding";
import axios from "axios";
// import * as Sentry from "@sentry/react";

axios.defaults.baseURL = "/api";

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 判断private
    if (config?.url?.includes("private") || config?.url?.includes("/opt/")) {
      const { currentActiveApiKey, timestamp } = getPrivateSignatureLocalInfo();
      const signBody =
        config?.method?.toUpperCase() == "GET" && config?.params
          ? Object.fromEntries(
              Object.entries(config?.params)
                .filter(([, v]) => typeof v != "undefined" && v !== "" && v !== null)
                .map(([k, v]) => [k, Array.isArray(v) ? v.join(",") : v]),
            )
          : config?.data;

      if (config?.method?.toUpperCase() == "GET") {
        config.params = signBody;
      }
      const requestBody = toQueryString(sortObjectByKeys(signBody));
      const signParams = {
        timestamp: timestamp,
        httpMethod: config?.method?.toUpperCase(),
        requestUri: config?.url?.includes("private") ? checkPrefix(config?.url, "/api") : config?.url,
        requestBody: requestBody,
        secret: currentActiveApiKey?.secret,
      };
      const signature = genApiSignature(signParams);

      const headerKey = useInterface.getState()?.metadata?.global?.appName;
      config.headers["X-" + headerKey + "-Api-Key"] = currentActiveApiKey?.apiKey;
      config.headers["X-" + headerKey + "-Passphrase"] = currentActiveApiKey?.passphrase;
      config.headers["X-" + headerKey + "-Signature"] = signature;
      config.headers["X-" + headerKey + "-Timestamp"] = timestamp;

      // VIP API
      if (config?.url?.includes("/opt/")) {
        config.baseURL = window?.ENV?.OPT_API_BASEURL || "https://cloudgw-testnet.edgex.exchange";
      }
      return config;
    }
    // Referral & Points API
    if (config?.url?.includes("invite") || config?.url?.includes("points")) {
      config.baseURL = window?.ENV?.EVENT_API_BASEURL || "https://testapi.pompom.social/api";
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  },
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码 报错
    if (response?.data?.msg && response?.data?.msg?.toUpperCase() !== "SUCCESS") {
      return Promise.reject({ ...response?.data, message: response?.data?.msg, config: response?.config });
    }
    return response;
  },
  function (error) {
    const status = error?.response?.status || 200;
    const url = error?.config?.url || "";
    const method = error?.config?.method || "GET";
    // Sentry.captureMessage(`REQUEST : ${method?.toUpperCase()} ${url} ${status}`);

    if ([401].includes(error?.response?.status)) {
      // 权限错误重新登录
      window?.ENV?.["401_RESET"] !== "0" && useUser.getState()?.resetUser();
      return Promise.resolve();
    }

    return Promise.reject(
      error?.response?.data?.msg
        ? {
            ...error,
            message: error?.response?.data?.msg,
          }
        : error,
    );
    // return Promise.reject(error);
  },
);

export default axios;
