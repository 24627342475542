import React from "react";
import { useTitle } from "ahooks";
import { Outlet } from "react-router";
import { Toaster } from "@/components/ui/toaster";
import Footer from "@/modules/home/components/Footer";
import Header from "@/modules/layout/components/Header";
import GlobalLayoutModule from "@/modules/layout/GlobalLayoutModule";

function Layout() {
  useTitle("edgeX - High-performance, orderbook-based perpetual DEX with native trading experience.");
  return (
    <>
      <GlobalLayoutModule />
      <div className="flex-1 flex flex-col">
        <Header />
        <div className="min-h-[calc(100vh-6rem)]">
          <Outlet />
        </div>
        <Footer topBorder={true} />
      </div>
      <Toaster />
    </>
  );
}

export default Layout;
