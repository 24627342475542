import { useToast } from "@/components/ui/use-toast";
import { CustomEvents } from "@/constants/customEvents";
import { disclaimerAndSignStepEnum } from "@/constants/wallets";
import axios from "@/services/request";
import realtimePrivate from "@/services/ws.realtimePrivate";
import useGlobal from "@/stores/global";
import useUser from "@/stores/user";
import { captureException } from "@sentry/react";
import { useEventListener, useMount } from "ahooks";
import { useAccountEffect } from "wagmi";

/**
 * Bootstrap 后用户信息的初始化请求，以及监听 private ws 推送的处理更新 store
 */
function UserUpdater() {
  const { toast: Toast } = useToast();
  const { setGlobal } = useGlobal();

  const { resetUser, setUser, currentClientAccountId, isAlreadySigned, currentActiveAccount: oldCurrentActiveAccount } = useUser();
  const alreadySigned = isAlreadySigned();
  // console.log("alreadySigned", address, ready, alreadySigned, account.status);
  // useDebounceEffect(
  //   () => {
  //     if (address && ready && !alreadySigned && account.status === "connected") {
  //       setGlobal({ disclaimerAndSignVisible: true, disclaimerAndSignStep: disclaimerAndSignStepEnum.unknown });
  //     }
  //   },
  //   [address, ready, alreadySigned, account.status],
  //   {
  //     wait: 500,
  //   },
  // );

  // 登录签名
  // 已登录刷新页面回调也会执行
  // useLogin({
  //   onComplete: (user /*isNewUser , wasAlreadyAuthenticated, loginMethod, loginAccount*/) => {
  //     if (user?.wallet?.address && !alreadySigned) {
  //       setGlobal({ disclaimerAndSignVisible: true, disclaimerAndSignStep: disclaimerAndSignStepEnum.unknown });
  //     }
  //   },
  // });

  // useConnectWallet({
  //   onSuccess: () => {
  //     if (!alreadySigned) {
  //       setGlobal({ disclaimerAndSignVisible: true, disclaimerAndSignStep: disclaimerAndSignStepEnum.unknown });
  //     }
  //   },
  // });

  // 注销处理
  // useLogout({
  //   onSuccess: () => {
  //     resetUser();
  //     realtimePrivate.logout();
  //   },
  // });

  useAccountEffect({
    onConnect: () => {},
    onDisconnect: () => {
      resetUser();
      realtimePrivate.logout();
    },
  });

  const accountFields = [
    "account",
    "collateral",
    "position",
    "positionTransaction",
    "deposit",
    "withdraw",
    "transferIn",
    "transferOut",
    "order",
    "orderFillTransaction",
    "positionTermList",
    "tradeHistory",
    "fastWithdraw",
  ];

  const getUserInfo = async (forceAccountId) => {
    // if (updatedRef.current) return;
    const targetClientAccountId = forceAccountId || currentClientAccountId;
    try {
      // updatedRef.current = true;
      // 先清空既有字段
      setUser(Object.fromEntries(accountFields.map((k) => [k, []])));
      const [getUserInfoRes, getAccountPageRes] = await Promise.all([axios.get("/v1/private/user/getUserInfo"), axios.get("/v1/private/account/getAccountPage")]);
      // 当前 clientAccountId 对应的 account 数据
      const currentActiveClientIdAccount = getAccountPageRes?.data?.data?.dataList?.find((i) => {
        return targetClientAccountId === i?.clientAccountId;
      });
      if (!currentActiveClientIdAccount) {
        return;
      }
      const address = currentActiveClientIdAccount?.ethAddress;
      const keys = useUser.getState()?.keys;
      // {apiKey, l2Key}
      const apiKey = keys?.[address?.toLowerCase()]?.[targetClientAccountId]?.apiKey;
      const l2Key = keys?.[address?.toLowerCase()]?.[targetClientAccountId]?.l2Key;

      const currentActiveAccount = {
        ...oldCurrentActiveAccount,
        ...getUserInfoRes?.data?.data,
        ...currentActiveClientIdAccount,
        accountId: currentActiveClientIdAccount?.id,
        keys: {
          apiKey,
          l2Key,
        },
      };

      setUser({
        account: getAccountPageRes?.data?.data?.dataList,
        currentActiveAccount: currentActiveAccount,
      });
      fetchAccountHistory();
      realtimePrivate?.init(); // 手动连接 ws
    } catch (e) {
      captureException(e);
      Toast.show(e?.message, { type: "error" });
    }
  };

  // 连接钱包 签名
  useEventListener(
    CustomEvents["wallet-connected"],
    (e) => {
      if (alreadySigned) return;
      setUser({
        currentActiveAccount: {
          ...oldCurrentActiveAccount,
          ethAddress: e.detail?.address,
          wallet: e.detail?.wallet,
          walletIcon: e.detail?.walletIcon,
        },
      });
      setGlobal({ disclaimerAndSignVisible: true, disclaimerAndSignStep: disclaimerAndSignStepEnum.unknown });
    },
    {
      target: window,
    },
  );
  // onboarding 完成后，获取用户信息
  useEventListener(
    CustomEvents["account-onboard"],
    (e) => {
      const forceAccountId = e.detail?.clientAccountId;
      realtimePrivate.logout();
      getUserInfo(forceAccountId);
    },
    {
      target: window,
    },
  );

  // 子账号切换
  useEventListener(
    CustomEvents["subaccount-switch"],
    (e) => {
      const forceAccountId = e.detail?.clientAccountId;
      realtimePrivate.logout();
      getUserInfo(forceAccountId);
    },
    {
      target: window,
    },
  );

  // 记录唯一标识不是 id 字段的映射
  const getIdField = (k) => ({ position: "contractId", collateral: "coinId", positionTermList: (item) => item.contractId + item.termCount }[k] || "id");

  const fetchAccountHistory = async () => {
    const accountId = useUser.getState()?.currentActiveAccount?.accountId;

    const [positionTransactionRes, orderFillTransactionRes, positionTermRes, tradeHistoryRes] = await Promise.all([
      axios.get("/v1/private/account/getPositionTransactionPage", { params: { accountId, filterCloseOnly: true } }),
      axios.get("/v1/private/order/getHistoryOrderFillTransactionPage", { params: { accountId } }),
      axios.get("/v1/private/account/getPositionTermPage", { params: { accountId } }),
      axios.get("/v1/private/order/getHistoryOrderPage", { params: { accountId, filterStatusList: ["FILLED", "CANCELED"] } }),
    ]);

    const dataToMerge = {
      positionTransaction: positionTransactionRes?.data?.data?.dataList,
      orderFillTransaction: orderFillTransactionRes?.data?.data?.dataList,
      positionTermList: positionTermRes?.data?.data?.dataList,
      tradeHistory: tradeHistoryRes?.data?.data?.dataList.map((r) => ({ ...r, from: "http-api" })),
    };

    setUser({
      ...Object.fromEntries(
        Object.entries(dataToMerge)
          .filter(([k, v]) => ["positionTransaction", "orderFillTransaction", "positionTermList", "tradeHistory"].includes(k) && v?.length > 0)
          .map(([k, v]) => {
            const oldRows = useUser.getState()[k] || [];
            v.forEach((rr) => {
              rr.id = rr.id || (typeof getIdField(k) === "function" ? getIdField(k)(rr) : rr[getIdField(k)]);
            });
            return [k, [...oldRows.filter((r) => !v.find((rr) => rr.id == r.id)), ...v].sort((a, b) => Number(b.createdTime) - Number(a.createdTime))];
          }),
      ),
    });
  };

  useMount(() => {
    if (alreadySigned) {
      realtimePrivate.logout();
      getUserInfo();
    }
  }, [alreadySigned]);

  // 监听 ws 推送
  useEventListener(
    CustomEvents["ws-message"],
    (e) => {
      if ((e.detail?.type == "trade-event" || e.detail?.type == "assets-event") && e.detail?.content?.data) {
        // e.detail?.content?.data 形如 {account:[],position:[],...}
        // 特殊处理 tradeHistory，将新推送的终态 order 合并到 tradeHistory
        e.detail.content.data.tradeHistory = e.detail?.content?.data.order?.filter((o) => ["FILLED", "CANCELED"].includes(o.status)) || [];
        setUser({
          ...Object.fromEntries(
            Object.entries(e.detail?.content?.data)
              .filter(([k, v]) => accountFields.includes(k) && v?.length > 0)
              .map(([k, v]) => {
                const oldRows = useUser.getState()[k] || [];
                v.forEach((rr) => {
                  rr.id = rr.id || (typeof getIdField(k) === "function" ? getIdField(k)(rr) : rr[getIdField(k)]);
                });
                return [
                  k,
                  // 标记来自 Snapshot 还是 update
                  [...oldRows.filter((r) => !v.find((rr) => rr.id == r.id)), ...v.map((r) => ({ ...r, from: e.detail?.content.event }))].sort((a, b) => Number(b.createdTime) - Number(a.createdTime)),
                ];
              }),
          ),
          // 更新当前账号信息
          currentActiveAccount: {
            ...oldCurrentActiveAccount,
            ...e.detail?.content?.data?.account?.find((i) => {
              return currentClientAccountId === i?.clientAccountId;
            }),
          },
        });
      }
    },
    { target: document },
  );

  return <></>;
}

export default UserUpdater;
