import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const unpartializeList = ["walletConfig"];

const useWalletConfig = create(
  persist(
    (set) => ({
      walletConfig: undefined, // 钱包配置
      setWalletConfig: (newValues) => set((state) => ({ ...state, ...newValues })),
    }),
    {
      name: "walletConfig",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => {
        const keys = Object.keys(state);
        return keys.reduce((prev, next) => {
          if (!unpartializeList.includes(next)) {
            return { ...prev, [next]: state[next] };
          }
          return prev;
        }, {});
      },
    },
  ),
);

export default useWalletConfig;
