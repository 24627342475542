import React, { useEffect, useMemo } from "react";
import { IntlProvider } from "react-intl";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { routes } from "@/routes/routes";
import Bootstrap from "@/modules/layout/components/Bootstrap";
import { TooltipProvider } from "@/components/ui/tooltip";
import ResponseErrorToast from "@/components/ResponseErrorToast";
import { fullChains, genConfig } from "@/constants/wallets";
import useGlobal from "@/stores/global";
import useWalletConfig from "./stores/wallet";
import useInterface from "./stores/interface";
import en from "./locales/en-US.json";
import "@/services/request";
import { initGA } from "./utils/analytics";

// load all i18n messages
const allMessages = require.context("@/locales", true, /^\.\/[\w]+-[\w]+.json$/);
const allMessagesMap = {};
allMessages.keys().forEach((filePath) => {
  const module = allMessages(filePath);
  const moduleName = filePath.match(/.+\/([\w]+-.+)\.json$/)?.[1];
  moduleName && (allMessagesMap[moduleName] = module);
});

const queryClient = new QueryClient();

// 埋点
initGA();

function App() {
  const messages = { ...Object.fromEntries(Object.entries(allMessagesMap).map(([lang, messages]) => [lang, { ...en, ...messages }])), "en-US": en };

  const { lang, remoteMessages } = useGlobal();
  const { setWalletConfig } = useWalletConfig();
  const { metadata } = useInterface();
  const currentLocale = lang.split("-")[0];

  const config = useMemo(() => {
    const chains = metadata?.multiChain?.chainList
      ?.filter((j) => fullChains?.find((i) => +i?.id === +j?.chainId))
      ?.map((k) => {
        return {
          ...k,
          chainId: +k?.chainId,
          id: +k?.chainId,
        };
      });

    if (!chains?.length) {
      return undefined;
    }
    return genConfig(chains, chains?.length > 1);
  }, [metadata?.multiChain?.chainList?.length]);


  useEffect(() => {
    if (config && metadata?.multiChain?.chainList?.length) {
      setWalletConfig({ walletConfig: config });
    }
  }, [config, metadata?.multiChain?.chainList?.length]);

  // 保存语料信息到全局变量 src/hooks/useTranslation.jsx 需要用到
  // eslint-disable-next-line no-underscore-dangle
  window.__messages = remoteMessages?.[lang] || messages[lang] || {};

  return (
    <Bootstrap>
      <QueryClientProvider client={queryClient}>
        <WagmiProvider config={config}>
          <IntlProvider messages={remoteMessages?.[lang] || messages[lang] || {}} locale={currentLocale} defaultLocale={currentLocale} onError={() => {}}>
            <TooltipProvider>
              <RouterProvider router={createBrowserRouter(routes)} />
              <ResponseErrorToast />
            </TooltipProvider>
          </IntlProvider>
        </WagmiProvider>
      </QueryClientProvider>
    </Bootstrap>
  );
}
export default App;
