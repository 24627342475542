/**
 * 如果 本地 l2 签名存在，钱包没有链接，重新链接钱包
 * 返回可靠的 Promise
 */
import useSubAccount from "@/modules/account/hooks/subaccount";
import useUser from "@/stores/user";
import { useContext } from "react";
import { useAccount, WagmiContext } from "wagmi";

export default (config) => {
  const sign = config?.sign; // 是否需要签名
  const wagmiAccount = useAccount();
  const { currentActiveAccount } = useUser();
  const wagmiContext = useContext(WagmiContext);
  const connectors = wagmiContext?.connectors;
  const { signAccount } = useSubAccount();

  // console.log("useReconnect", currentActiveAccount?.ethAddress, wagmiAccount?.status, wagmiAccount?.address);

  return async () => {
    const readyConnector = connectors?.find((c) => c.id == currentActiveAccount?.wallet);
    if (
      readyConnector &&
      currentActiveAccount?.ethAddress &&
      (wagmiAccount?.status == "disconnected" || (wagmiAccount?.status == "connected" && wagmiAccount?.address != currentActiveAccount?.ethAddress))
    ) {
      await readyConnector?.connect();
    }
    if (sign !== false) {
      await signAccount(currentActiveAccount?.clientAccountId || "main");
    }
  };
};
