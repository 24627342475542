import Icon from "@/components/Icon";
import { useToast } from "@/components/ui/use-toast";
import useActiveChains from "@/hooks/useActiveChains";
import useMulticallBalance from "@/hooks/useMulticallBalance";
import useSubmitTx from "@/hooks/useSubmitTx";
import useTranslation from "@/hooks/useTranslation";
import depositToast from "@/icons/deposit-toast.svg";
import useInterface from "@/stores/interface";
import useUser from "@/stores/user";
import { useInterval, useRequest } from "ahooks";
import axios from "axios";
import BigNumber from "bignumber.js";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { erc20Abi, getAddress, parseUnits } from "viem";
import { useSwitchChain } from "wagmi";
import { mainnet } from "viem/chains";
import { captureException } from "@sentry/react";

const Progress = ({ max }) => {
  const [seconds, setSeconds] = useState(max);
  const m = ~~(seconds / 60);
  const s = seconds % 60;
  useInterval(() => {
    setSeconds((seconds) => Math.max(0, seconds - 1));
  }, 1000);
  return (
    <div className="flex items-center gap-2">
      <progress className="progress progress-primary w-full h-1 flex-1" value={seconds} max={max}></progress>
      <span>
        ~{m == 0 ? "" : m + "m"}
        {s}s
      </span>
    </div>
  );
};
Progress.propTypes = {
  max: PropTypes.number,
};

const useDeposit = () => {
  const multiLanguage = useTranslation();
  const { metadata } = useInterface();
  const { currentActiveAccount } = useUser();
  const { toast: Toast } = useToast();
  const { multicallRun } = useMulticallBalance();
  const [curSelectToken, setCurSelectToken] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [activeToken] = useState("USDT"); // USDT or USDC 目前是静态

  const [dlgVisible, setDlgVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const SLIPPAGE_TABS = ["0.25", "0.5", "1"];
  const [slippage, setSlippage] = useState(SLIPPAGE_TABS[1]);

  const { currentActiveChainTokens, currentActiveChain, unsupported, chains } = useActiveChains();
  const { switchChain, isPending } = useSwitchChain();
  const tokens = currentActiveChainTokens;

  // 当前选择的代币信息
  const tokenInfo = tokens?.find((t) => t.token == curSelectToken) || tokens?.[0] || {};

  const [currentSelectTokenQuotePrice, setCurrentSelectTokenQuotePrice] = useState("1");
  const currentTokenBalanceWithQuotePrice = useMemo(() => {
    return BigNumber(currentSelectTokenQuotePrice || 0)
      .multipliedBy(depositAmount || 0)
      .toString();
  }, [depositAmount]);

  useRequest(
    () => {
      if (!tokenInfo?.token || !currentActiveChain?.chainId || !tokenInfo?.useFixedRate) return Promise.reject("1");
      return axios.get("/v1/private/assets/getCoinRate", {
        params: {
          chainId: currentActiveChain?.chainId,
          coin: tokenInfo?.token,
        },
      });
    },
    {
      ready: currentActiveChain?.chainId && tokenInfo?.token && tokenInfo?.useFixedRate && dlgVisible,
      refreshDeps: [currentActiveChain?.chainId, tokenInfo?.token, tokenInfo?.useFixedRate, dlgVisible],
      onSuccess(e) {
        const rate = e?.data?.data?.rate || "1";
        setCurrentSelectTokenQuotePrice(rate);
      },
      onError() {
        setCurrentSelectTokenQuotePrice("1");
      },
    },
  );

  const {
    data: balancesData,
    loading: multicallFetchLoading,
    runAsync: multicallFetchRun,
  } = useRequest(multicallRun, {
    manual: true,
  });

  useEffect(() => {
    if (!dlgVisible) return;
    setCurSelectToken(tokens?.[0]?.token);
    multicallFetchRun();
  }, [currentActiveChain?.chainId]);

  const starkExAssetId = useMemo(() => metadata?.coinList?.find((i) => i?.coinId === metadata?.multiChain?.coinId)?.starkExAssetId, [metadata?.coinList, metadata?.multiChain?.coinId]);
  const ifBaseToken = tokenInfo?.tokenAddress?.toLowerCase() == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
  // 当前选择币种的余额、allowance
  // 本币 tokenInfo?.tokenAddress 为空
  const curSelectTokenData = useMemo(() => {
    return !ifBaseToken ? balancesData?.combinedData?.[getAddress(tokenInfo?.tokenAddress)] : { balance: balancesData?.baseTokenBalance };
  }, [balancesData?.baseTokenBalance, balancesData?.combinedData, tokenInfo?.tokenAddress, ifBaseToken]);

  const approved = useMemo(() => {
    if (ifBaseToken) return true;
    if (!+curSelectTokenData?.allowance?.formatted) return false;
    return BigNumber(curSelectTokenData?.allowance?.value?.toString()).gte(parseUnits("" + (Number(depositAmount || "0") || 0), curSelectTokenData?.allowance?.decimals).toString());
  }, [curSelectTokenData?.allowance?.decimals, curSelectTokenData?.allowance?.formatted, curSelectTokenData?.allowance?.value, depositAmount, ifBaseToken]);

  // console.log("approved", approved, curSelectTokenData?.allowance?.formatted, depositAmount);

  const depositAmountTooSmall = depositAmount && Number(depositAmount) < (Number(metadata?.multiChain?.minDeposit) || 0);

  const { writeContract, loading: approveLoading } = useSubmitTx();

  const callApprove = async () => {
    try {
      if (unsupported) {
        await switchChain({ chainId: +currentActiveChain?.chainId });
      }
      // console.log("approve", tokenInfo.tokenAddress, currentActiveChain?.contractAddress, parseUnits(depositAmount, tokenInfo?.decimals));
      await writeContract(
        {
          abi:
            +currentActiveChain?.chainId == mainnet.id
              ? [
                  {
                    constant: false,
                    inputs: [
                      {
                        name: "_spender",
                        type: "address",
                      },
                      {
                        name: "_value",
                        type: "uint256",
                      },
                    ],
                    name: "approve",
                    outputs: [],
                    payable: false,
                    stateMutability: "nonpayable",
                    type: "function",
                  },
                ]
              : erc20Abi,
          address: tokenInfo.tokenAddress,
          functionName: "approve",
          args: [currentActiveChain?.contractAddress, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"],
        },
        true,
      );
      await multicallFetchRun();

      Toast.show(multiLanguage("approveSuccess"));
    } catch (e) {
      captureException(e);
      // eslint-disable-next-line no-console
      console.error("error", e);
      Toast.show(multiLanguage(typeof e?.details == "string" ? e?.details : e.message), { type: "error" });
    } finally {
      await multicallFetchRun();
    }
  };

  const handleDeposit = async () => {
    if (unsupported) {
      return switchChain({ chainId: +currentActiveChain?.chainId });
    }
    if (!approved) {
      await callApprove();
    }
    if (!approved) return 0;
    try {
      if (!starkExAssetId) throw { message: "Invalid starkExAssetId" };
      setSubmitting(true);
      let exchangeData = "0x0";
      const fromTokenAddress = tokenInfo.tokenAddress;
      const toTokenAddress = tokens?.find((t) => t.isDefaultToken)?.tokenAddress;
      if (fromTokenAddress != toTokenAddress) {
        exchangeData = await axios
          .get("v1/public/1inch/getAggregateExchangeData", {
            params: {
              src: fromTokenAddress,
              dst: toTokenAddress,
              amount: parseUnits(depositAmount, tokenInfo?.decimals),
              slippage: Math.min(50, Number(slippage) * 100),
              from: currentActiveAccount?.ethAddress,
              receiver: currentActiveAccount?.ethAddress,
              disableEstimate: true,
              compatibility: true,
              chainId: "" + currentActiveChain?.chainId,
            },
          })
          .then((res) => res.data?.data?.tx?.data);
      }
      const contractArg = {
        abi: [
          {
            inputs: [
              { internalType: "contract IERC20", name: "token", type: "address" },
              { internalType: "uint256", name: "amount", type: "uint256" },
              { internalType: "uint256", name: "starkKey", type: "uint256" },
              { internalType: "uint256", name: "positionId", type: "uint256" },
              { internalType: "bytes", name: "exchangeData", type: "bytes" },
            ],
            name: "deposit",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "payable",
            type: "function",
          },
        ],
        address: currentActiveChain?.contractAddress,
        functionName: "deposit",
        args: [tokenInfo?.tokenAddress, parseUnits(depositAmount, tokenInfo?.decimals), currentActiveAccount?.l2Key, currentActiveAccount?.accountId, exchangeData],
        chainId: +currentActiveChain?.chainId,
        gas: 396015,
      };
      // console.log("contractArg", contractArg);
      await writeContract(contractArg, false);
      setDlgVisible(false);
      const maxSeconds = parseInt(currentActiveChain?.blockTime) * parseInt(currentActiveChain?.txConfirm);

      const isNormalDeposit = currentActiveChain?.chainId == metadata?.multiChain?.chainList?.[0]?.chainId;

      Toast.show({
        duration: maxSeconds * 1000,
        title: (
          <>
            <Icon icon={depositToast} className="size-4 fill-foreground" />
            {multiLanguage(isNormalDeposit ? "normalDepositProgress" : "crossDepositProgress")}
          </>
        ),
        txt: (
          <>
            <div>
              {multiLanguage(isNormalDeposit ? "normalDepositProgressDetail" : "crossDepositProgressDetail", {
                coin: tokenInfo?.token,
                amount: depositAmount,
                chain: currentActiveChain?.chain,
              })}
            </div>
            <Progress max={maxSeconds} />
          </>
        ),
      });
    } catch (e) {
      captureException(e);
      // eslint-disable-next-line no-console
      console.error("error", e);
      let errorMessage = typeof e?.details == "string" ? e?.details : e?.name || e.message;

      if (e?.name && e?.name == "ContractFunctionExecutionError" && e.message.includes("transfer exceeds allowance")) {
        errorMessage = "deposit.transferExceedsAllowanceError";
      }

      Toast.show(multiLanguage(errorMessage), { type: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  return {
    unsupported,
    isPending,
    currentTokenBalanceWithQuotePrice,
    currentSelectTokenQuotePrice,
    depositAmount,
    tokens,
    tokenInfo,
    curSelectToken,
    starkExAssetId,
    balancesData,
    multicallFetchLoading,
    ifBaseToken,
    curSelectTokenData,
    approveLoading,
    approved,
    setDepositAmount,
    setCurSelectToken,
    callApprove,
    handleDeposit,
    multicallFetchRun,
    switchChain,
    chains,
    submitting,
    setSubmitting,
    dlgVisible,
    setDlgVisible,
    currentActiveChain,
    depositAmountTooSmall,
    slippage,
    setSlippage,
    activeToken,
  };
};
export default useDeposit;
