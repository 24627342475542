/**
 * 表单 form item 的布局组件，包含样式
 * "join" ：取名源于 daisyui join 组件，其他常见命名有 "Group" ，用于将多个元素拼接在一起
 */
import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import ui from "@/constants/ui";

const JoinFormControl = ({ hasError, className, prefixItems, children, suffixItems }) => {
  return (
    <div
      data-error={hasError ? "on" : ""}
      className={twMerge(
        "join flex-shrink-0  overflow-hidden items-center h-10 border border-solid  box-border px-3 gap-1 transition-all w-full group/join bg-[--join] border-[--join] group-[]/dialog:bg-background group-[]/dialog:border-background hover:border-border-subtle focus-within:border-border-strong rounded-sm has-[[data-state=open]]:border-border-strong has-[:focus]:border-border-strong",
        ui?.joinFormControlClassName,
        className,
      )}
    >
      {prefixItems?.filter(Boolean)?.map((pi, i) => (
        <div className="join-item h-full inline-flex items-center" key={`pi-${i}`}>
          {pi}
        </div>
      ))}
      <div className="flex-1 join-item h-full inline-flex items-center  [&>input]:border-none [&>input]:outline-none [&>input]:bg-transparent [&>input]:w-full [&>input]:h-full">{children}</div>
      {suffixItems?.filter(Boolean)?.map((si, i) => (
        <div className="join-item h-full inline-flex items-center" key={`si-${i}`}>
          {si}
        </div>
      ))}
    </div>
  );
};

JoinFormControl.propTypes = {
  hasError: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  prefixItems: PropTypes.array,
  suffixItems: PropTypes.array,
};

export default JoinFormControl;
