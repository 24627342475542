import Icon from "@/components/Icon";
import Spinner from "@/components/Spinner";
import { useToast } from "@/components/ui/use-toast";
import useActiveChains from "@/hooks/useActiveChains";
import useTranslation from "@/hooks/useTranslation";
import caret from "@/icons/caret.svg";
import HeaderDropDown from "@/modules/layout/components/HeaderDropDown";
import useUser from "@/stores/user";
import { captureException } from "@sentry/react";
import { twMerge } from "tailwind-merge";
import { useSwitchChain } from "wagmi";

const ChainDropDown = () => {
  const { chains, currentActiveChain } = useActiveChains();
  const { switchChainAsync, isPending } = useSwitchChain();
  const multiLanguage = useTranslation();
  const { toast } = useToast();
  const { currentActiveAccount } = useUser();

  const handleSwitchChain = async (config) => {
    if (+currentActiveChain?.chainId === +config?.chainId) return;
    try {
      await switchChainAsync({ chainId: +config?.chainId });
    } catch (e) {
      captureException(e);
      toast.show(multiLanguage(e?.details), { type: "error" });
    }
  };

  if (!chains?.length || currentActiveAccount?.wallet == "mpc") return null;
  return (
    <HeaderDropDown
      contentClassName={"min-w-[200px]"}
      title={
        <>
          {isPending ? <Spinner className={twMerge("size-5")} /> : <img className={twMerge("icon size-5")} src={currentActiveChain?.chainIconUrl} />}
          <Icon icon={caret} className="caret h-2.5 fill-foreground transition-all origin-center" />
        </>
      }
    >
      {chains.map((c) => (
        <a key={c.chain} onClick={() => handleSwitchChain(c)}>
          <img className={twMerge("icon size-5")} src={c?.chainIconUrl} />
          <div className="text-ellipsis overflow-hidden whitespace-nowrap">{c?.chain}</div>
        </a>
      ))}
    </HeaderDropDown>
  );
};

export default ChainDropDown;
