import * as Sentry from "@sentry/react";

Sentry.init({
  environment: window?.ENV?.ENV || "local",
  dsn: "https://3b40719a8fe68845c7fab189098c6b13@o4507649060175872.ingest.de.sentry.io/4507650046951504",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.browserApiErrorsIntegration({
      setTimeout: true,
      setInterval: true,
      requestAnimationFrame: true,
      XMLHttpRequest: true,
      eventTarget: true,
    }),
    // Sentry.captureConsoleIntegration({ levels: ["error"] }),
    Sentry.globalHandlersIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/.*\.edgex\.(exchange|codekit\.work)\/(opt|api)/, "https://testapi.pompom.social/api"],
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
