// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/font/IBMPlexSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/font/IBMPlexSans-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/font/IBMPlexSans-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@/assets/font/IBMPlexSans-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/**
 * 全局字体
 */

@layer components {
  .font-base {
    font-display: swap;
    font-style: normal;
  }
}

@layer base {
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  @font-face {
    @apply font-base;
    font-family: "IBMPlexSans";
    font-weight: 400;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2");
  }

  @font-face {
    @apply font-base;
    font-family: "IBMPlexSans";
    font-weight: 500;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");
  }

  @font-face {
    @apply font-base;
    font-family: "IBMPlexSans";
    font-weight: 600;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2");
  }

  @font-face {
    @apply font-base;
    font-family: "IBMPlexSans";
    font-weight: 700;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2");
  }
}
`, "",{"version":3,"sources":["webpack://./src/style/global.font-family.css"],"names":[],"mappings":";AACA;;EAEE;;AAEF;EACE;IACE,kBAAkB;IAClB,kBAAkB;EACpB;AACF;;AAEA;EACE,4DAA4D;EAC5D;IACE,gBAAgB;IAChB,0BAA0B;IAC1B,gBAAgB;IAChB,4DAAiE;EACnE;;EAEA;IACE,gBAAgB;IAChB,0BAA0B;IAC1B,gBAAgB;IAChB,4DAAgE;EAClE;;EAEA;IACE,gBAAgB;IAChB,0BAA0B;IAC1B,gBAAgB;IAChB,4DAAkE;EACpE;;EAEA;IACE,gBAAgB;IAChB,0BAA0B;IAC1B,gBAAgB;IAChB,4DAA8D;EAChE;AACF","sourcesContent":["\n/**\n * 全局字体\n */\n\n@layer components {\n  .font-base {\n    font-display: swap;\n    font-style: normal;\n  }\n}\n\n@layer base {\n  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */\n  @font-face {\n    @apply font-base;\n    font-family: \"IBMPlexSans\";\n    font-weight: 400;\n    src: url(\"@/assets/font/IBMPlexSans-Regular.ttf\") format(\"woff2\");\n  }\n\n  @font-face {\n    @apply font-base;\n    font-family: \"IBMPlexSans\";\n    font-weight: 500;\n    src: url(\"@/assets/font/IBMPlexSans-Medium.ttf\") format(\"woff2\");\n  }\n\n  @font-face {\n    @apply font-base;\n    font-family: \"IBMPlexSans\";\n    font-weight: 600;\n    src: url(\"@/assets/font/IBMPlexSans-SemiBold.ttf\") format(\"woff2\");\n  }\n\n  @font-face {\n    @apply font-base;\n    font-family: \"IBMPlexSans\";\n    font-weight: 700;\n    src: url(\"@/assets/font/IBMPlexSans-Bold.ttf\") format(\"woff2\");\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
