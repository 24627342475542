import useActiveChains from "@/hooks/useActiveChains";
import useInterface from "@/stores/interface";
import useUser from "@/stores/user";
import { usePrivy, useSignTypedData } from "@privy-io/react-auth";
import { createConfig } from "@privy-io/wagmi";
import { multicall } from "@wagmi/core";
import { useInterval } from "ahooks";
import axios from "axios";
import BigNumber from "bignumber.js";
import { http } from "viem";
import * as fullChainsModule from "viem/chains";
// import { useSignTypedData } from "wagmi";
import { bnToHex32, normalizeHex32 } from "@/components/trade/helpers";
import useSignMessage from "@/hooks/useSignMessage";
import BN from "bn.js";
import { Buffer } from "buffer";
import { keccak256 } from "viem";
import { captureException } from "@sentry/react";

const getRSVFromSignature = (signature) => {
  const r = signature.slice(0, 66);
  const s = "0x" + signature.slice(66, 130);
  const v = parseInt(signature.slice(130, 132), 16);
  return { r, s, v };
};

const MpcConnectUpdater = () => {
  const { chains } = useActiveChains();
  const chain = chains.find((c) => c.chainId == fullChainsModule.arbitrumSepolia.id) ? fullChainsModule.arbitrumSepolia : fullChainsModule.arbitrum;

  const { metadata } = useInterface();

  const { currentActiveAccount, isAlreadySigned } = useUser();
  const alreadySigned = isAlreadySigned();
  const { signTypedData: signTypedDataAsync } = useSignTypedData();
  const { user, ready, authenticated } = usePrivy();
  const hasEmbeddedWallet = !!user?.linkedAccounts?.find((account) => account.type === "wallet" && account.walletClientType === "privy");
  const spender = metadata?.multiChain?.chainList.find((c) => +c.chainId == +chain.id)?.contractAddress;
  const tokenAddress = metadata?.multiChain?.chainList.find((c) => +c.chainId == +chain.id)?.tokenList?.find((t) => t.token == "USDT")?.tokenAddress;

  const { signMessage } = useSignMessage();

  const call = async () => {
    try {
      const res = await multicall(
        createConfig({
          chains: [chain],
          transports: {
            [chain.id]: http(),
          },
        }),
        {
          contracts: [
            {
              abi: [
                {
                  inputs: [{ internalType: "address", name: "owner", type: "address" }],
                  name: "nonces",
                  outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                  stateMutability: "view",
                  type: "function",
                },
              ],
              address: tokenAddress,
              functionName: "nonces",
              args: [currentActiveAccount?.ethAddress],
            },
            {
              abi: [{ inputs: [], name: "name", outputs: [{ internalType: "string", name: "", type: "string" }], stateMutability: "view", type: "function" }],
              address: tokenAddress,
              functionName: "name",
              args: [],
            },
            {
              abi: [
                {
                  inputs: [{ internalType: "address", name: "account", type: "address" }],
                  name: "balanceOf",
                  outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
                  stateMutability: "view",
                  type: "function",
                },
              ],
              address: tokenAddress,
              functionName: "balanceOf",
              args: [currentActiveAccount?.ethAddress],
            },
            {
              abi: [
                {
                  type: "function",
                  name: "decimals",
                  stateMutability: "view",
                  inputs: [],
                  outputs: [
                    {
                      type: "uint8",
                    },
                  ],
                },
              ],
              address: tokenAddress,
              functionName: "decimals",
              args: [],
            },
          ],
        },
      );
      const owner = currentActiveAccount?.ethAddress;
      const deadline = Math.floor(Date.now() / 1000) + 60 * 60 * 24;
      const domain = {
        name: res?.[1]?.result, // token Name
        version: "1",
        chainId: +chain.id,
        verifyingContract: tokenAddress,
      };
      const types = {
        Permit: [
          { name: "owner", type: "address" },
          { name: "spender", type: "address" },
          { name: "value", type: "uint256" },
          { name: "nonce", type: "uint256" },
          { name: "deadline", type: "uint256" },
        ],
      };

      const message = {
        owner: owner,
        spender: spender,
        value: res?.[2]?.result + "",
        nonce: res?.[0]?.result + "",
        deadline: deadline,
      };
      const typedData = {
        account: owner,
        message,
        domain,
        primaryType: "Permit",
        types,
      };

      const balance = res?.[2]?.result?.toString();
      const decimals = res?.[3]?.result;
      const signature = await signTypedDataAsync(typedData);
      const { r, s, v } = getRSVFromSignature(signature);
      // eslint-disable-next-line no-console
      // console.log("typedData", spender, tokenAddress, typedData, res, balance);
      if (balance === undefined || BigNumber(balance).lt(BigNumber(metadata?.multiChain?.minDeposit).multipliedBy(10 ** decimals))) {
        return;
      }

      // keccak256(abi.encodePacked(amount, starkKey, positionId, block.chainid))
      // ${normalizeHex32(bnToHex32(new BN(mpcParams.mpcSignTime))).toString()}
      const origin = `${normalizeHex32(bnToHex32(new BN("" + balance))).toString()}${normalizeHex32(bnToHex32(new BN("" + currentActiveAccount?.l2Key?.slice(2), 16))).toString()}${normalizeHex32(
        bnToHex32(new BN("" + currentActiveAccount.accountId)),
      ).toString()}${normalizeHex32(bnToHex32(new BN("" + chain.id))).toString()}`;
      // eslint-disable-next-line no-console
      // console.log("str2keccak256", origin);

      const keccak256Str = keccak256(Buffer.from(origin, "hex")); // 0xabc
      const str2Sign = keccak256Str;
      // eslint-disable-next-line no-console
      // console.log("keccak256Str", keccak256Str, str2Sign);
      const mpcSignature = await signMessage(str2Sign);
      // eslint-disable-next-line no-console
      // console.log("strAfterSignMessage", mpcSignature);

      axios.post("/v1/private/deposit/requestRelayerSignAndBroadcast", {
        deadline: "" + typedData?.message?.deadline,
        r,
        s,
        v,
        type: "permit",
        amount: "" + balance,
        owner: currentActiveAccount.ethAddress,
        starkKey: currentActiveAccount.l2Key.slice(2),
        positionId: currentActiveAccount.accountId,
        chainId: "" + chain.id,
        accountId: currentActiveAccount.accountId,
        mpcSignature,
      });
    } catch (error) {
      captureException(error);
      // eslint-disable-next-line no-console
      console.error("requestRelayerSignAndBroadcast error", error);
    }
  };

  useInterval(call, alreadySigned && hasEmbeddedWallet && ready && authenticated ? 10000 : undefined);
  return <></>;
};

export default MpcConnectUpdater;
