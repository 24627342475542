// form layout

import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import ui from "@/constants/ui";

const Form = ({ className, children, ...rest }) => (
  <div
    className={twMerge(
      "flex flex-col gap-4 [&>section]:flex [&>section]:flex-wrap [&>section]:items-center [&>section]:justify-between [&_output:not(.text-primary)]:text-foreground [&_output]:text-right [&_label]:text-[--font-gray] text-sm [&>section]:min-h-5",
      ui?.formClassName,
      className,
    )}
    {...rest}
  >
    {children}
  </div>
);
Form.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

Form.Section = ({ children, ...rest }) => <section {...rest}>{children}</section>;
Form.Section.displayName = "Form.Section";
Form.propTypes = {
  children: PropTypes.any,
};
Form.Section.propTypes = {
  children: PropTypes.any,
};

Form.Label = ({ children, ...rest }) => <label {...rest}>{children}</label>;
Form.Label.displayName = "Form.Label";
Form.Label.propTypes = {
  children: PropTypes.any,
};

Form.Control = ({ children, block, className, ...rest }) => (
  <output className={twMerge(block ? "!basis-full shrink-0 !text-left mt-1" : "", className)} {...rest}>
    {children}
  </output>
);
Form.Control.displayName = "Form.Control";
Form.Control.propTypes = {
  children: PropTypes.any,
  block: PropTypes.bool,
  className: PropTypes.string,
};
export default Form;
