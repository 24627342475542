import { keyPairFromData, stripHexPrefix } from "@/components/trade/helpers";
import { useToast } from "@/components/ui/use-toast";
import { CustomEvents } from "@/constants/customEvents";
import { disclaimerAndSignStepEnum } from "@/constants/wallets";
import useGlobal from "@/stores/global";
import useInterface from "@/stores/interface";
import useUser from "@/stores/user";
import { byteArrayToHex, hexToByteArray, onboardSite, subArray, urlBase64Encode, uuidFormat } from "@/utils/onboarding";
import { usePrivy, useSignMessage } from "@privy-io/react-auth";
import { captureException } from "@sentry/react";
import { useEventListener } from "ahooks";
import { Buffer } from "buffer";
import { useEffect } from "react";
import { keccak256 } from "viem";

const MpcHandler = () => {
  const { user, login, authenticated, ready } = usePrivy();
  const { setGlobal } = useGlobal();
  const { signMessage } = useSignMessage();
  const { metadata } = useInterface();
  const { keys, setUser, currentActiveAccount: oldCurrentActiveAccount } = useUser();
  const appName = metadata?.global?.appName;
  const appEnv = metadata?.global?.appEnv;
  const appOnlySignOn = metadata?.global?.appOnlySignOn;
  const apiName = "";
  const { toast: Toast } = useToast();

  const mpcUserOnboardSite = async ({ address, clientAccountId }) => {
    try {
      setGlobal({ connectModalVisible: false });
      const l2KeySignature = await signMessage(`name: ${appName}\nenvId: ${appEnv}\naction: L2 Key\nonlySignOn: ${appOnlySignOn}\nclientAccountId: ${clientAccountId}`);
      const apiKeySignature = await signMessage(`action: ${appName} Onboard\nonlySignOn: ${appOnlySignOn}${apiName ? "\nparam: " + apiName : ""}`);

      // console.log("mpcUserOnboardSite", address, l2KeySignature, apiKeySignature, clientAccountId);

      const l2KeyPair = keyPairFromData(Buffer.from(stripHexPrefix(l2KeySignature), "hex"));

      // 假设 signature 是一个字符串
      const tempSignature = apiKeySignature.replace("0x", "");
      const tempBuffer = hexToByteArray(tempSignature);
      const r_buffer_hash = keccak256(subArray(tempBuffer, 0, 32));
      const s_buffer_hash = keccak256(subArray(tempBuffer, 32, 32));
      const tmpApiKey = uuidFormat(byteArrayToHex(subArray(hexToByteArray(s_buffer_hash), 0, 16)));
      const secret = urlBase64Encode(subArray(hexToByteArray(r_buffer_hash), 0, 32));
      const passphrase = urlBase64Encode(subArray(hexToByteArray(s_buffer_hash), 16, 16));

      const apiKeyPair = {
        apiKey: tmpApiKey,
        passphrase,
        secret,
      };

      setUser({
        currentActiveAccount: {
          ...oldCurrentActiveAccount,
          ethAddress: address,
          wallet: "mpc",
        },
      });

      /*{
        "keyPair": {
          "publicKey": "073c491a95d80460a614356b6489dc9d6bb09a543fc6ef702d81a9340f8e197d",
          "publicKeyYCoordinate": "073807306c8be7f9b094b100151e957f63bb7620ca7935fda91a87dd37bc1e07",
          "privateKey": "0549a0d64572850ff02abd4b27038361132e86e25d587a8b88969bda2ab24b54"
        },
        "signature": "0x90b34951783f79b45d6370144912fe418b7debb28103b349ed92dc1e0f18fe9047beb7b22c030123168d2b0dbcb253d22b8c148260c19a23c448aad17127040f1c"
      }*/

      /*{
        "keyPair": {
          "apiKey": "a139ecbd-fd16-c2f4-0051-496f84c1f641",
          "passphrase": "_5Hv3fdXrlvdWcay6iLWmQ",
          "secret": "BxXA2Sp3vXq2PvhN-Ub-5g0SUAzw4AC0HjDN2HxD1Vc"
        },
        "signature": "0x075ac1731df5cadd0f6cf73681e46d99650ecf1f86aef6cd941cc48e1474f7c470bc844f62e0f4c0db0db648b8789d670e67e173af342992477a2a333d8180cd1c"
      }*/

      const userKeys = {
        [address?.toLowerCase()]: {
          ...keys?.[address?.toLowerCase()],
          [clientAccountId]: {
            l2Key: l2KeyPair,
            apiKey: apiKeyPair,
          },
        },
      };

      setUser({ keys: userKeys });

      await onboardSite({
        address,
        appOnlySignOn: appOnlySignOn,
        param: "",
        signature: apiKeySignature,
        l2Key: l2KeyPair.publicKey,
        l2KeyYCoordinate: l2KeyPair?.publicKeyYCoordinate,
        clientAccountId,
      });
      // 触发事件
      window.dispatchEvent(new CustomEvent(CustomEvents["account-onboard"], { detail: { clientAccountId, hasRegistered: false } }));
      setGlobal({ disclaimerAndSignVisible: false, disclaimerAndSignStep: disclaimerAndSignStepEnum.unknown });
    } catch (error) {
      captureException(error);
      // eslint-disable-next-line no-console
      console.error(error);
      Toast.show(error?.message, { type: "error" });
    }
  };

  // 监听签名
  useEventListener(
    CustomEvents["mpc-sign"],
    (e) => {
      const forceAccountId = e.detail?.clientAccountId || "main";
      // signMessage(`name: ${appName}\nenvId: ${appEnv}\naction: L2 Key\nonlySignOn: ${appOnlySignOn}\nclientAccountId: ${forceAccountId}`)
      //   .then((l2KeySignature) =>
      //     signMessage(`action: ${appName} Onboard\nonlySignOn: ${appOnlySignOn}${apiName ? "\nparam: " + apiName : ""}`).then((apiKeySignature) => Promise.resolve([l2KeySignature, apiKeySignature])),
      //   )
      //   .then(([l2KeySignature, apiKeySignature]) =>
      //     mpcUserOnboardSite({ wallet: oldCurrentActiveAccount?.ethAddress || user?.wallet?.address, l2KeySignature, apiKeySignature, clientAccountId: forceAccountId }),
      //   );

      mpcUserOnboardSite({ address: oldCurrentActiveAccount?.ethAddress || user?.wallet?.address, clientAccountId: forceAccountId });
    },
    {
      target: window,
    },
  );
  // 监听签名
  useEventListener(
    CustomEvents["mpc-login"],
    async () => {
      await login();
    },
    {
      target: window,
    },
  );

  useEventListener(
    CustomEvents["mpc-sign-message"],
    async (e) => {
      let signature = "";
      try {
        signature = await signMessage(e.detail?.message);
      } catch (error) {
        captureException(error);
        // eslint-disable-next-line no-console
        console.error(error);
      }
      window.dispatchEvent(new CustomEvent(CustomEvents["mpc-sign-message-result"], { detail: { signature } }));
    },
    {
      target: window,
    },
  );
  const sign = async () => {
    setGlobal({ connectModalVisible: false });
    window.dispatchEvent(new CustomEvent(CustomEvents["wallet-connected"], { detail: { address: user?.wallet?.address, wallet: "mpc" } }));
  };

  useEffect(() => {
    if (authenticated && user?.wallet?.address && ready) {
      sign();
    }
  }, [authenticated, user?.wallet?.address, ready]);
  return <></>;
};

export default MpcHandler;
