/**
 * 数字输入，指定最大最小值，精度
 */
import { toPrecisionString, toTickSizeRoundString } from "@/utils/funcs";
import BigNumber from "bignumber.js";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { twMerge } from "tailwind-merge";

const NumberInput = ({ precision = 2, min = 0, max, step, className, value, onChange, onBlur, disableCorrect = false, ...props }) => {
  const correctValue = (value, min, max, precision, step, advanced = true) => {
    if (disableCorrect) return value;
    let result = value;

    // 第一部分：必要的数字纠正

    // 非数字小数点字符 替换
    result = result.replace(/[^\d.]/g, "").replace(/(\.\d+).*$/g, "$1");

    // 小数位过多纠正
    const valuePrecisionCorrected = (value + "").replace(new RegExp("(\\.\\d{" + precision + "}).+$", "g"), "$1");
    if (value != valuePrecisionCorrected) {
      result = valuePrecisionCorrected;
    }
    // 精度为 0 , 去掉末位小数点
    const valueTailingDotRemoved = (value + "").replace(/\.$/g, "");
    if (parseInt(precision, 10) === 0 && valueTailingDotRemoved != value) {
      result = valueTailingDotRemoved;
    }

    // 第二部分：只纠正第一部分的情况

    // 输入的值为 min 值字符串的前缀时，不纠正
    // 比如 min 为 0.01，输入 0.0 时，不纠正
    if (("" + min).indexOf("" + value) == 0 && advanced === false) {
      return result;
    }
    // min>= max 传值错误不纠正
    // if (new BigNumber(min).isGreaterThan(new BigNumber(max))) {
    //   return result;
    // }

    // 第三部分  min,max,step,precision 纠正
    if (!isNaN(max) && value && new BigNumber(value).isGreaterThan(new BigNumber(max))) {
      result = max;
      if (!isNaN(step) && BigNumber(result).isGreaterThan(toTickSizeRoundString(max, step, true))) {
        result = toTickSizeRoundString(max, step, true);
      }
    }
    if (!isNaN(min) && value && new BigNumber(value).isLessThan(new BigNumber(min)) && advanced === true) {
      result = min;
    }
    if (!isNaN(step) && result && advanced === true) {
      const roundedString = toTickSizeRoundString(result, step);
      if (roundedString !== result) {
        result = roundedString;
      }
    }
    // 精度补齐
    if (!isNaN(precision) && value && advanced === true) {
      const precisionString = toPrecisionString(result, precision);
      if (precisionString !== result) {
        result = precisionString;
      }
    }
    // console.log(disableCorrect, "value", value, "min", min, "max", max, "precision", precision, "step", step, "advanced", advanced, result);
    return result;
  };

  const blurCorrect = () => {
    const correctedValue = correctValue("" + (value || ""), min, max, precision, step, true);
    if ("" + correctedValue !== "" + value) {
      onChange({ target: { value: correctedValue } });
    }
  };

  const handleChange = (e) => {
    const value = e.target?.value;
    const correctedValue = correctValue("" + (value || ""), min, max, precision, step, false);
    if ("" + correctedValue !== "" + value) {
      return onChange({ target: { value: correctedValue } });
    }
    onChange(e);
  };

  useEffect(() => {
    const correctedValue = correctValue("" + (value || ""), min, max, precision, step, false);
    if ("" + correctedValue !== "" + value) {
      onChange({ target: { value: correctedValue } });
    }
  }, [value, precision, min, max, step]); // value precision min max 属性变化时，重新简单纠正

  return (
    <input
      type="text"
      min={min}
      max={max}
      value={value}
      onChange={handleChange}
      className={twMerge(
        "hide-button group-[]/join:input group-[]/join:input-ghost group-[]/join:input-sm group-[]/join:focus-within:outline-none group-[]/join:focus-within:border-transparent group-[]/join:focus-within:bg-transparent group-[]/join:w-full group-[]/join:px-0",
        className,
      )}
      onBlur={(e) => {
        blurCorrect();
        onBlur?.(e);
      }}
      {...props}
    />
  );
};

NumberInput.propTypes = {
  precision: PropTypes.any,
  min: PropTypes.any,
  max: PropTypes.any,
  value: PropTypes.any,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  step: PropTypes.string,
  disableCorrect: PropTypes.bool,
};

export default NumberInput;
