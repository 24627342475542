/**
 * 配置化菜单(后续可以根据后端接口渲染菜单)
 * @param
 */

import { isDev, isTestnet, isMainnet, winENV, currentENV } from "@/utils/env";
import { getENVItem } from "@/utils/deploy.envs";
import Icon from "@/components/Icon";
import logo from "@/icons/logo.svg";

export const GetHeadConfig = (metadata) => {
  const appName = metadata?.global?.appName;
  const appEnv = metadata?.global?.appEnv;
  const metadataDev = appEnv === "dev";
  const metadataMainnet = appEnv === "mainnet";
  // const metadataTestnet = appEnv === "testnet";
  const ENVDev = isDev || metadataDev || !currentENV; //dev && localhost
  const ENVTestMain = isTestnet || metadataMainnet || isMainnet || !currentENV; //Testnet && Mainnet && localhost
  // const ENVDevTestnet = isDev || isTestnet || metadataTestnet || !currentENV; //isDev && isTestnet && localhost
  const mainnetLink = getENVItem(appEnv).EXCHANGEPRO_FLAG_MAINNET_LINK || winENV?.MAINNET_LINK || "/";
  const testnetLink = getENVItem(appEnv).EXCHANGEPRO_FLAG_TESTNET_LINK || winENV?.TESTNET_LINK || "/";
  const curEnv = testnetLink.indexOf(window.location.host) != -1 ? "Testnet" : "Mainnet";

  // 首页URL dev testnet 环境没有部署首页
  const homeURL = {
    dev: "https://www.edgex.exchange",
    testnet: "https://www.edgex.exchange",
    mainnet: "https://www.edgex.exchange",
  };

  return {
    APP: {
      name: appName,
      key: appName,
      icon: <Icon icon={logo} className="h-7" />,
      target: false,
      link: homeURL[currentENV] || "/",
    },
    appMenu: [
      {
        title: isDev ? "Testnet" : curEnv,
        key: isDev ? "Testnet" : curEnv,
        target: true,
        link: "",
        weight: 999,
        sort: "",
        auth: true,
        tag: {},
        env: "",
        children: isDev
          ? []
          : [
              {
                label: "Mainnet",
                value: "mainnet",
                link: mainnetLink,
                target: true,
                env: "",
                auth: true,
              },
              {
                label: "Testnet",
                value: "testnet",
                link: testnetLink,
                target: true,
                env: "",
                auth: true,
              },
            ],
      },
      {
        title: "menu.Dashboard",
        key: "dashboard",
        target: false,
        link: "/dashboard",
        weight: 1,
        sort: "",
        auth: true,
        tag: {},
        env: "",
        children: [],
      },
      {
        title: "menu.trade",
        key: "trade",
        target: false,
        link: "/trade",
        weight: 1,
        sort: "",
        auth: true,
        tag: {},
        env: "",
        logEvent: "Web_Click_Trade_Navigation",
        children: [],
      },
      // {
      //   title: "NFT",
      //   key: "nft",
      //   target: false,
      //   link: "/nft",
      //   weight: 1,
      //   sort: "",
      //   auth: true,
      //   tag: {},
      //   env: "",
      //   logEvent: "",
      //   children: [],
      // },
      {
        title: "menu.faucet",
        key: "trade",
        target: false,
        link: "/faucet",
        weight: 1,
        sort: "",
        auth: ENVDev,
        tag: {},
        env: "",
        children: [],
      },
      {
        title: "menu.leaderboard",
        key: "leaderboard",
        target: false,
        link: "/leaderboard",
        weight: 1,
        sort: "",
        auth: ENVDev,
        tag: {},
        env: "",
        children: [],
      },
      {
        title: "menu.rewards",
        key: "trade",
        target: false,
        link: "",
        weight: 1,
        sort: "",
        auth: true,
        tag: {},
        env: "",
        children: [
          {
            label: "menu.referral",
            value: "referral",
            link: "/referral",
            target: false,
            env: "",
            auth: true,
          },
          {
            label: "menu.points",
            value: "points",
            link: "/points",
            target: false,
            env: "",
            auth: ENVDev,
          },
        ],
      },
      {
        title: "menu.more",
        key: "trade",
        target: false,
        link: "",
        weight: 1,
        sort: "",
        auth: true,
        tag: {},
        env: "",
        children: [
          {
            label: "menu.vip",
            value: "mainnet",
            link: `/vip`,
            target: false,
            env: "",
            auth: true,
          },
        ],
      },
    ],
    homePageMenu: [
      {
        title: "menu.about",
        target: false,
        link: "",
        weight: 2,
        sort: "",
        auth: true,
        tag: {},
        env: "",
        logEvent: "",
        children: [
          {
            label: "home.About.roject2",
            value: "home.About.roject2",
            link: "https://medium.com/@edgexexchange",
            auth: true,
            target: true,
            env: "",
          },
          // {
          //   label: "Desc",
          //   value: "Desc",
          //   link: "https://discord.gg/edgex",
          //   auth: true,
          //   target: true,
          //   logEvent: "Web_Click__Navigation_Discord_Community",
          //   env: "",
          // },
        ],
      },
      {
        title: "menu.Community",
        target: false,
        link: "",
        weight: 1,
        sort: "",
        auth: true,
        tag: {},
        env: "",
        logEvent: "Web_Click_Navigation_Community",
        children: [
          {
            label: "X",
            value: "X",
            link: "https://x.com/edgeX_exchange",
            auth: true,
            target: true,
            logEvent: "Web_Click_Navigation_X_Community",
            env: "",
          },
          {
            label: "Discord",
            value: "Discord",
            link: "https://discord.gg/edgex",
            auth: true,
            target: true,
            logEvent: "Web_Click__Navigation_Discord_Community",
            env: "",
          },
        ],
      },
      {
        title: "home.developer",
        target: false,
        link: "",
        weight: 1,
        sort: "",
        auth: true,
        tag: {},
        env: "",
        logEvent: "",
        children: [
          {
            label: "menu.Documentation",
            value: "menu.Documentation",
            link: "https://edgex-1.gitbook.io/edgex-documentation",
            auth: true,
            target: true,
            logEvent: "",
            env: "",
          },
        ],
      },
    ],
    // 右上角用户可操作配置
    userConfig: {
      switchLang: ENVDev,
      notification: true,
      appQrCode: ENVDev,
      settings: true,
      chains: true,
      account: true,
      download: false,
    },
  };
};
