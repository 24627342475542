import Form from "@/components/Form";
import NumberInput from "@/components/NumberInput";
import Select from "@/components/Select";
import Tooltip from "@/components/Tooltip";
import Trans from "@/components/Trans";
import TransitionStatus from "@/components/TransitionStatus";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { CustomEvents } from "@/constants/customEvents";
import { DEFAULT_TXT } from "@/constants/trade";
import ui from "@/constants/ui";
import useActiveChains from "@/hooks/useActiveChains";
import useTranslation from "@/hooks/useTranslation";
import JoinFormControl from "@/modules/trade/components/JoinFormControl";
import SpeedRating from "@/modules/trade/components/withdraw/SpeedRating";
import Summary from "@/modules/trade/components/withdraw/Summary";
import useWithdraw, { SPEED, mainChainList } from "@/modules/trade/components/withdraw/useWithdraw";
import useUserPrivateInfo from "@/modules/trade/hooks/useUserPrivateInfo";
import useInterface from "@/stores/interface";
import useUser from "@/stores/user";
import { useEventListener } from "ahooks";
import BigNumber from "bignumber.js";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { logEvent } from "@/utils/analytics";
import { arbitrumSepolia, arbitrum } from "viem/chains";
import useReconnect from "@/hooks/useReconnect";

// 输入框 6 位精度、展示位 2 位精度
const DEFAULT_DECIMAL = 2;
const getAbsoluteSymbol = (amount) => (Number(amount) < 0 ? "-$" : "$");
const toFormat = (num, decimal = DEFAULT_DECIMAL, option) => (option ? new BigNumber(num || 0).toFormat(decimal, option) : new BigNumber(num || 0).toFormat(decimal));

const DialogWithdraw = ({ children }) => {
  const {
    walletBalance,
    nextWalletBalance,
    totalCost,
    fee,
    withdrawInfoLoading,
    speed,
    tokenInfo,
    curSelectToken,
    curSelectChain,
    fast,
    withdrawAmount,
    setWithdrawAmount,
    setFast,
    setCurSelectToken,
    setCurSelectChain,
    handleWithdraw,
    dlgVisible,
    setDlgVisible,
    submitting,
    multicallFetchLoading,
    multicallFetchRun,
    withdrawAmountTooSmall,
    withdrawAmountTooLarge,
    isMainChain,
    currentActiveChainTokens,
    currentActiveChain,
    maxAvailable,
    loadingMaxAvailable,
    receiverAddress,
    setReceiverAddress,
    isArbitrum,
  } = useWithdraw();
  loadingMaxAvailable;

  const multiLanguage = useTranslation();
  let { chains } = useActiveChains();
  const { balance } = useUserPrivateInfo();
  const decimals = parseInt(tokenInfo?.decimals || 2, 10);
  const { metadata } = useInterface();
  const { currentActiveAccount } = useUser();
  // mpc 钱包提现 链只能选择 arbitrum
  if (currentActiveAccount?.wallet == "mpc") {
    chains = chains.filter((c) => [arbitrumSepolia.id, arbitrum.id].includes(+c.chainId));
  }

  useEventListener(
    CustomEvents["open-withdraw-dialog"],
    () => {
      setDlgVisible(true);
    },
    { target: window },
  );

  const reconnect = useReconnect();

  return (
    <Dialog
      open={dlgVisible}
      onOpenChange={(v) => {
        setDlgVisible(v);
      }}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        onOpenAutoFocus={async (e) => {
          setWithdrawAmount("");
          setReceiverAddress("");
          // open 时进行初始化
          if (chains?.length) {
            const newChainId = "" + (chains.find((c) => +c.chainId == +currentActiveChain?.chainId)?.chainId || chains?.[0]?.chainId);
            setCurSelectChain(newChainId);
            if (mainChainList.includes(+newChainId)) {
              setFast(true);
            }
          }
          e.preventDefault();
          await reconnect(); // 按需重连钱包
          await multicallFetchRun(); // 获取钱包余额以及 allowance
        }}
      >
        <DialogHeader>
          <DialogTitle>{multiLanguage("Withdraw")}</DialogTitle>
        </DialogHeader>
        <div className={twMerge(ui?.dialogScrollableBodyClassName, "text-sm")}>
          <label className="form-control">
            <div className="label">
              <span className="label-text inline-flex items-center gap-2">
                <div>{multiLanguage("To")}</div>
                <div className="text-brand py-px px-1 bg-background rounded text-xs">{multiLanguage("withdraw.tip1")}</div>
              </span>
              <Tooltip
                placement="top"
                title={
                  <div>
                    <div>{multiLanguage("withdraw.speedNote")}</div>
                    <div className="flex gap-1 items-center mt-1">
                      <SpeedRating bordered active={SPEED.FAST} len={3} />
                      <span>{multiLanguage("withdraw.speedNoteSpeed1")}</span>
                    </div>
                    <div className="flex gap-1 items-center mt-1">
                      <SpeedRating bordered active={SPEED.Medium} len={3} />
                      <span>{multiLanguage("withdraw.speedNoteSpeed2")}</span>
                    </div>
                    <div className="flex gap-1 items-center mt-1">
                      <SpeedRating bordered active={SPEED.SLOW} len={3} />
                      <span>{multiLanguage("withdraw.speedNoteSpeed3")}</span>
                    </div>
                  </div>
                }
              >
                <span className="label-text-alt bg-background rounded text-xs h-[19px] flex items-center px-2">
                  <SpeedRating active={speed || SPEED.NONE} len={3}>
                    <span className={ui?.helperClassName}>{multiLanguage("withdraw.Speed")}</span>
                  </SpeedRating>
                </span>
              </Tooltip>
            </div>
            <Select
              selectContentClassName="bg-background"
              className={" [&_.chain-speed]:hidden [&_.badge-last-used]:hidden [&_.item]:h-auto border-transparent px-2"}
              selectItemClassName={"px-1"}
              options={chains?.map((c) => ({
                label: (
                  <div className="item flex gap-2 h-11 items-center justify-between w-full">
                    <span className="inline-flex gap-2 text-secondary-content">
                      <img src={c.chainIconUrl} className="w-5" />
                      <span>{c.chain}</span>
                    </span>
                    <div className={"chain-speed"}>
                      {mainChainList.includes(+c.chainId) ? (
                        <div className="flex gap-0.5 flex-col text-xs">
                          <SpeedRating len={3} active={SPEED.FAST} bordered>
                            <span>{multiLanguage("withdraw.fastMode")}</span>
                          </SpeedRating>
                          <SpeedRating len={3} active={SPEED.SLOW} bordered>
                            <span>{multiLanguage("withdraw.normalMode")}</span>
                          </SpeedRating>
                        </div>
                      ) : (
                        <SpeedRating len={3} active={SPEED.Medium} bordered />
                      )}
                    </div>
                  </div>
                ),
                value: "" + c.chainId,
              }))}
              value={"" + curSelectChain}
              onChange={async (e) => {
                setCurSelectChain(e.target.value);
                logEvent("button", "click", "Web_Click_Withdraw_ChangeChain_btn", +e.target.value);
              }}
            ></Select>
            {isMainChain && (
              <Form className="px-2 py-2 mt-3 rounded text-sm bg-bg-secondary">
                <Form.Section>
                  <Form.Label className="inline-flex gap-1 items-center">
                    <div className="flex items-center gap-[6px]">
                      {/* <svg className={fast ? "text-brand" : "text-foreground-tertiary"} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                          d="M13.4869 7.38575C13.468 7.30561 13.4295 7.23139 13.375 7.16967C13.3205 7.10796 13.2516 7.06067 13.1744 7.032L9.57377 5.68137L10.49 1.09824C10.5108 0.991758 10.4963 0.881412 10.4489 0.783858C10.4014 0.686305 10.3235 0.606837 10.2269 0.557446C10.1303 0.508055 10.0202 0.491422 9.91337 0.510056C9.80649 0.528689 9.70858 0.58158 9.6344 0.660745L2.6344 8.16075C2.57754 8.22065 2.53641 8.29371 2.51469 8.37339C2.49296 8.45307 2.4913 8.53689 2.50987 8.61737C2.52845 8.69784 2.56666 8.77246 2.62111 8.83456C2.67556 8.89666 2.74454 8.94431 2.8219 8.97325L6.42377 10.3239L5.51002 14.902C5.48927 15.0085 5.50372 15.1188 5.55119 15.2164C5.59866 15.3139 5.67657 15.3934 5.77316 15.4428C5.86976 15.4922 5.97979 15.5088 6.08667 15.4902C6.19355 15.4716 6.29146 15.4187 6.36565 15.3395L13.3656 7.8395C13.4215 7.77958 13.4617 7.70686 13.4829 7.62774C13.504 7.54862 13.5054 7.46552 13.4869 7.38575Z"
                          fill="currentColor"
                        />
                      </svg> */}
                      <span className="text-secondary-content">{multiLanguage("Fast withdraw")}</span>
                    </div>
                  </Form.Label>
                  <Form.Control className="text-foreground inline-flex gap-2 items-center">
                    <span className="inline-flex items-center cursor-pointer">
                      <input id="remember" type="checkbox" value="" className="sr-only peer" onChange={(e) => setFast(e.target.checked)} checked={fast} />
                      <label
                        htmlFor="remember"
                        className="relative w-9 h-5 cursor-pointer bg-[--line-gray] rounded-full peer peer-checked:after:left-[18px] after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-[--font-gray] after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:after:bg-brand"
                      ></label>
                    </span>
                  </Form.Control>
                </Form.Section>
              </Form>
            )}
          </label>
          <label className="form-control mt-4">
            <div className="label">
              <span className="label-text inline-flex items-center gap-2">
                <div>{multiLanguage("Asset")}</div>
              </span>
            </div>
            <Select
              selectContentClassName="bg-background"
              className={" [&_.badge-token]:block [&_.balance]:hidden [&_.item]:h-auto border-transparent text-secondary-content [&_.trigger-hidden]:hidden px-2"}
              selectItemClassName={"px-1"}
              options={currentActiveChainTokens?.map((t) => ({
                label: (
                  <div className="item flex gap-2 h-11 items-center justify-between w-full">
                    <img src={t.iconUrl} className="w-5" />
                    <span>{t.token}</span>
                    {curSelectToken == t?.token ? <span className="text-xs bg-brand/5 h-5 leading-5 px-1.5 text-primary rounded trigger-hidden">{multiLanguage("Used")}</span> : null}
                    <div className="ml-auto trigger-hidden">
                      {multicallFetchLoading ? (
                        <span className="loading loading-xs loading-spin"></span>
                      ) : (
                        <>
                          <div className="text-xs leading-3 text-right">
                            {getAbsoluteSymbol(balance || "0")} {toFormat(Math.abs(balance || "0"), 2, BigNumber.ROUND_DOWN)}
                          </div>
                          <div className="text-xs leading-3 text-right text-[--foreground-tertiary]">{DEFAULT_TXT}</div>
                        </>
                      )}
                    </div>
                  </div>
                ),
                value: "" + t?.token,
              }))}
              value={curSelectToken}
              onChange={async (e) => {
                setCurSelectToken(e.target.value);
              }}
            ></Select>
            {/* <div className="h-9 px-5 leading-9 bg-background text-secondary-content rounded-xl mt-2 text-sm">
              <Tooltip title={multiLanguage("availableDesc")}>
                <span>
                  <span className={ui?.helperClassName}>{multiLanguage("maxWithdraw")}</span>: {getAbsoluteSymbol(balance || "0")}
                  {toFormat(Math.abs(balance || "0"), 2, BigNumber.ROUND_DOWN)}
                </span>
              </Tooltip>
            </div> */}
          </label>
          <label className="form-control mt-4">
            <div className="label">
              <span className="label-text inline-flex items-center gap-2">
                <div>{multiLanguage("Amount")}</div>
              </span>
            </div>
            <JoinFormControl
              className="bg-[rgba(255,255,255,0.04)] h-9 border-transparent px-2"
              suffixItems={[
                <span
                  className="text-foreground cursor-pointer px-2 rounded text-xs"
                  key="max"
                  onClick={() => {
                    const maxValue = BigNumber(maxAvailable?.[speed] || balance).toString();
                    setWithdrawAmount(maxValue);
                    logEvent("button", "click", "Web_Click_Withdraw_Max_btn", +maxValue);
                  }}
                >
                  {multiLanguage("MAX")}
                </span>,
              ]}
            >
              <NumberInput
                placeholder={"0." + new Array(decimals).fill(0).join("")}
                min={0}
                max={BigNumber.max(0, BigNumber(maxAvailable?.[speed] || balance)).toNumber()}
                value={withdrawAmount}
                precision={decimals}
                onChange={(e) => {
                  setWithdrawAmount(e.target.value);
                  logEvent("button", "click", "Web_Click_Withdraw_Openquickly_btn", +e.target.value);
                }}
              />
            </JoinFormControl>
            {/* <div className="mt-2 flex gap-1">
              {["25", "50", "75", "100"].map((v) => (
                <button
                  key={v}
                  className={twMerge(
                    "btn inline-flex flex-grow h-6 min-h-6 leading-6 text-center bg-secondary hover:bg-secondary text-foreground !rounded-[8px] !text-xs font-normal border border-solid border-transparent hover:border-transparent",
                    withdrawAmount &&
                      (balance || "0") != "0" &&
                      BigNumber(Math.abs(balance || "0") * (v / 100)).toFixed(decimals, BigNumber.ROUND_DOWN) == BigNumber(withdrawAmount).toFixed(decimals, BigNumber.ROUND_DOWN)
                      ? "box-border border border-solid border-primary text-primary hover:border-primary"
                      : "",
                  )}
                  onClick={() => {
                    setWithdrawAmount(BigNumber(Math.abs(balance || "0") * (v / 100)).toFixed(decimals, BigNumber.ROUND_DOWN));
                  }}
                >
                  {v}%
                </button>
              ))}
            </div> */}
            <div className="rounded bg-bg-secondary px-2 py-2 mt-2">
              <Form className={"text-sm"}>
                <Form.Section>
                  <Form.Label className="inline-flex gap-1 items-center">
                    <Tooltip title={multiLanguage("availableDesc")}>
                      <span className={ui?.helperClassName}>{multiLanguage("Available")}</span>
                    </Tooltip>
                    <span className="bg-bg-tertiary rounded text-xs  h-[19px] leading-[19px] px-[6px]">{tokenInfo?.token}</span>
                  </Form.Label>
                  <Form.Control>
                    <TransitionStatus
                      prevValue={<>{toFormat(maxAvailable?.[speed] || 0, decimals, BigNumber.ROUND_DOWN)}</>}
                      nextValue={<>{toFormat(Number(maxAvailable?.[speed] || 0) - Number(withdrawAmount || "0"), decimals, BigNumber.ROUND_DOWN)}</>}
                      forceTransitionStatus={Number(withdrawAmount || "") > 0}
                    />
                  </Form.Control>
                </Form.Section>
                {/* <Form.Section>
                  <Form.Label className="inline-flex gap-1 items-center">{multiLanguage("AccountLeverage")}</Form.Label>
                  <Form.Control className="text-foreground inline-flex gap-2 items-center">
                    <TransitionStatus suffix="x" prevValue={10} nextValue={1} />
                  </Form.Control>
                </Form.Section> */}
              </Form>
            </div>
          </label>
          {isArbitrum && currentActiveAccount?.wallet == "mpc" && (
            <label className="form-control mt-4">
              <div className="label">
                <span className="label-text inline-flex items-center gap-2">{multiLanguage("receiverAddress")}</span>
              </div>
              <JoinFormControl>
                <input value={receiverAddress} onChange={(e) => setReceiverAddress(e.target.value)} />
              </JoinFormControl>
            </label>
          )}
          {/* gasless desc */}
          {fast || !isMainChain ? (
            <>
              {fast && isMainChain && (
                <div className="  mt-3 text-foreground-tertiary text-xs">
                  <p className=" text-xs">{multiLanguage("Notice")}:</p>
                  <Trans i18nKey="fastNoticeTips">
                    • Non-Ethereum Withdrawals require verification at L2 (via ZK proofs) and the withdrawal process may
                    <span className="text-primary" style={{ display: "inline" }}>
                      take up to 4 hours
                    </span>
                    .
                    <br />
                    • Sufficient funds in the corresponding chain&#39;s asset pool are required to process Non-Ethereum Withdrawals.
                    <br />• Gas fees will also be incurred. System will charge a fee to cover this.
                  </Trans>
                  <br />
                  {/* 还没有Gitbook文档，先屏蔽 */}
                  {/* <a className="block mt-1 text-primary" target="_blank" href="" rel="noreferrer">{`${multiLanguage("KnowledgeWithdraw")}>`}</a> */}
                </div>
              )}
              {!isMainChain && (
                <div className="  mt-3 text-foreground-tertiary text-xs">
                  <p className=" text-xs">{multiLanguage("Notice")}:</p>
                  <Trans i18nKey="crossChainTips">
                    • Non-Ethereum Withdrawals require verification at L2 (via ZK proofs) and the withdrawal process may
                    <span className="text-primary">take up to 4 hours</span>
                    .
                    <br />
                    • Sufficient funds in the corresponding chain&#39;s asset pool are required to process Non-Ethereum Withdrawals.
                    <br />• Gas fees will also be incurred. System will charge a fee to cover this.
                  </Trans>
                  <br />
                  {/* 还没有Gitbook文档，先屏蔽 */}
                  {/* <a className="block mt-1 text-primary" target="_blank" href="" rel="noreferrer">{`${multiLanguage("KnowledgeCrossWithdraw")}>`}</a> */}
                </div>
              )}
            </>
          ) : (
            <div className="  mt-3 text-foreground-tertiary text-xs">
              <p className=" text-xs">{multiLanguage("Notice")}:</p>
              <Trans i18nKey="noticeDesc">
                • Non-Ethereum Withdrawals require verification at L2 (via ZK proofs) and the withdrawal process may
                <span className="text-primary" style={{ display: "inline" }}>
                  take up to 4 hours
                </span>
                .
                <br />
                • Sufficient funds in the corresponding chain&#39;s asset pool are required to process Non-Ethereum Withdrawals.
                <br />• Gas fees will also be incurred. System will charge a fee to cover this.
              </Trans>
            </div>
          )}
          <Summary walletBalance={walletBalance} nextWalletBalance={nextWalletBalance} fee={fee} totalCost={totalCost} />
        </div>
        <DialogFooter>
          <button
            disabled={
              withdrawInfoLoading ||
              (Number(withdrawAmount) || 0) <= 0 ||
              submitting ||
              !curSelectToken ||
              !curSelectChain ||
              multicallFetchLoading ||
              withdrawAmountTooSmall ||
              withdrawAmountTooLarge ||
              (isArbitrum && currentActiveAccount?.wallet == "mpc" && receiverAddress === "")
            }
            className={twMerge(" btn-primary  basis-full ", ui?.dialogFooterButtonClassName) + (submitting ? " pointer-events-none " : "")}
            onClick={() => {
              handleWithdraw();
              logEvent("button", "click", "Web_Click_Deposit_ConfirmDeposit_btn");
            }}
          >
            {withdrawAmountTooSmall
              ? multiLanguage("minWithdraw", {
                  amount: metadata?.multiChain?.minWithdraw,
                  token: tokenInfo?.token,
                })
              : withdrawAmountTooLarge
              ? multiLanguage("maxWithdrawToken", {
                  amount: metadata?.multiChain?.maxWithdraw,
                  token: tokenInfo?.token,
                })
              : multiLanguage("confirmWithdrawal")}
            {(withdrawInfoLoading || submitting) && <span className="loading loading-xs loading-spin"></span>}
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

DialogWithdraw.propTypes = {
  children: PropTypes.any,
};
export default DialogWithdraw;
