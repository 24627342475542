export const CustomEvents = {
  "open-disclaimer-dialog": "open-disclaimer-dialog",
  "close-disclaimer-dialog": "close-disclaimer-dialog",
  "open-sign-dialog": "open-sign-dialog",
  "close-sign-dialog": "close-sign-dialog",
  "set-disclaimer-sign-dialog": "set-disclaimer-sign-dialog",
  "open-order-detail": "open-order-detail",
  "open-settings-sheet": "open-settings-sheet",
  "open-wallet-connect-dialog": "open-wallet-connect-dialog",
  "close-wallet-connect-dialog": "close-wallet-connect-dialog",
  "set-wallet-connect-dialog": "set-wallet-connect-dialog",
  "open-close-position-dialog": "open-close-position-dialog",
  "ws-private-message": "ws-private-message",
  "ws-public-message": "ws-public-message",
  "ws-message": "ws-message",
  "ws-connect-error": "ws-connect-error",
  "account-onboard": "account-onboard",
  "wallet-connected": "wallet-connected",
  "wallet-disconnected": "wallet-disconnected",
  "subaccount-switch": "subaccount-switch",
  "mpc-sign": "mpc-sign",
  "mpc-sign-message": "mpc-sign-message",
  "mpc-sign-message-result": "mpc-sign-message-result",
  "mpc-login": "mpc-login",
  "open-transfer-out-dialog": "open-transfer-out-dialog",
  "open-transfer-account-out-dialog": "open-transfer-account-out-dialog",
  "open-deposit-dialog": "open-deposit-dialog",
  "open-withdraw-dialog": "open-withdraw-dialog",
  "account-change": "account-change",
};
