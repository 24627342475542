import Icon from "@/components/Icon";
import useTranslation from "@/hooks/useTranslation";
import disconnectIcon from "@/icons/disconnect.svg";
import useGlobal from "@/stores/global";
import useUser from "@/stores/user";
import { usePrivy } from "@privy-io/react-auth";
import { twMerge } from "tailwind-merge";
import { useDisconnect } from "wagmi";

const Logout = () => {
  const { resetUser } = useUser();
  const { setGlobal } = useGlobal();
  const { disconnect } = useDisconnect();
  const { logout } = usePrivy();
  const t = useTranslation();
  return (
    <a
      onClick={async () => {
        try {
          await logout();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("privy disconnect error", error);
        }
        try {
          await disconnect();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("wagmi disconnect error", error);
        }
        resetUser();
        setGlobal({ onboardingClientAccountId: "" });
        localStorage["privy:connections"] && delete localStorage["privy:connections"];
        localStorage["wagmi.store"] && delete localStorage["wagmi.store"];
      }}
      className={twMerge(``)}
    >
      <Icon icon={disconnectIcon} className="size-5" />
      <span>{t("menu.disconnect")}</span>
    </a>
  );
};

export default Logout;
