import Form from "@/components/Form";
import Icon from "@/components/Icon";
import NumberInput from "@/components/NumberInput";
import Tooltip from "@/components/Tooltip";
import useTranslation from "@/hooks/useTranslation";
import caret from "@/icons/caret.svg";
import arrowRight from "@/icons/line-right.svg";
import question from "@/icons/question.svg";
import useDepositHandler from "@/modules/trade/components/deposit/useDeposit";
import { useRequest } from "ahooks";
import axios from "axios";
import BigNumber from "bignumber.js";
import PropTypes from "prop-types";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

const TokenSettings = ({ slippage, setSlippage, activeToken }) => {
  const multiLanguage = useTranslation();
  const { currentActiveChain: chainInfo, tokenInfo, tokens } = useDepositHandler();
  const SLIPPAGE_TABS = ["0.25", "0.5", "1"];
  // const [slippage, setSlippage] = useState(SLIPPAGE_TABS[1]);
  const [customSlippage, setCustomSlippage] = useState(false);
  const [slippageDisplayStatus, setSlippageDisplayStatus] = useState(false);
  const [amount, setAmount] = useState("");

  const { loading: rateLoading } = useRequest(
    () => {
      setAmount("");
      if (!tokenInfo || !chainInfo || !chainInfo?.chainId) {
        return;
      }

      let fromTokenAddress = tokenInfo.tokenAddress;
      let toTokenAddress = tokens?.find((t) => t.isDefaultToken)?.tokenAddress;
      if (tokenInfo.token == chainInfo.gasToken) {
        fromTokenAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
      }
      if (fromTokenAddress == toTokenAddress) {
        return setAmount("1");
      }
      const to_decimals = tokens?.find((t) => t.isDefaultToken)?.decimals;
      return axios
        .get(
          `v1/public/1inch/getQuota?src=${tokenInfo.tokenAddress}&dst=${
            tokenInfo.token == chainInfo?.gasToken ? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" : tokens?.find((t) => t.isDefaultToken)?.tokenAddress
          }&amount=${Math.pow(10, to_decimals)}&compatibility=true&chainId=${chainInfo?.chainId}`,
        )
        .then((res) => {
          const newAmount = new BigNumber(res.data?.data?.toAmount).dividedBy(Math.pow(10, to_decimals)).toFixed();
          setAmount(newAmount);
        });
    },
    { refreshDeps: [tokenInfo, chainInfo] },
  );

  return (
    <Form className={"mt-2 rounded bg-background p-2 text-xs gap-3 [&>section]:min-h-[auto]"}>
      <Form.Section>
        <Form.Label className="inline-flex gap-2 items-center">
          <span>{multiLanguage("Swap")}</span>
          <Tooltip
            title={
              <div>
                <strong>{multiLanguage("Swap & Deposit")}</strong>
                <p>{multiLanguage("swap.desc")}</p>
              </div>
            }
            placement={"top"}
          >
            <span>
              <Icon icon={question} className=" w-4 cursor-help fill-[--foreground-tertiary]" />
            </span>
          </Tooltip>
        </Form.Label>
        <Form.Control>
          <div className="inline-flex gap-1">
            {activeToken}
            <Icon icon={arrowRight} className="w-4 fill-foreground" />
            {` ${tokenInfo?.token}`}
          </div>
        </Form.Control>
      </Form.Section>
      <Form.Section>
        <Form.Label className="inline-flex gap-2 items-center">
          <span>{multiLanguage("Rate")}</span>
        </Form.Label>
        <Form.Control>
          {rateLoading ? (
            <span className="loading loading-spinner loading-xs"></span>
          ) : (
            <p style={{ position: "relative" }}>
              1 {activeToken} ≈ {new BigNumber(amount || 0).toFixed(6)} {tokenInfo?.token}
            </p>
          )}
        </Form.Control>
      </Form.Section>
      <Form.Section>
        <Form.Label className="inline-flex gap-2 items-center">
          <span>{multiLanguage("MaxSlippage")}</span>
        </Form.Label>
        <Form.Control
          className="inline-flex gap-2 items-center justify-end cursor-pointer"
          onClick={() => {
            setSlippageDisplayStatus(!slippageDisplayStatus);
          }}
        >
          <span>{slippage}%</span>
          <Icon icon={caret} className={"fill-[--foreground-tertiary] w-3 " + (slippageDisplayStatus ? "rotate-180" : "")} />
        </Form.Control>
        {slippageDisplayStatus && (
          <div className="basis-full mt-3 flex gap-2">
            {/* <div className="leading-5 basis-full w-full">{multiLanguage("SlippageSetting")}</div> */}
            {SLIPPAGE_TABS.map((t) => (
              <button
                key={t}
                className={`button-slippage flex-1 h-7 border-solid rounded bg-[--join] text-foreground border box-border ${
                  t == slippage && !customSlippage ? "border-primary" : "border-transparent"
                }`}
                onClick={() => {
                  setSlippage(t);
                  setCustomSlippage(false);
                }}
              >
                {t}%
              </button>
            ))}
            <div className={twMerge("flex-1 h-7 inline-flex items-center border-transparent rounded box-border border bg-[--join]", customSlippage ? "border-primary" : "")}>
              <NumberInput
                className={"w-full outline-none bg-transparent px-2 text-foreground"}
                placeholder={multiLanguage("CustomSlippage")}
                min={0}
                max={100}
                value={customSlippage ? slippage : ""}
                onFocus={() => {
                  if (!customSlippage) {
                    setCustomSlippage(true);
                    setSlippage("");
                  }
                }}
                onChange={(e) => {
                  setSlippage(e.target.value);
                }}
              />
              <span key="p" className="px-2 text-foreground">
                %
              </span>
            </div>
          </div>
        )}
      </Form.Section>
    </Form>
  );
};

TokenSettings.propTypes = {
  slippage: PropTypes.string,
  setSlippage: PropTypes.func,
  activeToken: PropTypes.string,
};
export default TokenSettings;
