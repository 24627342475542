import Form from "@/components/Form";
import Icon from "@/components/Icon";
import NumberInput from "@/components/NumberInput";
import Select from "@/components/Select";
import Tooltip from "@/components/Tooltip";
import TransitionStatus from "@/components/TransitionStatus";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { CustomEvents } from "@/constants/customEvents";
import ui from "@/constants/ui";
import useReconnect from "@/hooks/useReconnect";
import useTranslation from "@/hooks/useTranslation";
import copy from "@/icons/copy.svg";
import JoinFormControl from "@/modules/trade/components/JoinFormControl";
import TokenSettings from "@/modules/trade/components/deposit/TokenSettings";
import useDeposit from "@/modules/trade/components/deposit/useDeposit";
import useUserPrivateInfo from "@/modules/trade/hooks/useUserPrivateInfo";
import useInterface from "@/stores/interface";
import useSymbol from "@/stores/symbol";
import useUser from "@/stores/user";
import { logEvent } from "@/utils/analytics";
import { shortStr, toThousandString } from "@/utils/funcs";
import { useEventListener } from "ahooks";
import BigNumber from "bignumber.js";
import PropTypes from "prop-types";
import CopyToClipboard from "react-copy-to-clipboard";
import { twMerge } from "tailwind-merge";
import { arbitrum, arbitrumSepolia } from "viem/chains";

const TOKEN = "$";

const DialogDeposit = ({ children }) => {
  const {
    unsupported,
    isPending,
    currentTokenBalanceWithQuotePrice,
    depositAmount,
    tokens,
    tokenInfo,
    curSelectToken,
    multicallFetchLoading,
    curSelectTokenData,
    approveLoading,
    approved,
    setDepositAmount,
    setCurSelectToken,
    callApprove,
    handleDeposit,
    switchChain,
    currentActiveChain,
    chains,
    dlgVisible,
    setDlgVisible,
    submitting,
    setSubmitting,
    depositAmountTooSmall,
    slippage,
    setSlippage,
    activeToken,
    multicallFetchRun,
  } = useDeposit();

  const t = useTranslation();
  const decimals = parseInt(tokenInfo?.decimals || 2, 10);
  // const maxBalance = BigNumber(curSelectTokenData?.balance?.formatted || 0).toFixed(decimals);
  const { totalAssets: currentEquity } = useUserPrivateInfo();
  const { metadata } = useInterface();

  const { toast: Toast } = useToast();
  const { currentActiveAccount } = useUser();
  const { getCurrentSymbol } = useSymbol();
  const symbol = getCurrentSymbol();

  useEventListener(
    CustomEvents["open-deposit-dialog"],
    () => {
      setDlgVisible(true);
    },
    { target: window },
  );

  const arbitrumChain = chains.find((c) => [arbitrumSepolia.id, arbitrum.id].includes(+c.chainId));

  const reconnect = useReconnect();

  return currentActiveAccount?.wallet == "mpc" ? (
    <Dialog
      open={dlgVisible}
      onOpenChange={(v) => {
        setDlgVisible(v);
      }}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>{t("Deposit")}</DialogTitle>
        </DialogHeader>
        <div className={twMerge(ui?.dialogScrollableBodyClassName, "text-sm")}>
          <label className="form-control">
            <div className="label pt-0 pb-3">
              <span className="label-text inline-flex items-center gap-2">
                <div>{t("From")}</div>
              </span>
            </div>
            <JoinFormControl className="gap-2" prefixItems={[<img key="icon" src={arbitrumChain?.chainIconUrl} className="w-5" />]}>
              <input readOnly className="text-foreground" value={arbitrumChain?.chain} />
            </JoinFormControl>
          </label>
          <label className="form-control mt-4">
            <div className="label pt-0 pb-3">
              <span className="label-text inline-flex items-center gap-2">
                <div>{t("Asset")}</div>
              </span>
            </div>
            <JoinFormControl className="gap-2" prefixItems={[<img key="icon" src={arbitrumChain?.tokenList?.find((t) => t.token == "USDT")?.iconUrl} className="w-5" />]}>
              <input readOnly className="text-foreground" value={"USDT"} />
            </JoinFormControl>
          </label>
          <div className="text-sm font-medium text-foreground mt-8">{t("important")}</div>
          <div className="mb-8">
            {t("embeddedWalletTips", {
              minDeposit: metadata?.multiChain?.minDeposit,
              quoteCoin: symbol?.quoteCoin,
            })}
          </div>
          <JoinFormControl
            suffixItems={[
              <CopyToClipboard
                key="copy"
                onCopy={() => {
                  Toast.show(t("copySuccess"));
                }}
                text={currentActiveAccount?.ethAddress}
              >
                <Icon icon={copy} className="w-3 cursor-pointer text-foreground" />
              </CopyToClipboard>,
            ]}
          >
            <input readOnly className="text-foreground" value={shortStr(currentActiveAccount?.ethAddress, 34)} />
          </JoinFormControl>
        </div>
        <DialogFooter>
          <button
            className={twMerge(" btn-primary basis-full ", ui?.dialogFooterButtonClassName)}
            onClick={() => {
              setDlgVisible(false);
            }}
          >
            {t("done")}
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  ) : (
    <Dialog
      open={dlgVisible}
      onOpenChange={(v) => {
        setDlgVisible(v);
        setSubmitting(false);
      }}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        onOpenAutoFocus={async (e) => {
          setDepositAmount("");

          if (!curSelectToken && tokens?.length > 0) {
            setCurSelectToken(tokens[0]?.token);
          }
          e.preventDefault();
          await reconnect(); // 按需重连钱包
          await multicallFetchRun(); // 获取钱包余额以及 allowance
        }}
      >
        <DialogHeader>
          <DialogTitle>{t("Deposit")}</DialogTitle>
        </DialogHeader>
        <div className={twMerge(ui?.dialogScrollableBodyClassName, "text-sm")}>
          <label className="form-control">
            <div className="label pt-0 pb-3">
              <span className="label-text inline-flex items-center gap-2">
                <div>{t("From")}</div>
                <div className="text-brand py-px px-2 bg-background rounded text-xs">{t("withdraw.tip1")}</div>
              </span>
            </div>
            <Select
              selectItemClassName="px-1"
              selectContentClassName="bg-background"
              options={chains?.map((c) => ({
                label: (
                  <div className="inline-flex gap-2 items-center">
                    <img src={c.chainIconUrl} className="w-5" />
                    <span>{c.chain}</span>
                    {c.chainId == "lastChainId" && <span className="badge badge-primary badge-outline badge-xs badge-last-used">{t("lastUsed")}</span>}
                  </div>
                ),
                value: "" + c.chainId,
              }))}
              disabled={isPending}
              value={"" + (currentActiveChain?.chainId || "")}
              className={"icon-select-trigger text-secondary-content [&_.badge-last-used]:hidden  px-2"}
              onChange={async (e) => {
                switchChain({ chainId: +e.target.value });
                logEvent("button", "click", "Web_Click_Deposit_ChangeChain_btn", +e.target.value);
              }}
            ></Select>
          </label>
          <label className="form-control mt-4">
            <div className="label pt-0 pb-3">
              <span className="label-text inline-flex items-center gap-2">
                <div>{t("Asset")}</div>
              </span>
            </div>
            <Select
              selectContentClassName="bg-background"
              options={tokens?.map((t) => ({
                label: (
                  <div className="w-full flex items-center justify-between">
                    <div className="inline-flex gap-2 items-center">
                      <img src={t.iconUrl} className="w-5" />
                      <span>{t.token}</span>
                      <span className="bg-bg-tertiary rounded-sm text-xs hidden badge-token h-[19px] leading-[19px] px-[6px] py-0">{t.token}</span>
                    </div>
                    {curSelectToken === "" + t.token && (
                      <svg className="check-mark" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M3 8.24289L6.37868 11.6216L13 5.00024" stroke="#00F18C" strokeWidth="1.5" strokeLinejoin="round" />
                      </svg>
                    )}
                  </div>
                ),
                value: "" + t.token,
              }))}
              value={"" + curSelectToken}
              selectItemClassName="px-1"
              className={"icon-select-trigger text-secondary-content [&_.badge-token]:block [&_.check-mark]:hidden px-2"}
              onChange={async (e) => {
                setCurSelectToken(e.target.value);
                logEvent("button", "click", "Web_Click_Deposit_ChangeToken_btn", e.target.value);
              }}
            ></Select>
            {tokenInfo?.isDefaultToken ? (
              <div className="flex gap-2 mt-2 text-secondary-content text-[14px]">
                {tokens?.map((item) => (
                  <div
                    className="h-9 basis-1/3 rounded text-xs bg-background cursor-pointer inline-flex gap-2 items-center justify-center"
                    onClick={() => {
                      setCurSelectToken(item.token);
                    }}
                    key={item.tokenAddress}
                  >
                    <img src={item.iconUrl} className="w-3" />
                    <span>{item.token}</span>
                  </div>
                ))}
              </div>
            ) : (
              <TokenSettings slippage={slippage} setSlippage={setSlippage} activeToken={activeToken} />
            )}
          </label>

          <label className="form-control mt-4">
            <div className="label  pt-0 pb-3">
              <span className="label-text inline-flex items-center gap-2">
                <div>{t("Amount")}</div>
              </span>
            </div>
            <JoinFormControl
              className="px-2 h-9"
              suffixItems={[
                <span
                  className="text-foreground cursor-pointer h-6 leading-6 text-xs"
                  key="max"
                  onClick={() => {
                    setDepositAmount(curSelectTokenData?.balance?.formatted);
                    logEvent("button", "click", "Web_Click_Deposit_Max_btn", curSelectTokenData?.balance?.formatted);
                  }}
                >
                  {t("MAX")}
                </span>,
              ]}
            >
              <NumberInput
                placeholder={"0." + new Array(decimals).fill(0).join("")}
                value={depositAmount}
                min={0}
                max={curSelectTokenData?.balance?.formatted}
                precision={decimals}
                onChange={(e) => {
                  setDepositAmount(e.target.value);
                  logEvent("input", "change", "Web_Click_Deposit_EnterNumber_btn", e.target.value);
                }}
              />
            </JoinFormControl>
            <JoinFormControl
              className="mt-2 h-9 px-2"
              suffixItems={[
                <span key="v" className="text-foreground">
                  <TransitionStatus
                    prevValue={toThousandString(curSelectTokenData?.balance?.formatted, decimals)}
                    nextValue={BigNumber(curSelectTokenData?.balance?.formatted || 0)
                      .minus(depositAmount || 0)
                      .toFixed(decimals)}
                    forceTransitionStatus={!!depositAmount}
                  />
                </span>,
              ]}
            >
              <div className="inline-flex gap-2 items-center">
                <Tooltip title={t("availableDesc")}>
                  <span className={ui?.helperClassName}>{t("Available")}</span>
                </Tooltip>
                <span className="bg-bg-tertiary rounded-sm text-xs  h-[19px] leading-[19px] px-[6px]">{tokenInfo?.token}</span>
              </div>
            </JoinFormControl>
          </label>

          {/* enable token */}
          {!approved && BigNumber(depositAmount).gt(0) ? (
            <div className="bg-background rounded flex flex-col gap-3 mt-4 pt-3 px-2 pb-2">
              <h2 className="enable-token-title text-sm">
                {t("enableSystemProWithToken", {
                  token: tokenInfo?.token || "",
                  network: currentActiveChain?.chain,
                })}
              </h2>
              <p className="enable-token-desc text-xs">
                {t("enableTokenDesc", {
                  token: tokenInfo?.token || "",
                  network: currentActiveChain?.chain,
                })}
              </p>
              <button
                disabled={approveLoading || multicallFetchLoading}
                className="btn btn-primary justify-between items-center gap-2 text-foreground h-5 min-h-5 px-2 leading-none !rounded w-fit self-center !font-normal !text-xs border-brand"
                onClick={() => {
                  callApprove();
                  logEvent("button", "click", "Web_Click_Deposit_Approve_btn");
                }}
              >
                {approveLoading || multicallFetchLoading ? (
                  <span className="loading loading-xs loading-spin"></span>
                ) : (
                  <span>
                    {t("enableWithToken", {
                      token: tokenInfo?.token || "",
                    })}
                  </span>
                )}
              </button>
            </div>
          ) : (
            ""
          )}

          {/* 到账金额 */}
          <Form className="mt-4 text-sm leading-none gap-3">
            {tokenInfo?.isDefaultToken && (
              <Form.Section>
                <Form.Label className="inline-flex gap-2 items-center">
                  <span>{t("Deposit")}</span>
                  <span className="bg-bg-tertiary rounded-sm h-[19px] leading-[19px] px-[6px] text-xs">{tokenInfo?.token}</span>
                </Form.Label>
                <Form.Control>
                  <div className="flex flex-row items-center gap-1">
                    {"≈ "}
                    <TransitionStatus prefix={TOKEN} prevValue={currentTokenBalanceWithQuotePrice} nextValue={0} />
                  </div>
                </Form.Control>
              </Form.Section>
            )}
            <Form.Section>
              <Form.Label className="inline-flex gap-2 items-center">
                <span>{t("Equity")}</span>
                <span className="bg-bg-tertiary rounded-sm h-[19px] leading-[19px] px-[6px] text-xs">{tokenInfo?.token}</span>
              </Form.Label>
              <Form.Control>
                <div className="flex flex-row items-center gap-1">
                  {"≈ "}
                  <TransitionStatus
                    prefix={TOKEN}
                    prevValue={toThousandString(currentEquity, decimals)}
                    forceTransitionStatus={!!depositAmount}
                    nextValue={toThousandString(BigNumber(currentEquity).plus(depositAmount), decimals)}
                  />
                </div>
              </Form.Control>
            </Form.Section>
          </Form>
        </div>
        <DialogFooter>
          <button
            disabled={!approved || multicallFetchLoading || approveLoading || isPending || !depositAmount || Number(depositAmount) <= 0 || depositAmountTooSmall}
            className={twMerge(" btn-primary basis-full ", ui?.dialogFooterButtonClassName) + (submitting ? " pointer-events-none " : "")}
            onClick={() => {
              handleDeposit();
              logEvent("button", "click", "Web_Click_Deposit_ConfirmDeposit_btn");
            }}
          >
            {unsupported
              ? t("switchNetwork")
              : depositAmountTooSmall
              ? t("minDeposit", {
                  token: tokenInfo?.token,
                  amount: metadata?.multiChain?.minDeposit,
                })
              : t("confirmDeposit")}
            {(submitting || multicallFetchLoading) && <span className="loading loading-xs loading-spin "></span>}
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

DialogDeposit.propTypes = {
  children: PropTypes.node,
};

export default DialogDeposit;
