import languages from "@/constants/languages";
import ui from "@/constants/ui";
import { disclaimerAndSignStepEnum } from "@/constants/wallets";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const persistFields = ["lang"];

let lang = new URL(location.href).searchParams.get("lang") || ui?.defaultLanguage || "en-US";
lang = languages.filter((row) => ui?.enabledLanguages?.includes(row.lang)).find((l) => l.lang == lang) ? lang : ui?.defaultLanguage || "en-US";

const useGlobal = create(
  persist(
    (set) => ({
      disclaimerAndSignVisible: false, // 免责声明弹窗
      disclaimerAndSignStep: disclaimerAndSignStepEnum.unknown, // 1-recover, 2-免责声明，其他loading
      onboardingClientAccountId: "", // 当前激活的账户
      connectModalVisible: false, // 是否展示钱包链接弹窗
      localChains: undefined, // 未链接钱包时候 本地切换链
      lang, // 当前语言 形如 en-US
      initialTitle: document.title, // 当前页面标题
      remoteMessages: {}, // 远程语言包
      network: true, // 网络状况
      setGlobal: (newValues) => set((state) => ({ ...state, ...newValues })),
    }),
    {
      name: "global",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => {
        const keys = Object.keys(state);
        return keys.reduce((prev, next) => {
          if (persistFields.includes(next)) {
            return { ...prev, [next]: state[next] };
          }
          return prev;
        }, {});
      },
    },
  ),
);

export default useGlobal;
