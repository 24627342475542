import React from "react";
import { Toaster } from "@/components/ui/toaster";
import Header from "@/modules/layout/components/Header";
import { Outlet } from "react-router";
import { useTitle } from "ahooks";
function HomeLayout() {
  useTitle("edgeX - High-performance, orderbook-based perpetual DEX with native trading experience.");
  return (
    <>
      <div className="flex-1 flex flex-col">
        <Header />
        <div className="min-h-[calc(100vh-6rem)]">
          <Outlet />
        </div>
      </div>
      <Toaster />
    </>
  );
}

export default HomeLayout;
