
/**
 * isDev
 * @param
 */
export const isDev = window?.ENV?.ENV === "dev";

/**
 * isTestnet
 * @param
 */
export const isTestnet = window?.ENV?.ENV === "testnet";

/**
 * isMainnet
 * @param
 */
export const isMainnet = window?.ENV?.ENV === "mainnet";

/**
 * 当前环境
 * @param
 */
export const currentENV = window?.ENV?.ENV;

/**
 * 当前所有环境变量
 * @param
 */
export const winENV = window?.ENV;
