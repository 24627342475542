import { toPrecisionString } from "@/utils/funcs";
import BigNumber from "bignumber.js";

/**
 * position transaction item 计算
 * @param {object} item positionTransaction item
 * @param {Array<Object>} symbolsList like [{symbol:"BTCUSDT"}]
 * @returns {object} decorated item
 */
export const calculatePositionTransaction = (item, symbolsList) => {
  const symbolInfo = symbolsList.find((s) => s.contractId == item.contractId);
  // 入场价格：beforeOpenValue / beforeOpenSize , 按照 contract.tickSize 取整。注意取整规则，卖出平多：向上取整；买入平空：向下取整
  const entryPrice = toPrecisionString(BigNumber(item.deltaOpenValue).minus(item.fillOpenValue).dividedBy(item.fillCloseSize), symbolInfo.pricePrecision);
  // 出场价格：fillCloseValue / fillCloseSize, 按照 contract.tickSize 取整。注意取整规则，卖出平多：向下取整；买入平空：向上取整
  const exitPrice = toPrecisionString(BigNumber(item.fillPrice), symbolInfo.pricePrecision);
  // 平仓价值：abs(fillCloseValue)
  const orderCloseValue = BigNumber(Math.abs(item.fillCloseValue) || 0);
  // 开仓价值：beforeOpenValue x abs(fillCloseSize) / beforeOpenSize
  const orderOpenValue = BigNumber(item.beforeOpenValue).multipliedBy(item.fillCloseSize).abs().dividedBy(item.beforeOpenSize).abs();
  // 手续费：beforeOpenFee x abs(fillCloseSize) / abs(beforeOpenSize) + fillCloseFee
  const orderTradingFee = BigNumber(item.beforeOpenFee).multipliedBy(item.fillCloseSize).abs().dividedBy(item.beforeOpenSize).plus(item.fillCloseFee);
  // 资金费：beforeFundingFee x abs(fillCloseSize) / abs(beforeOpenSize)
  const orderFundingFee = BigNumber(item.beforeFundingFee).multipliedBy(item.fillCloseSize).abs().dividedBy(item.beforeOpenSize);

  /*
        Long Position
        Realized P&L = Close Value - Open Value - Trading Fees + Funding Fees

        Short Position
        Realized P&L = Open Value - Close Value - Trading Fees + Funding Fees
         */
  const totalPnl =
    Number(item.fillCloseSize) <= 0
      ? orderCloseValue.minus(orderOpenValue).minus(orderTradingFee).plus(orderFundingFee)
      : orderOpenValue.minus(orderCloseValue).minus(orderTradingFee).plus(orderFundingFee);

  return {
    ...symbolInfo,
    ...item,
    // 交易类型：fillCloseSize >0 买入/long；fillCloseSize < 0 卖出/short
    side: Number(item.fillCloseSize) > 0 ? "BUY" : "SELL",
    entryPrice,
    exitPrice,
    orderCloseValue,
    orderOpenValue,
    orderTradingFee,
    orderFundingFee,
    totalPnl,
  };
};
