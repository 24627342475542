import Form from "@/components/Form";
import Icon from "@/components/Icon";
import Tooltip from "@/components/Tooltip";
import TransitionStatus from "@/components/TransitionStatus";
import useActiveChains from "@/hooks/useActiveChains";
import useTranslation from "@/hooks/useTranslation";
import question from "@/icons/question.svg";
import useWithdraw, { mainChainList } from "@/modules/trade/components/withdraw/useWithdraw";
import { toPrecisionStringWithType, toThousandString } from "@/utils/funcs";
import BigNumber from "bignumber.js";
import PropTypes from "prop-types";
import { useMemo } from "react";

// 输入框6位精度、展示位2位精度
const DEFAULT_DECIMAL = 2;
// const getAbsoluteSymbol = (amount) => (Number(amount) < 0 ? "-$" : "$");
const toFormat = (num, decimal = DEFAULT_DECIMAL, option) => (option ? new BigNumber(num || 0).toFormat(decimal, option) : new BigNumber(num || 0).toFormat(decimal));

const Summary = ({ walletBalance, nextWalletBalance, fee, totalCost }) => {
  const multiLanguage = useTranslation();
  const { currentActiveChain } = useActiveChains();
  const { curSelectChain, tokenInfo, fast, withdrawAmount } = useWithdraw();
  const isMainChain = useMemo(() => mainChainList.includes(+curSelectChain), [curSelectChain]);

  return (
    <Form className="mt-4 text-sm leading-none gap-3">
      {(fast || !isMainChain) && (
        <>
          {fee != 0 && (
            <Form.Section>
              <Form.Label className="inline-flex gap-1 items-center">
                <span>{multiLanguage("Fee")}</span>
                <Tooltip
                  placement={"top"}
                  title={
                    <div>
                      <strong>{isMainChain ? multiLanguage("AssetActionFee.FastWithdrawal") : multiLanguage("AssetActionFee.Non-EthereumWithdrawal")}</strong>
                      <p>
                        {isMainChain
                          ? currentActiveChain?.dynamicFee
                            ? multiLanguage("AssetActionFee.descrFastWithdrawal1WithAssets", {
                                min_fee: currentActiveChain?.minFee,
                                coin: tokenInfo?.token,
                              })
                            : multiLanguage("AssetActionFee.descrNon-EthereumWithdrawalWithAssets", {
                                coin: tokenInfo?.token,
                                min_fee: currentActiveChain?.minFee,
                                threshold: currentActiveChain?.threshold || 10000,
                                actual_charge: toFormat(Math.abs((withdrawAmount || 0) * currentActiveChain?.feeRate), 2, BigNumber.ROUND_DOWN),
                              })
                          : multiLanguage("AssetActionFee.descrNon-EthereumWithdrawalWithAssets", {
                              coin: tokenInfo?.token,
                              min_fee: currentActiveChain?.minFee,
                              threshold: currentActiveChain?.threshold || 10000,
                              actual_charge: toFormat(Math.abs((withdrawAmount || 0) * currentActiveChain?.feeRate), 2, BigNumber.ROUND_DOWN),
                            })}
                      </p>
                    </div>
                  }
                >
                  <span>
                    <Icon icon={question} className="fill-current w-4" />
                  </span>
                </Tooltip>
              </Form.Label>
              <Form.Control>${toFormat(fee)}</Form.Control>
            </Form.Section>
          )}
          <Form.Section>
            <Form.Label className="inline-flex gap-1 items-center">
              <span>{multiLanguage("Total")}</span>
            </Form.Label>
            <Form.Control>${toThousandString(toPrecisionStringWithType(totalCost, 2, "floor"))}</Form.Control>
          </Form.Section>
        </>
      )}
      <Form.Section>
        <Form.Label className="inline-flex gap-1 items-center">
          <span>{multiLanguage("Wallet")}</span>
          <span className="bg-bg-tertiary rounded-sm text-xs  h-[19px] leading-[19px] px-[6px]">{tokenInfo?.token}</span>
        </Form.Label>
        <Form.Control className="text-foreground inline-flex gap-2 items-center justify-end">
          <TransitionStatus prefix="$" prevValue={toFormat(walletBalance)} nextValue={toFormat(nextWalletBalance)} forceTransitionStatus={!!nextWalletBalance} />
        </Form.Control>
      </Form.Section>
    </Form>
  );
};

Summary.propTypes = {
  walletBalance: PropTypes.any,
  nextWalletBalance: PropTypes.any,
  fee: PropTypes.any,
  totalCost: PropTypes.string,
};

export default Summary;
