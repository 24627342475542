/**
 * 抵押品相关的计算
 */

import { getMaxLeverage } from "@/components/calculator/account";
import { findRiskTier } from "@/components/calculator/helper";
import { calculateCloseOrderFrozenAmount, calculateOpenOrderFrozenAmount, calculateOrderFillData, sortOrder } from "@/components/calculator/order";
import { ENUM_TRANSFER_STATUS, ENUM_WITHDRAW_STATUS } from "@/constants/trade";
import BigNumber from "bignumber.js";

/**
 * 计算保证金额相关字段
 * @param {object} param parameter object
 * @param {string} param.contractId like "1000001"
 * @param {string} param.quoteCoinId like "1001" USDT/USDC
 * @param {object} param.account like {contractIdToTradeSetting: {},defaultTradeSetting:{}},
 * @param {object} param.metadata like {contractList:[]},
 * @param {Array<object>} param.position like [{contractId: "1000001"}]
 * @param {Array<object>} param.symbolsList like [{contractId: "1000001",symbol:"BTCUSDT"}]
 * @param {Array<object>} param.withdraw 提现单列表
 * @param {Array<object>} param.transferOut 转出单列表
 * @param {Array<object>} param.collateral 抵押品列表
 * @param {Array<object>} options.order 订单/委托单列表
 * @returns {object}  like{ totalEquity, totalInitialMarginRequirement, totalStarkExRiskValue, totalPendingWithdrawAmount, totalPendingTransferOutAmount, } */
export const getCollateralInfo = ({ quoteCoinId, position, symbolsList, metadata, account, withdraw, transferOut, collateral, order }) => {
  const currentCollateral = collateral.find((c) => c.coinId === quoteCoinId);

  // (当前抵押品总初始保证金)
  // Collateral.totalInitialMarginRequirement = sum ( Position.initialMarginRequirement ) 当前抵押品所有仓位初始保证金之和
  let totalInitialMarginRequirement = "0";
  // (当前抵押品整体权益)
  // Collateral.totalEquity = Collateral.amount + sum ( Position.value ) 当前抵押品所有仓位价值之和
  let totalEquity = BigNumber(currentCollateral?.amount || "0");
  // (当前抵押品总维持保证金)
  // Collateral.totalStarkExRiskValue = sum ( Position.starkExRiskValue ) 当前抵押品所有仓位维持保证金之和
  let totalStarkExRiskValue = "0";

  position
    ?.filter((p) => p.coinId == quoteCoinId)
    ?.forEach((i) => {
      const symbolInfo = symbolsList.find((s) => s.contractId == i.contractId);
      const value = BigNumber(i?.openSize).multipliedBy(symbolInfo?.oraclePrice)?.toString();
      const absValue = Math.abs(value);
      const curAccountMaxLeverage = getMaxLeverage({ contractId: i?.contractId, metadata, account });
      const riskTierList = symbolInfo?.riskTierList;
      const currentTier = findRiskTier(absValue, riskTierList);
      const actualLeverage = BigNumber.min(curAccountMaxLeverage, currentTier?.maxLeverage).toString();
      const initialMarginRate = BigNumber(1).div(actualLeverage).toFixed(6, BigNumber.ROUND_FLOOR);
      const initialMarginRequirement = BigNumber(absValue).multipliedBy(initialMarginRate);
      const starkExRiskRate = BigNumber(currentTier?.starkExRisk).div(BigNumber(2).pow(32));
      const starkExRiskValue = BigNumber(absValue).multipliedBy(starkExRiskRate).toString();
      totalInitialMarginRequirement = BigNumber(totalInitialMarginRequirement).plus(initialMarginRequirement);
      totalEquity = BigNumber(totalEquity).plus(value);
      totalStarkExRiskValue = BigNumber(totalStarkExRiskValue).plus(starkExRiskValue);
    });

  // 抵押品所有处理中的提现金额
  // Collateral.totalPendingTransferOutAmount = sum ( TransferOut.amount ) 当前抵押品对应正在处理的转出单 (status=PENDING_XXX) 所有金额之和
  const totalPendingWithdrawAmount = withdraw
    .filter((w) => w.coinId == quoteCoinId && w.status == ENUM_WITHDRAW_STATUS.PENDING_CENSORING)
    .reduce((acc, cur) => {
      return acc.plus(cur.amount);
    }, BigNumber(0));

  // 抵押品所有处理中的转出金额
  //  * Collateral.totalPendingTransferOutAmount = sum ( TransferOut.amount ) 当前抵押品对应正在处理的转出单 (status=PENDING_XXX) 所有金额之和
  const totalPendingTransferOutAmount = transferOut
    .filter((t) => (t.coinId == quoteCoinId && t.status == ENUM_TRANSFER_STATUS.PENDING_CENSORING) || t.status == ENUM_TRANSFER_STATUS.PENDING_CHECKING)
    .reduce((acc, cur) => {
      return acc.plus(cur.amount);
    }, BigNumber(0));

  // 计算所有订单冻结金额
  let totalOrderFrozenAmount = BigNumber(0);

  const sortedOrder = sortOrder(order.filter((o) => o.status != "UNTRIGGERED"));

  let tmpContract = null;
  let tmpOrderSide = "";
  let tmpPositionOpenSize = BigNumber(0);
  let tmpOrderFrozenAmount = BigNumber(0);

  sortedOrder.forEach((tmpOrder) => {
    if (tmpOrder.contractId != tmpContract?.contractId || tmpOrder.side != tmpOrderSide) {
      tmpContract = symbolsList.find((s) => s.contractId == tmpOrder.contractId);
      tmpOrderSide = tmpOrder.side;
      totalOrderFrozenAmount = totalOrderFrozenAmount.plus(BigNumber.max(tmpOrderFrozenAmount, 0));
      tmpOrderFrozenAmount = BigNumber(0);
      const contractPosition = position.find((p) => p.contractId == tmpOrder.contractId);
      tmpPositionOpenSize = BigNumber(contractPosition?.openSize || "0");
    }
    const tmpOrderInitialMarginRate = BigNumber(1).div(tmpOrder.maxLeverage).toFixed(6, BigNumber.ROUND_FLOOR);
    const tmpOrderFeeRate = Math.max(tmpOrder.takerFeeRate, tmpOrder.makerFeeRate);
    const { closeSize, closeValue, openSize, openValue } = calculateOrderFillData({ contract: tmpContract, order: tmpOrder, positionOpenSize: tmpPositionOpenSize });

    tmpOrderFrozenAmount = tmpOrderFrozenAmount
      .plus(calculateCloseOrderFrozenAmount(tmpContract.oraclePrice, tmpOrderInitialMarginRate, closeSize, closeValue, tmpOrderFeeRate))
      .plus(calculateOpenOrderFrozenAmount(tmpContract.oraclePrice, tmpOrderInitialMarginRate, openSize, openValue, tmpOrderFeeRate));

    tmpPositionOpenSize = tmpPositionOpenSize.plus(closeSize).plus(openSize);
  });
  totalOrderFrozenAmount = totalOrderFrozenAmount.plus(BigNumber.max(tmpOrderFrozenAmount, 0));

  return {
    totalEquity,
    totalInitialMarginRequirement,
    totalStarkExRiskValue,
    totalPendingWithdrawAmount,
    totalPendingTransferOutAmount,
    totalOrderFrozenAmount,
  };
};
