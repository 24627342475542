import { Navigate, redirect } from "react-router-dom";
import React from "react";
import { isDev, isTestnet, isMainnet, winENV, currentENV } from "@/utils/env";
import Layout from "@/modules/layout/layout";
import HomeLayout from "@/modules/layout/homeLayout";
import TradeLayout from "@/modules/layout/tradeLayout";
import VipLayout from "@/modules/layout/vipLayout";
import SymbolRedirect from "@/modules/trade/pages/SymbolRedirect";
import LazyLoad from "./LazyLoad.jsx";

// dev && testnet 环境
// const ENVDevTestnet = isDev || isTestnet || !currentENV; //isDev && isTestnet && localhost

// 如果访问的域名以"pro."或"testnet"开头，且path为"/"，页面重定向到 "/trade"
const HomeRedirectLoader = async ({ request, params }) => {
  const origin = request.url.split("//")[1];
  // 在testnet-internal 域名下做首页文案验证，验证完成后删除
  // console.log("origin:", origin);
  // if (origin.startsWith("testnet-internal")) return null;
  //
  if (origin.startsWith("pro.") || origin.startsWith("testnet")) return redirect("/trade");
  else return null;
};

// 如果访问的域名以"www."开头，且path不为"/"，页面重定向到 "/404"
const NofoundRedirectLoader = async ({ request, params }) => {
  const matchStr = "www.";
  const origin = request.url.split("//")[1];
  const path = origin.split("/")[1];
  if (origin.startsWith(matchStr) && !!path) {
    return redirect("/404");
  } else return null;
};

// 如果访问的域名以"www."开头，且path为"/trade"，页面重定向到域名"pro.edgex.exchange"
const TradeRedirectLoader = async ({ request, params }) => {
  const matchStr = "www.";
  const origin = request.url.split("//")[1];
  if (origin.startsWith(matchStr)) return redirect(request.url.replace(matchStr, "pro."));
  else return null;
};

// 辅助测试功能Routes
const testRoutes = [
  // 测试功能
  {
    path: "transferaccount-operateTestKeandev-v1demo",
    auth: true,
    element: LazyLoad(import("@/modules/account/pages/transfer-account")),
  },
  {
    path: "form",
    auth: true,
    element: LazyLoad(import("@/modules/form/pages/Form")),
  },
];

export const routes = [
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      // home
      {
        index: true,
        auth: true,
        loader: HomeRedirectLoader,
        element: LazyLoad(import("@/modules/home/pages/index")),
      },
      {
        path: "userterms",
        auth: true,
        element: LazyLoad(import("@/modules/home/pages/TermsUser")),
      },
      {
        path: "privacypolicy",
        auth: true,
        element: LazyLoad(import("@/modules/home/pages/PrivacyPolicy")),
      },
    ],
  },
  {
    path: "/",
    element: <TradeLayout />,
    children: [
      {
        path: "trade",
        loader: TradeRedirectLoader,
        element: <SymbolRedirect />,
      },
      // trade
      {
        path: "trade/:symbol",
        loader: TradeRedirectLoader,
        element: LazyLoad(import("@/modules/trade/pages/Trade")),
      },
      // dashboard
      {
        path: "dashboard",
        auth: true,
        loader: NofoundRedirectLoader,
        element: LazyLoad(import("@/modules/dashboard/pages/Dashboard")),
        children: [
          {
            index: true,
            auth: true,
            element: <Navigate to="/dashboard/markets" />,
          },
          {
            path: "markets",
            auth: true,
            element: LazyLoad(import("@/modules/dashboard/components/Markets")),
          },
          {
            path: "profile",
            auth: true,
            children: [
              {
                index: true,
                auth: true,
                element: LazyLoad(import("@/modules/dashboard/components/Profile")),
              },
              {
                path: "detail",
                auth: true,
                element: LazyLoad(import("@/modules/dashboard/components/ProfileDetail")),
              },
            ],
          },
          {
            path: "orders",
            auth: true,
            element: LazyLoad(import("@/modules/dashboard/components/Orders")),
          },
          {
            path: "funds",
            auth: true,
            element: LazyLoad(import("@/modules/dashboard/components/Funds")),
          },
          {
            path: "transfers",
            auth: true,
            element: LazyLoad(import("@/modules/dashboard/components/Transfers")),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      // account
      {
        path: "account",
        auth: true,
        loader: NofoundRedirectLoader,
        element: LazyLoad(import("@/modules/account/pages/Account")),
      },
      // faucet
      {
        path: "faucet",
        auth: true,
        loader: NofoundRedirectLoader,
        element: LazyLoad(import("@/modules/faucet/pages/Faucet")),
      },
      {
        path: "funding/:symbol",
        auth: true,
        loader: NofoundRedirectLoader,
        element: LazyLoad(import("@/modules/funding/pages/Funding")),
      },
      {
        path: "keyManagement",
        auth: true,
        loader: NofoundRedirectLoader,
        element: LazyLoad(import("@/modules/keyManagement/pages/KeyManagement")),
      },
      {
        path: "leaderboard",
        auth: true,
        loader: NofoundRedirectLoader,
        element: LazyLoad(import("@/modules/leaderboard/pages/Leaderboard")),
      },
      {
        path: "points",
        auth: true,
        loader: NofoundRedirectLoader,
        element: LazyLoad(import("@/modules/points/pages/Points")),
        // children: [
        //   {
        //     path: "transfer",
        //     auth: true,
        //     element: LazyLoad(import("@/modules/points/pages/Transfer")),
        //   },
        // ],
      },
      // referral
      {
        path: "referral/:id?",
        auth: true,
        loader: NofoundRedirectLoader,
        element: LazyLoad(import("@/modules/referral/pages/Referral")),
      },
      {
        path: "referral/information/:id?",
        auth: true,
        loader: NofoundRedirectLoader,
        element: LazyLoad(import("@/modules/referral/pages/MyInfo")),
      },
      ...testRoutes,
    ],
  },

  {
    path: "/",
    element: <VipLayout />,
    children: [
      // vip
      {
        path: "vip",
        auth: true,
        loader: NofoundRedirectLoader,
        element: LazyLoad(import("@/modules/vip/pages/VIP")),
      },
    ],
  },

  {
    path: "/deme",
    auth: true,
    loader: NofoundRedirectLoader,
    element: LazyLoad(import("@/modules/demo/pages/Demo")),
  },
  { path: "*", element: <Navigate to="/" replace /> },
  // { path: "*", element: <div className="flex-1 flex items-center justify-center text-lg">404</div> },
];

// const routes = [
//   {
//     path: "/",
//     element: <Layout />,
//     children: [
//       {
//         path: "/",
//         element: <Navigate to="/" replace />,
//       },
//     ],
//   },
//   {
//     path: "/",
//     element: <HomeLayout />,
//     children: [
//       { index: true, element: LazyLoad(import("./modules/home/pages")) },
//       {
//         path: "/",
//         element: <Navigate to="/" replace />,
//       },
//     ],
//   },
//   { path: "*", element: <Navigate to="/" replace /> },
//   // { path: "*", element: <div className="flex-1 flex items-center justify-center text-lg">404</div> },
// ];
// // load all modules
// const modules = require.context("@/modules", true, /^\.\/[\w-]+\/index\.jsx$/);
// modules.keys().forEach((filePath) => {
//   modules(filePath).default({ routes });
// });
