import ui from "@/constants/ui";
import useInterface from "@/stores/interface";
import { getPrecision } from "@/utils/funcs";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export const DEFAULT_SYMBOL = ui?.defaultSymbol || "BTCUSDT";
const persistFields = ["favorites"];
const initialStoreValues = {
  currentSymbol: location.href.match(/\/trade\/([^/]+)/)?.[1] || DEFAULT_SYMBOL, // 当前 选中 币对
  favorites: [], // 收藏的 币对
  symbols: {
    // key 为 contractName 字段
    // 所有 symbol
    // BTCUSDC: {
    //   contractName: "BTCUSDC",
    //   lastPrice: 43629,
    //   price24hPcnt: 0.000871739579271,
    //   highPrice24h: 44361,
    //   lowPrice24h: 43423,
    //   oraclePrice: 43610.346180890905,
    //   indexPrice: 43603.58,
    //   turnover24h: 4016930785.1075,
    //   volume24h: 91696.502,
    //   funding1h: 0.0000125,
    //   openInterest: 1225.8776,
    //   tradeCount: 4450,
    // },
  },
  currentSymbolFundingRate: "",
};

const useSymbol = create(
  persist(
    immer((set, get) => ({
      ...initialStoreValues,
      setSymbol: (newValues) => set((state) => ({ ...state, ...newValues })),
      getSymbols: () => {
        const { symbols } = get();
        const interfaceState = useInterface.getState();
        const coinList = interfaceState?.metadata?.coinList || [];
        return (interfaceState?.metadata?.contractList?.map((c, rankIdx) => ({ rankIdx, ...c })) || []).map((c) => {
          const obj = symbols[c.contractName] || {};
          // baseCoin 形如 BTC
          // quoteCoin 形如 USDC
          const baseCoinInfo = coinList.find((item) => item.coinId === c.baseCoinId) || {};
          const quoteCoinInfo = coinList.find((item) => item.coinId === c.quoteCoinId) || {};
          return {
            // instrumentInfo.all topic 返回的数据
            ...obj,
            // symbol 接口中的  合约 contract
            ...c,
            // 兼容之前系统的映射字段
            // obj , c 只会 symbol 字段重复 合并后 symbol: "BTCUSDC",
            symbol: c.contractName, // 兼容之前系统的映射字段
            pricePrecision: getPrecision(c.tickSize),
            priceStep: c.tickSize,
            sizePrecision: getPrecision(c.stepSize),
            sizeStep: c.stepSize,

            baseCoin: baseCoinInfo.coinName,
            // 显示精度
            baseCoinPrecision: Math.abs(Math.log10(baseCoinInfo.showStepSize || 1)),
            // 计算精度
            baseCoinRealPrecision: Math.abs(Math.log10(baseCoinInfo.stepSize || 1)),
            baseCoinIcon: baseCoinInfo.iconUrl,

            quoteCoinPrecision: Math.abs(Math.log10(quoteCoinInfo.showStepSize || 1)),
            quoteCoinRealPrecision: Math.abs(Math.log10(quoteCoinInfo.stepSize || 1)),
            quoteCoin: quoteCoinInfo.coinName,
            quoteCoinIcon: quoteCoinInfo.iconUrl,
          };
        });
      },
      getCurrentSymbol: () => {
        const { currentSymbol, getSymbolDetail } = get();
        return getSymbolDetail(currentSymbol);
      },
      getSymbolDetail: (symbol, field = "contractName") => {
        const { getSymbols } = get();
        const symbols = getSymbols();
        return symbols.find((s) => s[field] == symbol) || {};
      },
    })),
    {
      name: "symbol",
      storage: createJSONStorage(() => localStorage),
      // 只保存部分字段
      partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key]) => persistFields.includes(key))),
    },
  ),
);

export default useSymbol;
