import Icon from "@/components/Icon";
import { CustomEvents } from "@/constants/customEvents";
import useTranslation from "@/hooks/useTranslation";
import emailIcon from "@/icons/email.svg";
import useGlobal from "@/stores/global";
import { logEvent } from "@/utils/analytics";

const MpcConnect = () => {
  const t = useTranslation();
  const { setGlobal } = useGlobal();
  return (
    <div
      onClick={() => {
        setGlobal?.({ connectModalVisible: false });
        window.dispatchEvent(new CustomEvent(CustomEvents["mpc-login"], { detail: {} }));
        logEvent("link", "click", "Web_Click_EmailWallet_btn");
      }}
      className="rounded-sm h-10 px-3 flex items-center gap-2 bg-background text-foreground hover:bg-primary hover:text-bg-primary cursor-pointer transition-all"
    >
      {<Icon icon={emailIcon} className="size-4" />}
      {t("loginWithEmail")}
    </div>
  );
};

export default MpcConnect;
