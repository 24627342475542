import Icon from "@/components/Icon";
import Spinner from "@/components/Spinner";
import Trans from "@/components/Trans";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { CustomEvents } from "@/constants/customEvents";
import useTranslation from "@/hooks/useTranslation";
import error from "@/icons/error.svg";
import success from "@/icons/success.svg";
import walletIcon from "@/icons/wallet.svg";
import useGlobal from "@/stores/global";
import { useBoolean, useEventListener } from "ahooks";
import { useRef, useState } from "react";
import { useConnectors } from "wagmi";

import imToken from "@/assets/img/walllet/imToken.png";
import metamask from "@/assets/img/walllet/metamask.png";
import okx from "@/assets/img/walllet/okx.png";
import walletconnect from "@/assets/img/walllet/walletconnect.png";
import tokenpocket from "@/assets/img/walllet/TokenPocket.png";
import ui from "@/constants/ui";
import MpcConnect from "@/modules/layout/components/MpcConnect";
import { twMerge } from "tailwind-merge";
import { logEvent } from "@/utils/analytics";
import { captureException } from "@sentry/react";

const staticWallets = [
  {
    id: "io.metamask",
    name: "MetaMask",
    icon: metamask,
    link: "https://metamask.io/",
  },
  {
    id: "com.okex.wallet",
    name: "OKX Wallet",
    icon: okx,
    link: "https://www.okx.com/web3",
  },
  // {
  //   id: "walletConnect",
  //   name: "WalletConnect",
  //   icon: walletconnect,
  //   link: "https://walletconnect.com/",
  // },
  // {
  //   id: "walletConnect",
  //   name: "imToken",
  //   icon: imToken,
  //   link: "https://token.im/",
  // },
  {
    id: "pro.tokenpocket",
    name: "TokenPocket",
    icon: tokenpocket,
    link: "https://www.tokenpocket.pro/en/download/app",
  },
];

const WalletConnectCmp = () => {
  const [isPending, { setTrue, setFalse }] = useBoolean(false);
  const [connectStatus, setConnectStatus] = useState(undefined);
  const t = useTranslation();
  const { toast: Toast } = useToast();
  const { setGlobal } = useGlobal();
  const connectorConfig = useRef();
  const connectors = useConnectors();
  const mergedConnectors = staticWallets.map((w) => ({ ...w, ...connectors.find((c) => c.id === w.id), name: w.name })).concat(connectors.filter((w) => !staticWallets.find((s) => s.id === w.id)));
  // 优先用本地 icon metamask 特殊处理
  mergedConnectors.forEach((w) => {
    const staticWalletIcon = staticWallets.find((s) => s.name === w.name)?.icon;
    if (staticWalletIcon && w.id != "io.metamask") {
      w.icon = staticWalletIcon;
    }
  });

  const handleConnect = async (config) => {
    try {
      if (!connectors.find((w) => w.id === config.id)) {
        setGlobal?.({ connectModalVisible: false, walletInstallGuide: config });
        return;
      }
      setConnectStatus(undefined);
      setTrue();
      connectorConfig.current = config;
      const res = await config?.connect();

      setGlobal?.({ connectModalVisible: false });
      // await connectWallet();
      // await new Promise((resolve) => setTimeout(resolve, 500));
      // const btnIdx = [...document.querySelectorAll("#privy-modal-content button>span:first-of-type")].map((n) => n.textContent).findIndex((n) => n === config.name);
      // document.querySelectorAll("#privy-modal-content button")?.[btnIdx + 1]?.click();

      setFalse();
      setConnectStatus(true);
      // 触发事件
      window.dispatchEvent(new CustomEvent(CustomEvents["wallet-connected"], { detail: { address: res?.accounts?.[0], chainId: res.chainId, wallet: config.id, walletIcon: config.icon } }));
      const logName = config.id === "MetaMask" ? "Web_Click_MetaMaskWallet_btn" : config.id === "OKX" ? "Web_Click_OKXWallet_btn" : "";
      if (logName) logEvent("button", "click", logName);
    } catch (e) {
      captureException(e);
      // eslint-disable-next-line no-console
      console.log(e);
      Toast.show(t(typeof e?.details == "string" ? e?.details : e.message), { type: "error" });
      setFalse();
      setConnectStatus(false);
    }
  };

  const handleRetryConnect = () => {
    if (connectorConfig?.current) {
      handleConnect(connectorConfig.current);
    }
  };

  if (connectStatus === false) {
    return (
      <div className="flex flex-col w-full items-center">
        <Icon icon={error} className="size-14 mt-6" />
        <div className="text-foreground text-sm font-medium mt-4">
          <Trans i18nKey="failToConnect">Failed to connect</Trans>
        </div>

        <button onClick={handleRetryConnect} className={twMerge(ui?.dialogFooterButtonClassName, "mt-8 btn-primary w-2/3")}>
          <Trans i18nKey="retry">Retry</Trans>
        </button>
      </div>
    );
  }

  if (connectStatus === true) {
    return (
      <div className="w-full flex flex-col gap-4 items-center">
        <Icon icon={success} className="size-16" />
        <div className="text-foreground text-base font-bold">
          <Trans i18nKey="connected">Connected</Trans>
        </div>
      </div>
    );
  }

  if (isPending) {
    return (
      <div className="w-full flex flex-col gap-8 items-center">
        <Spinner iconClassName="size-14" className="text-primary fill-primary mt-6"></Spinner>
        <div className="flex flex-col gap-4 items-center">
          <div className="text-foreground text-sm font-medium mt-4">
            <Trans i18nKey="requestingConnection">Requesting Connection</Trans>
          </div>
          {/* <div className="text-base-300 text-sm">
            <Trans i18nKey="connectingDesc">Open the MetaMask browser extension to connect your wallet.</Trans>
          </div> */}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <MpcConnect />
      <div className="divider my-4 before:scale-y-50 after:scale-y-50 text-accent">{t("OR")}</div>
      <div className="text-sm">
        <Trans i18nKey="chooseWallet">Select a wallet</Trans>
      </div>
      <ul className="grid grid-wrap grid-cols-1 items-center gap-1 mt-4">
        {mergedConnectors?.map((i, idx) => {
          return (
            <li
              onClick={() => handleConnect(i)}
              key={i?.id + idx}
              className={twMerge(
                "cursor-pointer rounded-sm h-10 px-3 bg-background text-foreground flex justify-start items-center gap-2 hover:bg-primary hover:text-bg-primary transition-all",
                connectors.find((w) => w.id === i.id) ? "" : "text-foreground-disabled",
              )}
            >
              <div className="size-4 rounded-full bg-bg-tertiary inline-flex items-center justify-center">
                {i?.icon ? <img src={i?.icon} className={twMerge("rounded-full", i.id == "io.metamask" ? "size-4" : "size-4")} /> : <Icon icon={walletIcon} className="size-4" />}
              </div>
              <div className="">{i.name}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const WalletConnectDialog = () => {
  const t = useTranslation();
  const { connectModalVisible, walletInstallGuide, setGlobal } = useGlobal();

  const handleWalletChange = (v) => {
    setGlobal?.({ walletInstallGuide: v ? walletInstallGuide : null });
  };

  const handleChanges = (v) => {
    setGlobal?.({ connectModalVisible: v });
  };

  // 添加事件触发
  useEventListener(CustomEvents["open-wallet-connect-dialog"], () => {
    setGlobal({ connectModalVisible: true });
  });

  useEventListener(CustomEvents["close-wallet-connect-dialog"], () => {
    setGlobal({ connectModalVisible: false });
  });

  useEventListener(CustomEvents["set-wallet-connect-dialog"], (e) => {
    setGlobal(e?.detail);
  });

  return (
    <>
      <Dialog open={connectModalVisible} onOpenChange={handleChanges}>
        <DialogContent
          className="max-w-[480px]"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader>
            <DialogTitle>{t("connectWallet")}</DialogTitle>
          </DialogHeader>

          <WalletConnectCmp />
        </DialogContent>
      </Dialog>
      <Dialog open={!!walletInstallGuide} onOpenChange={handleWalletChange}>
        <DialogContent
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader>
            <DialogTitle>{t("connectWallet")}</DialogTitle>
          </DialogHeader>
          <div className=" flex flex-col gap-4 [&>div]:w-full mt-4">
            <div className="p-4 flex items-center justify-center">
              <div className="size-12 rounded-full bg-bg-tertiary inline-flex items-center justify-center">
                {walletInstallGuide?.icon ? (
                  <img src={walletInstallGuide?.icon} className={twMerge("rounded-full", walletInstallGuide.id == "io.metamask" ? "size-9" : "size-12")} />
                ) : (
                  <Icon icon={walletIcon} className="size-12" />
                )}
              </div>
            </div>
            <div className="text-center">{t("installWalletGuide")}</div>
            <button
              className="btn btn-accent h-12 min-h-12 mt-4"
              onClick={() => {
                setGlobal?.({ connectModalVisible: true, walletInstallGuide: null });
              }}
            >
              {t("changeWallet")}
            </button>
            <button
              className="btn btn-primary h-12 min-h-12"
              onClick={() => {
                window.open(walletInstallGuide?.link);
              }}
            >
              {t("installWallet", { wallet: walletInstallGuide?.name })}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

WalletConnectDialog.propTypes = {};

export default WalletConnectDialog;
