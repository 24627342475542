import { createClient, http } from "viem";
import * as fullChainsModule from "viem/chains";
import { createConfig } from "wagmi";
import { walletConnect } from "wagmi/connectors";

export const fullChains = Object.values(fullChainsModule);

export const genConfig = (chainIds = [fullChainsModule.mainnet, fullChainsModule.sepolia], needStore = false) => {
  if (window?.dangerouslyExportedConfig) {
    return window.dangerouslyExportedConfig;
  }

  // chainIds.forEach((chain) => {
  //   if (window?.ENV?.[`CHAIN_RPC_URL_${chain.id}`]) {
  //     chain.rpcUrls.default.http[0] = window.ENV[`CHAIN_RPC_URL_${chain.id}`];
  //   }
  // });

  // chainIds.find((n) => n.chain == "Sepolia").rpcUrls.default.http[0] = "https://sepolia.infura.io/v3/";


  const config = createConfig({
    chains: chainIds,
    connectors: [
      // walletConnect({
      //   projectId: "fd316edf6637c7a3aae560559fb8422d",
      // }),
    ],
    client({ chain }) {
      // console.log(chain, window?.ENV?.[`CHAIN_RPC_URL_${chain.id}`] || undefined);
      return createClient({
        chain,
        transport: http(window?.ENV?.[`CHAIN_RPC_URL_${chain.id}`] || undefined),
      });
    },
    // transports: Object.fromEntries(chainIds.map((chain) => [chain.chainId, http(window?.ENV?.[`CHAIN_RPC_URL_${chain.id}`] || undefined)])),
  });

  if (needStore) {
    // todo 不挂在global上
    window.dangerouslyExportedConfig = config;
  }

  return config;
};

export const disclaimerAndSignStepEnum = {
  sign: 1,
  disclaimer: 2,
  unknown: 0,
};

// 测试用
export const testWalletPrivateKeys = [
  "0x1c4a8ec8fc9ea9131c4412b123a127465d9603bdbce223d2327a6980dce1f716",
  "0xed660d01744ea4829890fab0d8ce00598a793274718bc60a7a762b6c41ce444a",
  "0x8dda0b14d8da6e0bbbfde2cc850e6ce08e0ecf7fdb7f1b2a89bf5f83ce2cb8b9",
  "0xe3a1245edb84113a923c28d71c5fcae7248e6e5c64ce41cf0cef0ede2211f54d",
  "0x45a7b92944dcaa235e5ac9ac9c3669fea9acd2b3069fc88997dd432b933d07f1",
  "0xf53492a5d128d73c7152a88f79fedea5df8afb8fe6ffd1a0a3e0b603faa0b4d9",
  "0xe394e9b45dbf0f4f8b87891e7ab9a022553d8c42b6bc2efb4eac0f3c51fcf6f4",
  "0x7d9f95d855d7d08aa92b2aec13eac101cd931a93ed0f38ba087872e6c42ccaf5",
  "0x939d5d2c255ff2bf71a4951d8878d34d8bb7bdc4e7719e9a188fe08b2f0ef204",
  "0xe3035b0d79998b5195b762331f9cd91c5557ba72540b03bc5bf36eb245a74e43",
];
