import ReactGA from "react-ga4";
import { currentENV } from "./env";

export const initGA = () => {
  const analyticsIdList = {
    dev: "G-JXLY8G0LCZ",
    testnet: "G-B6HWQM2XZ1",
    mainnet: "G-CC15H4GB9Q",
  };
  const analyticsId = analyticsIdList[currentENV] || analyticsIdList["dev"];
  // console.log(analyticsId, "埋点id");
  ReactGA.initialize([
    {
      trackingId: analyticsId,
      siteSpeedSampleRate: 100,
    },
  ]);
};

/**
 * 页面事件
 * @param  logPageView();
 * trackerNames 事件名
 * page 页面路径
 */

export const logPageView = (trackerNames, page) => {
  if (!window.GA_INITIALIZED) {
    initGA();
    window.GA_INITIALIZED = true;
  }
  // ReactGA.set({ path: page || window.location.pathname }, trackerNames);
  ReactGA.send({ hitType: "pageview", page: page || window.location.pathname, title: trackerNames });
};
/**
 * 点击事件
 * @param logEvent('button', 'click');
 * category：必选，事件类别 （跳转：link 点击按钮：button）
 * action：必选，事件动作
 * label 可选，事件标签,事件名
 * value：可选，参数值
 */

export const logEvent = (category, action, label, value) => {
  if (!window.GA_INITIALIZED) {
    initGA();
    window.GA_INITIALIZED = true;
  }
  ReactGA.event({ category, action, label, value });
};

/**
 * 页面事件
 * @param
 */
export function trackPageView() {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
}
