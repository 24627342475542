import { getFeeRate, getMaxLeverage } from "@/components/calculator/account";
import { getCollateralInfo } from "@/components/calculator/collateral";
import { calculatePositionItem } from "@/components/calculator/position";
import { calculatePositionTerm } from "@/components/calculator/positionTerm";
import { calculatePositionTransaction } from "@/components/calculator/positionTransaction";
import { TYPE_orderType } from "@/constants/trade";
import useSettings from "@/modules/trade/stores/settings";
import useInterface from "@/stores/interface";
import useSymbol from "@/stores/symbol";
import useUser from "@/stores/user";
import BigNumber from "bignumber.js";
import { useMemo } from "react";

// 获取私有信息
export default function useUserPrivateInfo() {
  const { uplPriceType } = useSettings();
  const { metadata } = useInterface();
  const { getSymbols, getCurrentSymbol } = useSymbol();
  const { quoteCoinId, contractId } = getCurrentSymbol();
  const symbolsList = getSymbols();
  const { currentActiveAccount: account, positionTransaction, orderFillTransaction, order, collateral, withdraw, transferOut, positionTermList, tradeHistory } = useUser();
  let { position } = useUser();
  // position = position.filter((p) => p.enableDisplay !== false);
  const collateralInfo = getCollateralInfo({ contractId, quoteCoinId, position, account, metadata, symbolsList, withdraw, transferOut, collateral, order });

  const closePnLList = useMemo(() => {
    return positionTransaction?.filter((item) => Number(item.fillCloseSize) != 0)?.map((item) => calculatePositionTransaction(item, symbolsList)) || [];
  }, [positionTransaction, symbolsList]);

  const showPositionTermList = positionTermList/*?.filter((item) => BigNumber(item.cumCloseSize).plus(item.cumOpenSize).eq(0))*/?.map((item) => calculatePositionTerm(item, symbolsList)) || [];

  // order.status [ UNKNOWN_ORDER_STATUS, PENDING, OPEN, FILLED, CANCELING, CANCELED, UNTRIGGERED, UNRECOGNIZED ]
  // order.type [ UNKNOWN_ORDER_TYPE, LIMIT, MARKET, STOP_LIMIT, STOP_MARKET, TAKE_PROFIT_LIMIT, TAKE_PROFIT_MARKET, UNRECOGNIZED ]
  const allOrders =
    order?.map((i) => {
      return {
        ...symbolsList.find((s) => s.contractId == i.contractId),
        ...i,
      };
    }) || [];

  const tradeHistoryList =
    tradeHistory?.map((i) => {
      return {
        ...symbolsList.find((s) => s.contractId == i.contractId),
        ...i,
      };
    }) || [];
  const activeOrderList = allOrders?.filter((o) => ["PENDING", "OPEN", "CANCELING"].includes(o.status)) || [];
  const conditionalOrderList = allOrders?.filter((o) => ["UNTRIGGERED"].includes(o.status)) || [];
  // TODO: K 线图中显示的订单 = 条件单 + TPSL 单？（规则不清楚）
  const klineShowConditionalOrderList = allOrders?.filter((o) => ["UNTRIGGERED"].includes(o.status) && ["STOP_LIMIT", "STOP_MARKET", "TAKE_PROFIT_LIMIT", "TAKE_PROFIT_MARKET"].includes(o.type)) || [];

  const filledList = useMemo(() => {
    return (
      orderFillTransaction?.map((i) => {
        return {
          ...symbolsList.find((s) => s.contractId == i.contractId),
          ...i,
        };
      }) || []
    );
  }, [orderFillTransaction, symbolsList]);

  const { takerFeeRate = 0, makerFeeRate = 0 } = getFeeRate({ contractId, account, metadata });

  const positionList =
    position
      ?.filter((p) => Number(p.openSize) != 0)
      ?.map((i) => {
        const tpOSlList = conditionalOrderList?.filter((o) => o.isPositionTpsl && o.contractId === i.contractId && o.side == (BigNumber(i?.openSize).gte(0) ? "SELL" : "BUY"));
        return {
          ...calculatePositionItem(i, { symbolsList, collateralInfo, account, metadata, priceType: uplPriceType }),
          tpOSlList,
          tpVolume: tpOSlList?.reduce((prev, next) => {
            if ([TYPE_orderType.TAKE_PROFIT_MARKET].includes(next?.type)) {
              return BigNumber(prev).plus(next?.size).toString();
            }
            return prev;
          }, 0),
          slVolume: tpOSlList?.reduce((prev, next) => {
            if ([TYPE_orderType.STOP_MARKET].includes(next?.type)) {
              return BigNumber(prev).plus(next?.size).toString();
            }
            return prev;
          }, 0),
        };
      }) || [];

  return {
    getMaxLeverage: (forceContractId) => getMaxLeverage({ contractId: forceContractId || contractId, account, metadata }),
    getFeeRate: () => getFeeRate({ contractId, account, metadata }),
    balance: BigNumber.max(
      0,
      BigNumber(collateralInfo.totalEquity)
        .minus(collateralInfo.totalInitialMarginRequirement)
        .minus(collateralInfo.totalPendingWithdrawAmount)
        .minus(collateralInfo.totalPendingTransferOutAmount)
        .minus(collateralInfo.totalOrderFrozenAmount),
    ),
    takerFeeRate: Math.max(Number(takerFeeRate), Number(makerFeeRate)),
    totalAssets: collateralInfo?.totalEquity || 0,
    filledList,
    positionList,
    positionTermList: showPositionTermList,
    activeOrderList,
    conditionalOrderList,
    klineShowConditionalOrderList,
    tradeHistoryList,
    closePnLList,
    collateral: collateralInfo,
    position: positionList.find((p) => p.contractId === contractId),
  };
}
