export default {
  enabledLanguages: ["en-US", "zh-CN"],
  symbols2Recommend: [],
  defaultLanguage: "en-US",
  symbolPopoverContentProps: {
    sideOffset: 16,
    alignOffset: 0,
  },
  symbolPopoverContentClassName: "",
  symbolPopoverTabs: ["favorite_tab", "all_tab", "USDT_tab"],
  // symbolPopoverContentTableBodyRowClassName: "hover:!bg-bg-tertiary",
  addFavCol: "left",
  headerDropDownIconTitleClassName: "hover:bg-transparent [&_img]:size-5 [&_.spinner]:size-5",
  headerDropDownIconItemClassName: "[&_.icon]:size-5",
  headerLinkActiveClassName: "text-primary fill-primary active-nav-link",
  joinFormControlClassName: "",
  orderInputClassName:
    "bg-[--join] border-[--join] hover:border-border-subtle focus-within:border-border-strong rounded-sm has-[[data-state=open]]:border-border-strong  has-[:focus]:border-border-strong",
  tableClassName: "[--b1:19.13%_0_102.07]", // #141414
  tableHeaderRowClassName: "",
  tableBodyRowClassName: "",
  tabsPrimitiveTriggerClassName:
    "text-foreground-secondary font-normal data-[state=active]:font-normal data-[state=active]:text-foreground data-[state=active]:fill-foreground-primary transition-none",
  historyTabs: ["orderBook", "recentTrades"],
  tooltipContentClassName: "px-3 py-2 rounded",
  selectContentClassName: "",
  selectViewPortClassName: "",
  selectItemClassName:
    "hover:text-primary focus:text-primary rounded-sm min-h-8 px-2 box-border [&>.indicator>span]:hidden [&>.indicator]:before:content-[''] [&>.indicator]:before:rounded-full [&>.indicator]:before:bg-primary [&>.indicator]:before:size-1 [&>.indicator]:before:absolute [&>.indicator]:before:left-1/2 [&>.indicator]:before:top-1/2 [&>.indicator]:before:-translate-y-1/2 [&>.indicator]:before:-translate-x-1/2 [&>.indicator]:before:hidden [&>.indicator]:data-[state=checked]:before:block",
  hideSelectItemIndicator: true,
  formClassName: "",
  toastDefaultClassName: "top-[66px] right-3 rounded bg-bg-secondary border-border-subtle  text-foreground-secondary",
  toastCloseClassName: "right-3 top-4 size-6 inline-flex items-center justify-center",
  toastTitleClassName: "text-base leading-6 text-foreground",
  toastTitleIconClassName: "size-4",
  toastDescriptionClassName: "text-xs opacity-100 text-inherit",
  toastSimpleClassName: "top-[66px] px-4 py-3 rounded text-foreground bg-bg-secondary border-border-subtle",
  depositBtnClassName: "!min-h-7 !h-7 !font-normal !text-xs", //
  withdrawBtnClassName: "!min-h-7 !h-7 !font-normal !text-xs", //
  indexPriceSourceTableHeaderRowClassName: "text-foreground-secondary [&>*]:h-6 [&>*]:leading-6 [&>*]:px-1 [&>*:not(:first-child):last-child]:text-right",
  indexPriceSourceTableContainerClassName: "border-none mb-1",
  indexPriceSourceTableBodyRowClassName: "border-none [&>*]:h-6 [&>*]:leading-6 [&>*]:px-1 [&>*:not(:first-child):last-child]:text-right",
  indexPriceSourceTitleClassName: "text-sm px-1 my-1",
  indexPriceSourceTableClassName: "[--b1:0%_0%_19%] [--highlight:transparent] table-xs",
  tabsContentClassName: "pt-0 relative",

  positionTableClasses: {
    containerClassName: "mt-2",
    tableClassName: "table-pin-cols",
    bodyRowClassName: "[&>*]:text-nowrap [&>*]:text-foreground [&>*]:text-sm [&>*:not(:first-child)]:text-left [&>*:not(:first-child):last-child]:text-right",
    headerRowClassName: "[&>*]:text-xs [&>*:not(:first-child)]:text-left [&>*:not(:first-child):last-child]:text-right",
  },
  positionTableButtonClassName:
    "btn text-primary whitespace-nowrap flex-nowrap bg-accent btn-xs px-4 !text-xs !text-foreground !font-medium border-none [--btn-color:37.15%_0_0] [--n:37.15%_0_0] disabled:bg-bg-primary disabled:!text-bg-disabled appearance-none",
  dashboardTableButtonClassName: "btn text-primary bg-accent btn-xs px-4 !text-xs text-foreground  !font-medium border-none [--btn-color:37.15%_0_0]",
  helperClassName: "tooltip-text",
  dashboardTableClasses: {
    tableClassName: "table-pin-cols",
    headerRowClassName: "border-none !bg-background [&>*]:h-3 [&>*]:text-xs [&>*]:leading-none [&>*]:px-0 [&>*:not(:first-child)]:text-left [&>*:not(:first-child):last-child]:text-right [&>*]:pb-4",
    bodyRowClassName: "border-none [&>*]:min-h-9 [&>*]:h-9 [&>*]:px-0 [&>*]:leading-9 [&>*:not(:first-child)]:text-left [&>*:not(:first-child):last-child]:text-right",
  },
  dashboardTabsListClassName: "justify-start w-full border-b border-bg-secondary [&>*]:px-1 gap-2",
  dashboardTabsTriggerClassName:
    "rounded-none text-sm text-foreground-tertiary data-[state=active]:text-foreground data-[state=active]:bg-[linear-gradient(to_bottom,transparent_0px,transparent_96%,oklch(var(--p))_96%,oklch(var(--p)))]",
  dashboardOrderQuerySectionClassName:
    "inline-flex [&>.form-control]:pl-3 [&>.form-control]:bg-bg-secondary [&>.form-control]:rounded [&>.form-control]:w-auto [&>.form-control]:flex-row [&>.form-control]:gap-1 [&>.form-control]:inline-flex [&_.label-text]:text-nowrap [&_.label-text]:text-[--font-gray] gap-2.5 absolute top-[44px] border-b border-background right-0 h-8",
  toastLimit: 5,
  tabTriggerClassName: "font-medium text-[--font-gray] data-[state=active]:text-foreground data-[state=active]:font-medium",
  defaultShowEqualValInput: true,
  selectTriggerClassName: "",
  selectGhostClassName:
    "input-ghost input-sm border-transparent data-[state=open]:border-transparent text-xs px-0 focus-within:outline-none focus-within:border-transparent focus-within:bg-transparent py-0",
  settingsClassName: "bg-transparent",
  settingsIconClassName: "size-6 text-primary opacity-30",
  dialogContentClassName:
    "sm:max-w-[380px] fixed left-[50%] top-[50%] z-50 grid w-full max-w-dialog translate-x-[-50%] translate-y-[-50%] gap-8 p-4 bg-[--dialog] text-sm  text-[--font-gray] shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] rounded-md group/dialog",
  dialogTitleClassName: "",
  dialogCloseClassName:
    "absolute right-4 top-4 text-[--font-gray] rounded-sm ring-offset-popover hover:text-primary-content transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground [&>svg]:size-5",
  dialogFooterClassName: "sm:space-x-2 ",
  dialogFooterButtonClassName: "btn text-sm border-none !font-medium min-h-[38px] h-[38px] [&:disabled]:bg-[oklch(var(--btn-color,var(--b2))_/_var(--tw-bg-opacity))]",
  alertDialogDescriptionClassName: "text-sm",
  alertDialogCancelButtonClassName: "bg-accent",
  toastOrderSuccessClassName: "top-[66px] right-3 rounded-lg bg-bg-secondary border-border-subtle  text-foreground-secondary py-4 border-l-[24px] border-l-primary",
  toastOrderFailureClassName: "top-[66px] right-3 rounded-lg bg-bg-secondary border-border-subtle  text-foreground-secondary py-4 border-l-[24px] border-l-warning",
  viewHeaderClassName: "",
  viewHeaderItemClassName: "shrink-0 text-xs [&>.view-item-value]:text-xs whitespace-nowrap [&>.view-item-value]:whitespace-nowrap",
  lastPriceClassName: "text-2xl font-medium",
  emptyClassName: "[&>svg]:text-accent [&>div]:text-foreground-secondary",
  dialogScrollableBodyClassName: "max-h-[calc(100vh_-_240px)] no-scrollbar overflow-auto",
  headerIconLinkClassName: "h-full inline-flex items-center justify-center cursor-pointer px-2.5 hover:text-primary hover:bg-bg-primary",
  SheetContentClassName: "module module-settings text-sm flex flex-col",
  dashboardSelectClasses: {
    className: "bg-transparent border-transparent",
    selectItemClassName: "",
  },
  pageContainerClassName: "mx-[134px]",
};
