import React from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import ui from "@/constants/ui";

function HeaderNavLink({ className, children, ...rest }) {
  const commonClass = "w-auto h-full flex items-center justify-center px-3 nav-link text-sm";
  return (
    <NavLink className={({ isActive, isPending }) => twMerge(commonClass, isPending ? "loading loading-xs inline-flex " : isActive ? ui?.headerLinkActiveClassName : "", className)} {...rest}>
      {children}
    </NavLink>
  );
}
HeaderNavLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default HeaderNavLink;
