import Spinner from "@/components/Spinner";
import React, { Suspense } from "react";

export const hoemRoutes = [
  // {
  //   path: "/",
  //   element: <Suspense fallback={<Spinner className="min-h-[90vh]" />}>{React.createElement(React.lazy(() => import("./pages")))}</Suspense>,
  // },
  {
    path: "userterms",
    element: <Suspense fallback={<Spinner className="min-h-[90vh]" />}>{React.createElement(React.lazy(() => import("./pages/TermsUser")))}</Suspense>,
  },
  {
    path: "privacypolicy",
    element: <Suspense fallback={<Spinner className="min-h-[90vh]" />}>{React.createElement(React.lazy(() => import("./pages/PrivacyPolicy")))}</Suspense>,
  },
];