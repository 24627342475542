import Icon from "@/components/Icon";
import BN from "bn.js";

import { bnToHex32, generateRandomClientId, getTransferErc20Fact, normalizeHex32 } from "@/components/trade/helpers";
import { SignableConditionalTransfer, SignableTransfer, SignableWithdrawal } from "@/components/trade/starkex-lib";
import { useToast } from "@/components/ui/use-toast";
import useActiveChains from "@/hooks/useActiveChains";
import useMulticallBalance from "@/hooks/useMulticallBalance";
import useSignMessage from "@/hooks/useSignMessage";
import useTranslation from "@/hooks/useTranslation";
import infoTipsFull from "@/icons/info-tips-full.svg";
import Dialog from "@/modules/trade/components/Dialog";
import useUserPrivateInfo from "@/modules/trade/hooks/useUserPrivateInfo";
import axios from "@/services/request";
import useInterface from "@/stores/interface";
import useUser from "@/stores/user";
import { useRequest } from "ahooks";
import BigNumber from "bignumber.js";
import { Buffer } from "buffer";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { getAddress, keccak256, sha256 } from "viem";
import { arbitrumSepolia, arbitrum, goerli, mainnet, sepolia } from "viem/chains";
import { captureException } from "@sentry/react";

export const mainChainList = [+goerli.id, +sepolia.id, +mainnet.id];

export class SPEED {
  static FAST = 3;
  static Medium = 2;
  static SLOW = 1;
  static NONE = 0;
}

const useWithdraw = () => {
  const { balance } = useUserPrivateInfo();
  const t = useTranslation();
  const { toast: Toast } = useToast();
  const { currentActiveAccount } = useUser();
  const { metadata } = useInterface();
  const { chains, currentActiveChain } = useActiveChains();
  const arbitrumChain = chains.find((c) => c.chainId == arbitrumSepolia.id) ? arbitrumSepolia : arbitrum;

  const [curSelectToken, setCurSelectToken] = useState();
  const [curSelectChain, setCurSelectChain] = useState();
  const [fast, setFast] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [dlgVisible, setDlgVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [receiverAddress, setReceiverAddress] = useState("");
  const { signMessage } = useSignMessage();

  // pullOff: true, 代表不显示在 tokenList 中
  // withdrawEnable: true 代表可以提现，否则不可提现
  // 链对应的tokenList， 过滤pullOff
  const currentActiveChainTokens = (curSelectChain ? chains?.find((i) => +i.chainId === +curSelectChain) : currentActiveChain)?.tokenList?.filter((t) => !t.pullOff);

  const tokenInfo = currentActiveChainTokens?.find((i) => i.token == curSelectToken) || currentActiveChainTokens?.[0];

  const withdrawAmountTooSmall = withdrawAmount && Number(withdrawAmount) < (Number(metadata?.multiChain?.minWithdraw) || 0);
  const withdrawAmountTooLarge = withdrawAmount && Number(withdrawAmount) > (Number(metadata?.multiChain?.maxWithdraw) || 999999);

  const isMainChain = useMemo(() => mainChainList.includes(+curSelectChain), [curSelectChain]);

  const isArbitrum = useMemo(() => +curSelectChain === +arbitrumChain.id, [curSelectChain]);

  useEffect(() => {
    setFast(isMainChain ? true : false);
  }, [isMainChain]);
  // todo layout
  const { multicallRun } = useMulticallBalance(curSelectChain);

  const {
    data: balancesData,
    loading: multicallFetchLoading,
    runAsync: multicallFetchRun,
  } = useRequest(multicallRun, {
    manual: true,
  });

  useEffect(() => {
    if (!dlgVisible) return;
    setCurSelectToken(currentActiveChainTokens?.[0]?.token);
    multicallFetchRun();
  }, [curSelectChain]);

  const speed = useMemo(() => {
    const curChainId = curSelectChain;
    if (!curChainId) {
      return SPEED.NONE;
    }
    switch (+curChainId) {
      case +goerli.id:
      case +sepolia.id:
      case +mainnet.id:
        if (fast) {
          return SPEED.FAST;
        }
        return SPEED.SLOW;
      default:
        return SPEED.Medium;
    }
  }, [curSelectChain, fast]);

  const handleFee = async () => {
    if (!withdrawAmount) return "0";
    if (speed === SPEED.NONE) return "0";
    switch (speed) {
      case SPEED.FAST:
        // eslint-disable-next-line no-case-declarations
        const fastWithdrawInfo = await axios.get("/v1/private/assets/getFastWithdrawSignInfo", {
          params: {
            chainId: +curSelectChain,
            amount: withdrawAmount,
          },
        });
        return fastWithdrawInfo?.data?.data?.fee;
      case SPEED.Medium:
        // eslint-disable-next-line no-case-declarations
        const crossWithdrawInfo = await axios.get("/v1/private/assets/getCrossWithdrawSignInfo", {
          params: {
            chainId: +curSelectChain,
            amount: withdrawAmount,
          },
        });
        return crossWithdrawInfo?.data?.data?.fee;
      case SPEED.SLOW:
        return "0";
      default:
        return "0";
    }
  };

  const { data: fee, loading: withdrawInfoLoading } = useRequest(handleFee, {
    refreshDeps: [fast, withdrawAmount, curSelectChain],
    debounceWait: 500,
  });

  // 跨链提币，快速提币 最开可提数量
  const { balance: availableMargin } = useUserPrivateInfo();
  const decimals = parseInt(tokenInfo?.decimals || 2, 10);

  const { data: maxAvailable, loading: loadingMaxAvailable } = useRequest(
    () =>
      availableMargin
        ? Promise.all([
            axios
              .get("/v1/private/assets/getCrossWithdrawSignInfo", {
                params: {
                  chainId: +curSelectChain,
                  amount: "" + availableMargin,
                },
              })
              .then((res) =>
                BigNumber(availableMargin)
                  .minus(res?.data?.data?.fee || "0")
                  .toFixed(decimals),
              ),
            axios
              .get("/v1/private/assets/getFastWithdrawSignInfo", {
                params: {
                  chainId: +curSelectChain,
                  amount: "" + availableMargin,
                },
              })
              .then((res) =>
                BigNumber(availableMargin)
                  .minus(res?.data?.data?.fee || "0")
                  .toFixed(decimals),
              ),
          ]).then((arr) => ["" + availableMargin, "" + availableMargin, ...arr])
        : Promise.resolve([]),
    {
      refreshDeps: [curSelectChain, "" + availableMargin],
      ready: !!curSelectChain && !!availableMargin && Number(availableMargin) > 0 && dlgVisible,
      debounceWait: 1000,
    },
  );

  // 当前选择币种的余额、allowance
  // 本币 tokenInfo?.tokenAddress 为空
  const curSelectTokenData = useMemo(() => {
    return balancesData?.combinedData?.[getAddress(tokenInfo?.tokenAddress)];
  }, [balancesData?.baseTokenBalance, balancesData?.combinedData, tokenInfo?.tokenAddress]);

  const totalCost = useMemo(() => {
    return BigNumber.min(balance, BigNumber(fee || "0").plus(withdrawAmount || "0")).toString();
  }, [fee, withdrawAmount, balance]);

  const walletBalance = curSelectTokenData?.balance?.formatted;
  const k = BigNumber(totalCost).lte(balance) ? 0 : 1;
  const nextWalletBalance =
    curSelectTokenData?.balance?.formatted && +withdrawAmount
      ? BigNumber(curSelectTokenData?.balance?.formatted)
          .plus(withdrawAmount)
          .minus(k * fee)
          .toString()
      : "";

  const normalWithdraw = async () => {
    // console.log("--normalWithdraw--");
    const clientId = generateRandomClientId();
    const time = Math.ceil(new Date().getTime() / 3600000) * 3600000 + 14 * 24 * 60 * 60 * 1000;
    let withdrawalToSign = {
      humanAmount: withdrawAmount,
      expirationIsoTimestamp: `${time}`,
      clientId,
      positionId: currentActiveAccount.accountId,
      ethAddress: currentActiveAccount?.ethAddress,
    };

    const starkWithdrawal = SignableWithdrawal.fromWithdrawal(withdrawalToSign, +curSelectChain, metadata?.multiChain?.coinId);
    const signature = await starkWithdrawal.sign(currentActiveAccount?.keys?.l2Key);

    await axios.post("/v1/private/assets/createNormalWithdraw", {
      accountId: currentActiveAccount?.accountId,
      coinId: metadata?.multiChain?.coinId,
      amount: withdrawAmount,
      ethAddress: currentActiveAccount?.ethAddress,
      clientWithdrawId: clientId,
      expireTime: time?.toString(),
      l2Signature: signature,
    });
  };

  const fastWithdraw = async () => {
    // console.log("--fastWithdraw--");
    const withdrawInfo = await axios.get("/v1/private/assets/getFastWithdrawSignInfo", {
      params: {
        chainId: +curSelectChain,
        amount: withdrawAmount,
      },
    });
    const fee = withdrawInfo?.data?.data?.fee;
    const fastWithdrawMaxAmount = withdrawInfo?.data?.data?.fastWithdrawMaxAmount;

    if (BigNumber(withdrawAmount).gte(fastWithdrawMaxAmount)) {
      throw { message: "Exceeded maximum withdrawal balance" };
    }

    const clientId = generateRandomClientId();
    const salt = sha256(clientId).slice(0, 10);
    const time = Math.ceil(new Date().getTime() / 3600000) * 3600000 + 14 * 24 * 60 * 60 * 1000;

    const debitAmount = BigNumber(fee).plus(withdrawAmount).toString();

    const targetChainTokens = chains?.find((i) => +i?.chainId === +curSelectChain)?.tokenList;

    const factParams = {
      recipient: currentActiveAccount?.ethAddress, // 钱包地址
      tokenAddress: targetChainTokens?.[0]?.tokenAddress, // usdc 币种地址
      tokenDecimals: tokenInfo?.decimals, // usdc 币种
      humanAmount: withdrawAmount, // 提币数量
      salt,
    };
    const fact = getTransferErc20Fact(factParams);
    const transferToSign = {
      senderPositionId: currentActiveAccount?.accountId, // 发送者positionId
      receiverPositionId: withdrawInfo?.data?.data?.lpAccountId, // 资金池id
      receiverPublicKey: withdrawInfo?.data?.data?.fastWithdrawL2Key, // 资金池publickey
      factRegistryAddress: withdrawInfo?.data?.data?.fastWithdrawFactRegisterAddress, // l1 fact注册的合约地址
      fact,
      humanAmount: debitAmount, // 提现数量 + fee
      clientId,
      expirationIsoTimestamp: `${time}`,
    };
    const starkConditionalTransfer = SignableConditionalTransfer.fromTransfer(transferToSign, +curSelectChain);
    const signature = await starkConditionalTransfer.sign(currentActiveAccount?.keys?.l2Key);

    await axios.post("/v1/private/assets/createFastWithdraw", {
      accountId: currentActiveAccount?.accountId,
      coinId: metadata?.multiChain?.coinId,
      amount: withdrawAmount,
      ethAddress: currentActiveAccount?.ethAddress,
      erc20Address: targetChainTokens?.[0]?.tokenAddress,
      lpAccountId: withdrawInfo?.data?.data?.lpAccountId,
      clientFastWithdrawId: clientId,
      expireTime: time.toString(),
      l2Signature: signature,
      fee: fee,
      chainId: +curSelectChain,
      factRegistryAddress: withdrawInfo?.data?.data?.fastWithdrawFactRegisterAddress,
      fact: fact || salt,
    });
  };

  const crossWithdraw = async () => {
    // console.log("--crossWithdraw--");
    const withdrawInfo = await axios.get("/v1/private/assets/getCrossWithdrawSignInfo", {
      params: {
        chainId: +curSelectChain,
        amount: withdrawAmount,
      },
    });
    const fee = withdrawInfo?.data?.data?.fee;
    const crossWithdrawMaxAmount = withdrawInfo?.data?.data?.crossWithdrawMaxAmount;

    if (BigNumber(withdrawAmount).gte(crossWithdrawMaxAmount)) {
      throw { message: "Exceeded maximum withdrawal balance" };
    }

    const clientId = generateRandomClientId();
    const time = Math.ceil(new Date().getTime() / 3600000) * 3600000 + 14 * 24 * 60 * 60 * 1000;

    // 选择提现链的usd地址
    const targetChainTokens = chains?.find((i) => +i?.chainId === +curSelectChain)?.tokenList;
    const transferToSign = {
      humanAmount: new BigNumber(withdrawAmount).plus(Number(fee)).toFixed(), // params.amount,
      expirationIsoTimestamp: `${time}`,
      receiverPositionId: withdrawInfo?.data?.data?.lpAccountId, // crossChainAccountId
      senderPositionId: currentActiveAccount?.accountId, // 用户id
      receiverPublicKey: withdrawInfo?.data?.data?.crossWithdrawL2Key, // crossChainL2Key
      clientId,
    };
    // console.log("transferToSign", transferToSign);
    const starkTransfer = SignableTransfer.fromTransfer(transferToSign, +curSelectChain);
    const signature = await starkTransfer.sign(currentActiveAccount?.keys?.l2Key);

    const mpcParams = {
      mpcSignTime: dayjs().unix() + "",
      mpcAddress: currentActiveAccount?.ethAddress,
      ethAddress: receiverAddress,
      mpcSignature: "",
    };
    if (isArbitrum && currentActiveAccount?.wallet == "mpc") {
      // bytes32 userOperationHash = keccak256(abi.encodePacked(to, amount, token, userSignTime, block.chainid));
      const origin = `${receiverAddress.slice(2).toLowerCase()}${normalizeHex32(
        bnToHex32(new BN(BigNumber(withdrawAmount).multipliedBy(BigNumber(10).pow(decimals)).toString())),
      ).toString()}${targetChainTokens?.[0]?.tokenAddress.slice(2).toLocaleLowerCase()}${normalizeHex32(bnToHex32(new BN(mpcParams.mpcSignTime))).toString()}${normalizeHex32(
        bnToHex32(new BN("" + curSelectChain)),
      ).toString()}`;
      // eslint-disable-next-line no-console
      // console.log("str2keccak256", origin);

      const keccak256Str = keccak256(Buffer.from(origin, "hex")); // 0xabc
      const str2Sign = keccak256Str;
      // eslint-disable-next-line no-console
      // console.log("keccak256Str", keccak256Str, str2Sign);
      mpcParams.mpcSignature = await signMessage(str2Sign);
      // eslint-disable-next-line no-console
      // console.log("strAfterSignMessage", mpcParams.mpcSignature);
    }

    await axios.post("/v1/private/assets/createCrossWithdraw", {
      accountId: currentActiveAccount?.accountId,
      coinId: metadata?.multiChain?.coinId,
      amount: withdrawAmount,
      ethAddress: currentActiveAccount?.ethAddress,
      erc20Address: targetChainTokens?.[0]?.tokenAddress,
      lpAccountId: withdrawInfo?.data?.data?.lpAccountId,
      clientCrossWithdrawId: clientId,
      expireTime: time?.toString(),
      l2Signature: signature,
      fee: fee,
      chainId: +curSelectChain,
      ...(isArbitrum && currentActiveAccount?.wallet == "mpc" ? mpcParams : {}),
    });
  };

  const handleWithdraw = async () => {
    try {
      const confirmed = await Dialog.confirm({
        title: t("confirmWithdrawal"),
        description: (
          <div>
            <div className="text-foreground-tertiary">{fast ? t("withdraw.fast.desc", { fee: fee + " " + tokenInfo?.token }) : t("withdraw.desc")}</div>
            <div className="rounded-xl bg-bg-secondary text-foreground flex items-center gap-2 py-3 px-4 mt-6">
              <Icon icon={infoTipsFull} className="size-5 fill-warning" />
              <div className=" flex-1">{t("confirmWithdrawDesc2")}</div>
            </div>
          </div>
        ),
        contentClassName: "max-w-[480px]",
        cancel: false,
        confirmText: t("confirmWithdrawal"),
      });
      if (!confirmed) return;

      setSubmitting(true);
      switch (speed) {
        case SPEED.FAST:
          await fastWithdraw();
          break;
        case SPEED.SLOW:
          await normalWithdraw();
          break;
        case SPEED.Medium:
          await crossWithdraw();
          break;
      }
      Toast.show(t("withdrawalSubmitted"), { type: "success" });
      setSubmitting(false);
      setDlgVisible(false);
    } catch (e) {
      captureException(e);
      // eslint-disable-next-line no-console
      console.error(e);
      setSubmitting(false);
      Toast.show(e?.message?.toString(), { type: "error" });
    }
  };

  return {
    walletBalance,
    nextWalletBalance,
    totalCost,
    fee,
    withdrawInfoLoading,
    speed,
    tokenInfo,
    curSelectToken,
    curSelectChain,
    fast,
    withdrawAmount,
    setWithdrawAmount,
    setFast,
    setCurSelectToken,
    setCurSelectChain,
    handleWithdraw,
    dlgVisible,
    setDlgVisible,
    submitting,
    setSubmitting,
    balancesData,
    multicallFetchRun,
    multicallFetchLoading,
    withdrawAmountTooSmall,
    withdrawAmountTooLarge,
    isMainChain,
    currentActiveChainTokens,
    currentActiveChain,
    maxAvailable,
    loadingMaxAvailable,
    receiverAddress,
    setReceiverAddress,
    isArbitrum,
  };
};
export default useWithdraw;
