/**
 * 与用户相关的 store
 */

import { deepCopy } from "@/utils/funcs";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const unpartializeList = ["account", "collateral", "position", "positionTransaction", "deposit", "withdraw", "transferIn", "transferOut", "order", "orderFillTransaction"];

const initialStoreValues = {
  remember: true, // 签名时的记住我
  currentActiveAccount: null /*{
    accountId:"470252072772567514",
    clientAccountId:"main",
    ethAddress,
    wallet:"mpc", // 标识钱包来源 mpc or [connectorId]
    walletIcon:"mpc", // 钱包 icon 地址
    l2Key,
    l2KeyYCoordinate,
    userId,
    user,
    userPreference,
    apiKey,
    l2Key
  }*/, // 当前使用的账户信息
  currentClientAccountId: "main", // 当前使用的 clientAccountId
  // currentAccountId: "", // 当前使用的 accountId

  // accounts 列表 叫 account 与 ws 字段名一致
  account: [
    /*{
    "id": "453163298452406680",
    "userId": "453163298414657793",
    "ethAddress": "0xefaf40c41F0f457397361B8dD29F2b9fB985FE95",
    "l2Key": "0x357ddaf26a759cc9a8427814c0a5f0df83999dbf5f0a11d0b15289eb031ce83",
    "l2KeyYCoordinate": "0xe51f2905d7a816327cafb22ab20d1371fd70cad68bcc9a7f70e1cddb05fc14",
    "clientAccountId": "main",
    "defaultTradeSetting": {
      "takerFeeRate": "0",
      "makerFeeRate": "0",
      "takerFeeDiscount": "0",
      "makerFeeDiscount": "0",
      "maxLeverage": "0",
      "setFeeRate": false,
      "setFeeDiscount": false,
      "setMaxLeverage": false
    },
    "contractIdToTradeSetting": {},
    "maxLeverageLimit": "0",
    "createOrderPerMinuteLimit": 0,
    "createOrderDelayMillis": 0,
    "extraType": "",
    "extraDataJson": "",
    "status": "NORMAL",
    "createdTime": "1708042549718",
    "updatedTime": "1708042549718",
    "systemAccount": false,
    "liquidating": false
  }*/
  ],
  // 当前账户的抵押物信息
  collateral: [
    /*{
      "userId": "453163298414657793",
      "accountId": "453163298452406680",
      "coinId": "1000",
      "amount": "123.123",
      "legacyAmount": "0",
      "cumDepositAmount": "0",
      "cumWithdrawAmount": "0",
      "cumTransferInAmount": "0",
      "cumTransferOutAmount": "0",
      "cumPositionBuyAmount": "0",
      "cumPositionSellAmount": "0",
      "cumFillFeeAmount": "0",
      "cumFundingFeeAmount": "0",
      "cumFillFeeIncomeAmount": "0",
      "createdTime": "1708042549718",
      "updatedTime": "1708042549718"
    }*/
  ],
  // 持仓信息
  position: [],
  // 持仓变动明细
  positionTransaction: [],

  positionTermList: [],
  // 充值单
  deposit: [],
  // 提现单
  withdraw: [],
  // 转入单
  transferIn: [],
  // 转出单
  transferOut: [],
  // 委托单
  order: [],
  // 成交明细
  orderFillTransaction: [],

  // 历史订单
  tradeHistory: [],

  // 快速提现
  fastWithdraw: [],

  keys: {} /*{
    [address]: {
      'main': {apiKey, l2Key},
      'account1': {apiKey, l2Key},
      'account2': {apiKey, l2Key},
    }
   }*/,
};

const useUser = create(
  persist(
    (set, get) => ({
      ...initialStoreValues,
      setUser: (newValues) => set((state) => ({ ...state, ...newValues })),
      resetUser: () => set(() => deepCopy(initialStoreValues)),
      // 是否已经签名
      isAlreadySigned: () => {
        const { keys, currentClientAccountId, currentActiveAccount } = get();
        return currentActiveAccount?.ethAddress && !!keys?.[currentActiveAccount?.ethAddress?.toLocaleLowerCase()]?.[currentClientAccountId]?.l2Key;
      },
    }),
    {
      name: "user",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => {
        const stateKeys = Object.keys(state);
        const needStore = state.remember;
        return stateKeys.reduce((prev, next) => {
          if (!unpartializeList.includes(next)) {
            if (!needStore) {
              if (next === "currentActiveAccount" || next === "keys") return prev;
            }
            return { ...prev, [next]: state[next] };
          }
          return prev;
        }, {});
      },
    },
  ),
);

export default useUser;
